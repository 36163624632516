export enum EntityTypeEnum {
  PROPOSAL = 'proposal',
  REVIEW = 'review',
  VIDEO_DISTRIBUTION_REVIEW = 'video_distribution_review',
  CREATIVE_QUERY = 'creative_query',
  QUIZ_QUESTION = 'quiz_question',
  VIDEO_DISTRIBUTION = 'video_distribution',
}

export enum EntityAction {
  EDIT = 'edit',
  DELETE = 'delete',
  CANCEL = 'cancel',
}

export interface EntityParams {
  type: EntityTypeEnum;
  id: string;
}
