<div class="days" [ngClass]="{ reverted: reverted }">
  <span *ngIf="!progress.completed" class="flex items-center">
    <div class="days-progress_circle cursor-pointer" [canShow]="progress.tag | titlecase" [appTooltip]="progress.type">
      <svg class="progress-ring-out" width="34" height="34">
        <circle class="progress-ring__circle_out" stroke-width="4" fill="transparent" r="15" cx="17" cy="17" />
      </svg>
      <svg class="progress-ring" width="34" height="34" #proposalId>
        <circle
          class="progress-ring__circle"
          stroke-width="4"
          fill="transparent"
          r="15"
          cx="17"
          cy="17"
          [ngStyle]="{ 'stroke-dasharray': strokeDasharray, 'stroke-dashoffset': strokeDashoffset }"
        />
      </svg>
      <span>{{ (progress.count > 365 ? progress.count / 365 : progress.count) | number : '1.0-0' }}</span>
    </div>

    <p class="text-sm whitespace-nowrap ml-8">
      <ng-container *ngIf="!progress.tag">{{ progress.count > 365 ? 'years' : progress.type }} to go </ng-container>
      <ng-container *ngIf="progress.tag">
        <span class="pill">
          {{ progress.tag }}
        </span>
      </ng-container>
    </p>
  </span>
  <span *ngIf="progress.completed" class="font-bold"> Finished {{ progress.count }} {{ progress.type }} Ago </span>
</div>
