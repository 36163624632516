import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Style } from '../../interface/style-model';
import { VideoConfig } from '../../interface/video-config.interface';
import { VideoPlayerService } from '../../services/video-player.service';
import { Resolution } from '../../../app.datatypes';
import { DistributionService } from '../../services/distribution.service';
declare const cast: any;
declare const chrome: any;

@Component({
  selector: 'app-custom-video-player',
  templateUrl: './custom-video-player.component.html',
  styleUrls: ['./custom-video-player.component.scss'],
})
export class CustomVideoPlayerComponent implements AfterViewInit, OnDestroy {
  pipButton: boolean = true;
  chromecastButton: boolean = true;
  fullscreenButton: boolean = true;
  isMoreButton: boolean;
  playerController: any;
  isLandscape: boolean;
  castDevice: string;
  isCastConnecting: boolean;
  isCasting: boolean;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.handleButtons();
  }
  isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  @ViewChild('videoPlayer') videoElement: ElementRef;
  @ViewChild('progressBar') progressBar: ElementRef;
  @Input() videoConfig: VideoConfig = {
    videoControlOptions: '',
    resolutions: [],
    src: '',
    poster: '',
    showText: '',
    classes: '',
    loop: false,
    autoplay: false,
    controls: true,
  };
  @Input() styleConfig: Style = {};
  @Output() mouseEntered = new EventEmitter<MouseEvent>();
  @Output() mouseLeft = new EventEmitter<MouseEvent>();
  @Output() videoEvent = new EventEmitter<Event>();
  @Output() loadedData = new EventEmitter<any>();
  timeout: ReturnType<typeof setTimeout>;
  video: HTMLVideoElement;
  selectedres = 0;
  displayControllsOpacity = 0;
  isPlaying = false;
  isFullVolume = true;
  isFullScreen = false;
  watchedProgress = 0;
  loadPercentage = 0;
  durationRemaining = '00:00';
  controlsTimeout: ReturnType<typeof setTimeout>;
  castContext: any;
  constructor(
    private readonly videoPlayerService: VideoPlayerService,
    private readonly el: ElementRef,
    private readonly distributionService: DistributionService
  ) {
    if (window['__onGCastApiAvailable']) {
      if (cast?.framework) {
        this.castContext = cast.framework.CastContext.getInstance();
        if (!chrome.cast) {
          return;
        }
        this.castContext.setOptions({
          receiverApplicationId: chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
          autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
        });
        this.castContext.addEventListener(cast.framework.CastContextEventType.SESSION_STATE_CHANGED, (event) => {
          switch (event.sessionState) {
            case cast.framework.SessionState.SESSION_STARTING:
              this.isCastConnecting = true;
              break;
            case cast.framework.SessionState.SESSION_STARTED:
              this.isCastConnecting = false;

              break;
            case cast.framework.SessionState.SESSION_RESUMED:
              this.isCastConnecting = false;
              this.handleCasting(event);

              break;
            case cast.framework.SessionState.SESSION_ENDED:
              this.isCasting = false;
              break;
          }
        });
      }
    }
  }

  handleMediaSession(mediaSession: any): void {
    this.isCasting = mediaSession.media.contentId === this.videoConfig.src;
    mediaSession.addUpdateListener((res) => {
      this.isCasting = res;
      if (!this.isCasting) {
        this.playPause();
      }
    });
  }

  handleCasting(event: any): void {
    if (!this.isCasting && event.session) {
      this.castDevice = event.session.getCastDevice().friendlyName;
      if (event.session.getMediaSession()) {
        this.handleMediaSession(event.session.getMediaSession());
      }
    }
  }

  startSession(): void {
    this.castContext.requestSession().then(() => {
      this.loadMedia();
    });
  }

  loadMedia(): void {
    if (this.castContext.getCurrentSession() && !this.isCasting) {
      const mediaInfo = new chrome.cast.media.MediaInfo(this.videoConfig.src, 'video/mp4');
      mediaInfo.metadata = new chrome.cast.media.MovieMediaMetadata();
      mediaInfo.metadata.metadataType = chrome.cast.media.MetadataType.MOVIE;
      mediaInfo.metadata.title = this.videoConfig.title;
      mediaInfo.metadata.subtitle = this.videoConfig.description;

      const image = new chrome.cast.Image(this.videoConfig.poster);
      mediaInfo.metadata.images = [image];
      const request = new chrome.cast.media.LoadRequest(mediaInfo);
      request.autoplay = this.isPlaying;

      this.castContext
        .getCurrentSession()
        .loadMedia(request)
        .then(() => {
          const player = new cast.framework.RemotePlayer();
          this.playerController = new cast.framework.RemotePlayerController(player);
          this.handleMediaSession(this.castContext.getCurrentSession().getMediaSession());
        });
    }
  }

  ngAfterViewInit(): void {
    this.videoPlayerService.addVideoPlayer(this.videoElement);
    this.video = this.videoElement.nativeElement;
    if (this.videoConfig.play_duration > 0) {
      this.video.currentTime = this.videoConfig.play_duration;
    }
    if (this.videoConfig.resolutions?.length) {
      this.selectedres = this.videoConfig.selectedRes ?? this.videoConfig.resolutions[0].video_details.heightInPx;
    }
    this.el.nativeElement.addEventListener('fullscreenchange', () => {
      if (!document.fullscreenElement) {
        this.isFullScreen = false;
      } else {
        this.isFullScreen = true;
      }
    });
    this.handleButtons();

    this.progressBar?.nativeElement?.addEventListener('click', (event) => {
      const progressBar = this.progressBar.nativeElement as HTMLElement;
      const clickX = event.clientX - progressBar.getBoundingClientRect().left;
      const progressBarWidth = progressBar.offsetWidth;
      const percentage = (clickX / progressBarWidth) * 100;
      const newTime = (percentage / 100) * this.video.duration;
      this.video.currentTime = newTime;
      if (this.playerController) {
        this.playerController.seek(newTime);
      }
    });
    if (this.videoConfig.autoplay) {
      this.isFullVolume = false;
    }
  }

  toggleMute(): void {
    this.video.muted = !this.video.muted;
    if (this.video.muted) {
      this.isFullVolume = false;
    } else {
      this.isFullVolume = true;
    }
    if (this.playerController) {
      this.playerController.muteOrUnmute();
    }
  }

  toggleFullscreen(): void {
    const element = this.el.nativeElement;
    if (this.isFullscreenEnabled()) {
      if (!this.isFullScreen) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      } else {
        this.closeFullscreen();
      }
    }
  }

  isFullscreenEnabled(): boolean {
    return (
      document.fullscreenEnabled ||
      (document as any).webkitFullscreenEnabled ||
      (document as any).mozFullScreenEnabled ||
      (document as any).msFullscreenEnabled
    );
  }

  closeFullscreen(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msExitFullscreen) {
      (document as any).msExitFullscreen();
    }
  }

  playVideo(event: Event): void {
    this.videoEvent.emit(event);
  }

  onMouseEnter(event: MouseEvent): void {
    this.mouseEntered.emit(event);
    clearTimeout(this.controlsTimeout);
    if (this.videoConfig.controls !== false) {
      this.displayControls(true);
    }
    if (this.videoConfig.hoverPlay) {
      this.timeout = setTimeout(() => {
        this.playPauseVideo(true);
      }, 1000);
    }
  }

  onMouseLeave(event: MouseEvent): void {
    this.mouseLeft.emit(event);
    this.displayControls();
    if (this.videoConfig.hoverPlay) {
      clearTimeout(this.timeout);
      this.playPauseVideo(false);
    }
  }

  onLoadedData(event: any): void {
    this.loadedData.emit(event);
    const time = this.calculateTime(this.video.duration, true);
    this.videoPlayerService.videoDuration$.next(time);
  }

  handleProgress(): void {
    const bf = this.video.buffered;
    if (bf.length === 0) {
      this.loadPercentage = 0;
      return;
    }
    let range = 0;
    const time = this.video.currentTime;
    while (range < bf.length && time < bf.start(range)) {
      range++;
    }
    const loadEndPercentage = bf.end(range) / this.video.duration;
    this.loadPercentage = loadEndPercentage * 100;
  }

  handleTimeUpdate(): void {
    this.watchedProgress = (this.video.currentTime / this.video.duration) * 100;

    const totalSecondsRemaining = this.video.duration - this.video.currentTime;
    if (totalSecondsRemaining > 0) {
      this.calculateTime(totalSecondsRemaining);
    }
  }

  calculateTime(totalSecondsRemaining: number, fullTime?: boolean): string {
    let hours = null;
    if (totalSecondsRemaining >= 3600) {
      hours = Math.floor(totalSecondsRemaining / 3600)
        .toString()
        .padStart(2, '0');
    }
    const minutes = Math.floor((totalSecondsRemaining % 3600) / 60)
      .toString()
      .padStart(2, '0');
    const seconds = Math.floor(totalSecondsRemaining % 60)
      .toString()
      .padStart(2, '0');
    this.durationRemaining = `${hours ? hours + ':' : ''}${minutes}:${seconds}`;
    if (fullTime) {
      return `${hours ? hours + 'h' + ' ' : ''}${minutes}m ${seconds}s`;
    }
    return this.durationRemaining;
  }

  ngOnDestroy(): void {
    this.handlePlayBackTime();
    this.videoPlayerService.removeVideoPlayer(this.videoElement);
    this.video.pause();
  }

  handlePlayBackTime(): void {
    const time = Math.floor(this.video.currentTime);
    if (!this.videoConfig.id || !time) {
      return;
    }
    this.distributionService.handlePlayBackTime(this.videoConfig.id, time);
  }

  playPauseVideo(start: boolean): void {
    if (start) {
      this.video.play();
    } else {
      this.video.pause();
      this.handlePlayBackTime();
    }
    this.isPlaying = start;
  }

  playPause(): void {
    this.handleButtons();
    if (this.video.paused) {
      this.playPauseVideo(true);
      this.loadMedia();
    } else {
      this.playPauseVideo(false);
    }
    if (this.playerController) {
      this.playerController.playOrPause();
    }
  }

  displayControls(display?: boolean): void {
    if (display) {
      this.displayControllsOpacity = 1;
    } else {
      if (this.controlsTimeout) {
        clearTimeout(this.controlsTimeout);
      }
      this.controlsTimeout = setTimeout(() => {
        this.displayControllsOpacity = 0;
      }, 2000);
    }
  }

  togglePictureInPicture(): void {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    } else {
      this.video.requestPictureInPicture();
    }
  }

  onResolutionChange(option: Resolution): void {
    this.selectedres = option.video_details.heightInPx;
    if (this.videoConfig.handleResolutionChangeFromParent) {
      this.videoPlayerService.resolutionChange.emit(option.video_details.heightInPx);
    } else {
      const time = this.video.currentTime;
      this.videoConfig.src = null;
      this.videoConfig.src = option.file_url;
      this.video.load();
      setTimeout(() => {
        this.video.currentTime = time;
        this.video.play();
      });
    }
  }

  handleButtons(): void {
    this.pipButton = true;
    this.chromecastButton = true;
    this.fullscreenButton = true;
    this.isLandscape = false;
    this.isMoreButton = false;
    const width = window.innerWidth;

    if (width < 768 && window.screen.orientation.type.includes('landscape') && this.isPlaying) {
      this.isLandscape = true;
      setTimeout(() => {
        this.toggleFullscreen();
      });
    }
    if (width < 540) {
      this.pipButton = false;
    }
    if (width < 400) {
      this.chromecastButton = false;
    }
    if (width < 320 || this.videoConfig?.videoControlOptions?.includes('nofullscreen')) {
      this.fullscreenButton = false;
    }
    if (!this.pipButton || !this.chromecastButton || !this.fullscreenButton) {
      this.isMoreButton = true;
    }
  }

  stopCast(): void {
    this.castContext.getCurrentSession().endSession(true);
  }
}
