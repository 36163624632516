import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AuthenticatedUser, AuthenticatedUserQuery, SharedModule } from '../../../../shared';
import { Distribution, RoyaltyMap } from '../../../../app.datatypes';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OnlyNumberDirective } from '../../../../shared/modules/directives/only-number/only-number.directive';
import { CheckboxModule } from '../../../../shared/modules/components/checkbox/checkbox.module';
export function rewardsSumValidator(): ValidatorFn {
  return (control: FormArray): { [key: string]: any } | null => {
    const rewards = control.controls.map((item) => item.get('reward_percentage').value);
    const total = rewards.reduce((sum, reward) => sum + reward, 0);
    if (total !== 100) {
      return { rewardsSumExceeded: true };
    }
    return null;
  };
}
@Component({
  selector: 'app-distribution-royalty-map',
  templateUrl: './distribution-royalty-map.component.html',
  standalone: true,
  imports: [SharedModule, OnlyNumberDirective, CheckboxModule],
})
export class DistributionRoyaltyMapComponent implements OnInit {
  user: AuthenticatedUser;
  @Output() royaltyMaps: EventEmitter<Partial<Distribution>> = new EventEmitter();
  @Input() distribution: Distribution;

  form = new FormGroup({
    is_usdc_supported: new FormControl(false),
    is_film_supported: new FormControl(true),
    royalty_map: new FormArray([], rewardsSumValidator()),
    wallet_address_usdc: new FormControl(),
  });

  constructor(private authenticatedUserQuery: AuthenticatedUserQuery, private destroyRef: DestroyRef) {}

  get royaltyMapFields() {
    return this.form?.get('royalty_map') as FormArray;
  }

  ngOnInit(): void {
    this.user = this.authenticatedUserQuery.user;
    if (this.distribution?.royalty_map?.length) {
      this.form.get('is_usdc_supported').setValue(this.distribution.is_usdc_supported ?? false);
      this.form.get('wallet_address_usdc').setValue(this.distribution.wallet_address_usdc);
      this.form.get('is_film_supported').setValue(this.distribution.is_film_supported ?? true);
      this.distribution.royalty_map.forEach((royaltyMap: RoyaltyMap) => this.addStakeHolders(null, royaltyMap));
    } else {
      this.addStakeHolders(0);
    }
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.form
        .get('wallet_address_usdc')
        .setValidators(this.form.value.is_usdc_supported ? Validators.required : null);
      this.form.get('wallet_address_usdc').updateValueAndValidity({ emitEvent: false });
      this.emitData();
    });
  }

  emitData(): void {
    this.royaltyMaps.emit(this.form.value);
  }

  addStakeHolders(index?: number, royaltyMap?: RoyaltyMap): void {
    this.royaltyMapFields.push(
      new FormGroup({
        name: new FormControl(index === 0 ? this.user.name : royaltyMap?.name, Validators.required),
        wallet_address: new FormControl(
          index === 0 ? this.user.wallet_address : royaltyMap?.wallet_address,
          Validators.required
        ),
        reward_percentage: new FormControl(index === 0 ? 100 : royaltyMap?.reward_percentage, Validators.required),
        is_priority_stakeholder: new FormControl(royaltyMap?.is_priority_stakeholder ?? false),
        priority_stakeholder_amount: new FormControl(royaltyMap?.priority_stakeholder_amount ?? 0),
      })
    );
    this.emitData();
  }

  removeStakeHolder(index: number): void {
    this.royaltyMapFields.removeAt(index);
    this.emitData();
  }

  changePriorityHolder(index: number): void {
    const formGroup = this.royaltyMapFields.at(index) as FormGroup;
    const formControl = formGroup.get('priority_stakeholder_amount');
    if (formGroup.value.is_priority_stakeholder) {
      formControl.addValidators(Validators.required);
    } else {
      formControl.removeValidators(Validators.required);
      formControl.setValue(null);
    }
    formControl.updateValueAndValidity();
    this.emitData();
  }
}
