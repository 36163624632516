
<input type="text"
#dateFromDp="dpDayPicker"
[dpDayPicker]="datePickerConfig"
[theme]="'dp-material'"
[disabled]="disabled"
 (ngModelChange)="calendarApplied($event)"
[(ngModel)]="value" />

<app-control-error-message [controlName]="controlName"></app-control-error-message>

