import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { dcpDateFormat } from '../../../formatterFunctions';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SharedService } from '../../../services';
import { DatePickerComponent } from 'ng2-date-picker';
import { formatDate } from '@angular/common';
/* eslint-disable no-use-before-define,@typescript-eslint/no-use-before-define */
export const DATEPICKER_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DcpDatePickerComponent),
  multi: true,
};

/* eslint-enable no-use-before-define, @typescript-eslint/no-use-before-define */

@Component({
  selector: 'app-dcp-date-picker',
  templateUrl: './dcp-date-picker.component.html',
  styleUrls: ['./dcp-date-picker.component.scss'],
  providers: [DATEPICKER_CONTROL_VALUE_ACCESSOR],
})
export class DcpDatePickerComponent implements ControlValueAccessor {
  @Input() controlName = '';
  @ViewChild('dateFromDp') public dateFromDp: DatePickerComponent;

  constructor(private sharedService: SharedService) {
    this.sharedService.getResetFilter().subscribe(() => {
      this.value = '';
    });
  }
  datePickerConfig = {
    format: 'MM/DD/YYYY',
    min: formatDate(new Date(), 'MM/dd/YYYY', 'en-US'),
  };

  isDatePickerOpen = false;
  @Output() selectedDateFilter: EventEmitter<string> = new EventEmitter<string>();

  value: string;
  disabled = false;

  onChange(_: string): void {}
  onTouched(_: string): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    if (value) {
      this.value = dcpDateFormat(value);
    }
  }

  calendarApplied(event: string): void {
    this.value = event;
    this.onChange(this.value);
    this.onTouched(this.value);
    this.selectedDateFilter.emit(this.value);
  }

  onShowDateRangePicker(): void {
    this.isDatePickerOpen = true;
  }

  onHideDateRangePicker(): void {
    this.isDatePickerOpen = false;
  }
}
