<section
  class="flex flex-col h-full relative"
  @panelIn
  joyrideStep="cq10"
  [title]="joyRideService?.isTourInProgress() ? 'Create Creative Query' : ''"
  text="Congratulations, you have just submitted your Creative Query. It will become active on the platform once it has been approved by a member of our moderation team. Good-luck!"
  (done)="this.closeCreateCQSidebar.emit()"
  stepPosition="center"
>
  <div class="px-24 h-64 min-h-64 max-h-64 border-b border-gray-border">
    <h3 class="font-semibold mt-16">{{ isCreativeQueryEdit ? 'Edit' : 'Create' }} Creative Query</h3>
    <button class="absolute top-16 right-16 opacity-75 hover:opacity-100" (click)="closeCreateForm()" *ngIf="isNew">
      <app-icon [icon]="'close'" class="w-16 h-16"></app-icon>
    </button>
  </div>

  <app-tabs class="flex-1 overflow-hidden tabs-create-panel flex flex-col">
    <app-tab-item>
      <app-tab-label>
        <app-icon [icon]="'bulb'"></app-icon>
        <label>Create a Creative Query</label>
        <p class="absolute top-0 left-0 w-full h-full" id="tab1" (click)="activateTab(1)" #tab1></p>
        <p class="absolute top-0 left-0 w-full h-full" (click)="$event.preventDefault(); $event.stopPropagation()"></p>
      </app-tab-label>
      <app-tab-body id="tb">
        <div class="cq-scroll-top h-full p-24 pb-0" [ngClass]="{ 'overflow-y-auto': !scrollRestricted }">
          <form *ngIf="form || joyRideService.isTourInProgress()" [formGroup]="form" class="pt-1" autocomplete="off">
            <fieldset
              class="form-group"
              joyrideStep="cq2"
              [title]="joyRideService?.isTourInProgress() ? 'Create Creative Query' : ''"
              text="Add the Title of your Creative Query"
            >
              <label>Title</label>
              <input placeholder="Title" id="title" trim="blur" formControlName="title" required [maxLength]="191" />
              <p class="text-sm opacity-75" *ngIf="!form.get('title').touched">
                The title is not the question. For example - A Trailer Query, or Larry's Script Query
              </p>
              <p
                class="error-msg"
                *ngIf="form.get('title').errors && form.get('title').errors.required && form.get('title').touched"
              >
                Title is <strong>Required</strong>
              </p>
            </fieldset>
            <div *ngIf="creativeQuery && questions">
              <h4 class="mt-24 font-semibold pb-8 border-b border-gray-border mb-16">Creative Query Details</h4>
              <div class="bg-body border border-gray-border p-16 w-full h-full mb-24 rounded overflow-hidden">
                <div
                  joyrideStep="cq3"
                  [title]="joyRideService?.isTourInProgress() ? 'Create Creative Query' : ''"
                  text="Next, add the Creative Query question."
                >
                  <p>Add your Creative Query Question.</p>
                  <div class="form-group xl">
                    <input
                      placeholder="Add your creative query question here"
                      id="question"
                      formControlName="question"
                      required
                      trim="blur"
                      (blur)="updateQuestion()"
                      (keyup)="onAddQuestion($event.target.value)"
                      [ngClass]="{
                        'border-error':
                          (form.get('question').errors &&
                            form.get('question').errors.required &&
                            form.get('question').touched) ||
                          (form.get('question').touched && !isQuestionValid)
                      }"
                    />
                    <p
                      *ngIf="
                        (form.get('question').errors &&
                          form.get('question').errors.required &&
                          form.get('question').touched) ||
                        (form.get('question').touched && !isQuestionValid)
                      "
                      class="text-error text-xs top-48 left-0 form ng-invalid"
                      [ngClass]="{ hidden: answerClicked }"
                    >
                      Enter your question. The length of the question must be at least 6 characters.
                    </p>
                    <p
                      *ngIf="!isQuestionValid && answerClicked"
                      class="text-error text-xs top-48 left-0 form ng-invalid"
                    >
                      Creative Query Question is required before you can set your answers.
                    </p>
                  </div>
                </div>
                <div
                  joyrideStep="cq4"
                  [title]="joyRideService?.isTourInProgress() ? 'Create Creative Query' : ''"
                  stepPosition="left"
                  (next)="scrollRestricted = false"
                  text="Add all answer options. A minimum of two options are required, but may add as many options as needed. Answers can be of any combination available."
                >
                  <p class="text-dark p-3 mt-8 mb-8">
                    Add answer options to your Creative Query. You need at least two options for you Creative Query to
                    be meaningful, so please add an image or video to your option or just type text. You can add as many
                    options you need.
                  </p>

                  <p *ngIf="hasOptionError" class="text-error text-xs top-48 left-0 form ng-invalid">
                    {{ errorOptionMessage }}
                  </p>
                  <div>
                    <ng-container *ngFor="let option of options; let i = index">
                      <div
                        class="bg-white rounded p-16 border border-gray-border mb-20 w-full overflow-hidden"
                        id="options"
                      >
                        <div *ngIf="!option?.isSaved && option?.type === 'text'" class="form-group">
                          <textarea
                            type="text"
                            placeholder="Answer option {{ option?.index + 1 }}"
                            trim="blur"
                            (keyup)="showSaveButton($event, option)"
                            (blur)="setOptionTitle($event.target.value)"
                            appAutoresizeArea
                          ></textarea>
                          <p
                            *ngIf="option?.value && option.value.length < 2"
                            class="text-error text-xs top-48 left-0 form ng-invalid"
                          >
                            A minimum of two characters are required to save a text based answer
                          </p>
                        </div>

                        <div *ngIf="option?.isSaved === true" class="md:flex">
                          <div class="flex w-full">
                            <span
                              class="rounded-full min-w-32 w-32 h-32 border border-gray-border bg-body flex justify-center items-center"
                              >{{ option?.index + 1 }}</span
                            >
                            <div *ngIf="option?.type === 'text'" class="flex-grow">
                              <div class="pl-16 form-group">
                                <textarea
                                  *ngIf="option?.isEdit === true"
                                  type="text"
                                  [value]="option?.value"
                                  trim="blur"
                                  (blur)="setOptionUpdatedTitle($event.target.value, option)"
                                  appAutoresizeArea
                                ></textarea>
                                <p *ngIf="option?.isEdit === false" class="break-word">{{ option?.value }}</p>
                              </div>
                            </div>

                            <div *ngIf="option?.type === 'image'" class="flex-grow">
                              <div class="px-16">
                                <input
                                  *ngIf="option?.isEdit === true"
                                  type="text"
                                  placeholder="Enter title here"
                                  [value]="option?.title"
                                  trim="blur"
                                  (blur)="setOptionUpdatedTitle($event.target.value)"
                                />
                                <p *ngIf="option?.isEdit === false">{{ option?.title }}</p>
                                <p>{{ option?.description }}</p>
                              </div>
                            </div>
                            <div *ngIf="option?.type === 'doc'" class="flex-grow">
                              <div class="px-16">
                                <input
                                  *ngIf="option?.isEdit === true"
                                  type="text"
                                  placeholder="Enter title here"
                                  [value]="option?.title"
                                  trim="blur"
                                  (blur)="setOptionUpdatedTitle($event.target.value)"
                                />
                                <p *ngIf="option?.isEdit === false">{{ option?.title }}</p>
                                <p>{{ option?.description }}</p>
                              </div>
                            </div>

                            <div *ngIf="option?.type === 'video'" class="flex-grow">
                              <div class="px-16">
                                <input
                                  *ngIf="option?.isEdit === true"
                                  type="text"
                                  placeholder="Enter title here"
                                  [value]="option?.title"
                                  trim="blur"
                                  (blur)="setOptionUpdatedTitle($event.target.value)"
                                />
                                <p *ngIf="option?.isEdit === false">{{ option?.title }}</p>
                                <p>{{ option?.description }}</p>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="option?.isSaved === true && option?.isEdit === false">
                            <div *ngIf="option?.type === 'video'" class="image-video-box ratio-16-9">
                              <ng-container *ngIf="option?.videoBlobUrl">
                                <app-video-player
                                  class="absolute top-0 w-full h-full"
                                  [videoConfig]="generateVideoConfig(option)"
                                ></app-video-player>
                              </ng-container>
                              <div
                                class="flex absolute bottom-0 right-0 p-8 z-50"
                                joyrideStep="cq9"
                                [title]="joyRideService?.isTourInProgress() ? 'Create Creative Query' : ''"
                                text="Options to edit, reset or delete answer options are found here"
                              >
                                <button class="btn-icon btn-sm error btn-inverted mr-8">
                                  <app-icon [icon]="'renew'" (click)="resetOption(option)"></app-icon>
                                </button>
                                <button
                                  class="btn-icon btn-sm error btn-inverted mr-8"
                                  *ngIf="options?.length > 2 && option?.index > 1"
                                  (click)="deleteOption(option?.index)"
                                >
                                  <app-icon [icon]="'trash'"></app-icon>
                                </button>
                                <button class="btn-icon btn-sm btn-inverted" (click)="openEditModal('video', option)">
                                  <app-icon [icon]="'edit'"></app-icon>
                                </button>
                              </div>
                            </div>

                            <div *ngIf="option?.type === 'image'" class="image-video-box ratio-16-9">
                              <img
                                [src]="option?.value"
                                alt="option-image"
                                class="absolute top-0 left-0 w-full h-full object-cover"
                              />
                              <div class="flex absolute bottom-0 right-0 p-8 z-50">
                                <button class="btn-icon btn-sm error btn-inverted mr-8">
                                  <app-icon [icon]="'renew'" (click)="resetOption(option)"></app-icon>
                                </button>
                                <button
                                  class="btn-icon btn-sm error mr-8 btn-inverted"
                                  *ngIf="options?.length > 2 && option?.index > 1"
                                  (click)="deleteOption(option?.index)"
                                >
                                  <app-icon [icon]="'trash'"></app-icon>
                                </button>
                                <button class="btn-icon btn-sm btn-inverted" (click)="openEditModal('image', option)">
                                  <app-icon [icon]="'edit'"></app-icon>
                                </button>
                              </div>
                            </div>
                            <div *ngIf="option?.type === 'doc'" class="image-video-box ratio-16-9">
                              <img
                                [src]="option?.thumbnail_url"
                                alt="option-image"
                                class="absolute top-0 left-0 w-full h-full object-cover"
                              />
                              <div class="flex absolute bottom-0 right-0 p-8 z-50">
                                <button class="btn-icon btn-sm error btn-inverted mr-8">
                                  <app-icon [icon]="'renew'" (click)="resetOption(option)"></app-icon>
                                </button>
                                <button
                                  class="btn-icon btn-sm error mr-8 btn-inverted"
                                  *ngIf="options?.length > 2 && option?.index > 1"
                                  (click)="deleteOption(option?.index)"
                                >
                                  <app-icon [icon]="'trash'"></app-icon>
                                </button>
                                <button class="btn-icon btn-sm btn-inverted" (click)="openEditModal('doc', option)">
                                  <app-icon [icon]="'edit'"></app-icon>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ng-container *ngIf="option?.isSaved === true">
                          <div
                            *ngIf="option?.isSaved === true && option?.isEdit === true"
                            class="w-full flex flex-wrap justify-end"
                          >
                            <button
                              *ngIf="option?.type !== 'none'"
                              class="btn-icon btn-sm error mr-16"
                              (click)="resetOption(option)"
                            >
                              <app-icon [icon]="'renew'"></app-icon>
                            </button>
                            <button class="btn-icon btn-sm error mr-16" (click)="closeOption(option)">
                              <app-icon [icon]="'close'"></app-icon>
                            </button>
                            <button class="btn-icon btn-sm success" (click)="saveUpdatedOption(option)">
                              <app-icon [icon]="'check'"></app-icon>
                            </button>
                          </div>
                        </ng-container>

                        <div
                          *ngIf="option?.isSaved === true && option?.isEdit === false && option?.type === 'text'"
                          class="flex flex-wrap w-full justify-end"
                        >
                          <button
                            class="btn-icon btn-sm error mr-16"
                            *ngIf="options?.length > 2 && option?.index > 1"
                            (click)="deleteOption(option?.index)"
                          >
                            <app-icon [icon]="'trash'"></app-icon>
                          </button>
                          <button class="btn-icon btn-sm edit" (click)="editOption(option)">
                            <app-icon [icon]="'edit'"></app-icon>
                          </button>
                        </div>

                        <div *ngIf="option?.isSaved === false" class="w-full flex justify-between">
                          <div class="md:flex" *ngIf="option?.type === 'none'">
                            <button
                              (click)="chooseOptionType('text', option?.index)"
                              class="btn btn-link -ml-24 md:ml-0"
                            >
                              Set Text
                              <app-icon [icon]="'text'" class="w-24 h-24 text-blue"></app-icon>
                            </button>
                            <button
                              (click)="chooseOptionType('video', option?.index)"
                              class="btn btn-link -ml-24 md:ml-0"
                            >
                              Set a Video
                              <app-icon [icon]="'video'" class="w-24 h-24 text-blue"></app-icon>
                            </button>
                            <button
                              (click)="chooseOptionType('image', option?.index)"
                              class="btn btn-link -ml-24 md:ml-0"
                            >
                              Set an Image
                              <app-icon [icon]="'image'" class="w-24 h-24 text-blue"></app-icon>
                            </button>
                            <button
                              (click)="chooseOptionType('doc', option?.index)"
                              class="btn btn-link -ml-24 md:ml-0"
                            >
                              Set a pdf
                              <app-icon [icon]="'doc'" class="w-24 h-24 text-blue"></app-icon>
                            </button>
                          </div>
                          <div class="flex">
                            <button
                              *ngIf="option?.type !== 'none'"
                              class="btn-icon btn-sm error mr-16"
                              (click)="resetOption(option)"
                            >
                              <app-icon [icon]="'renew'"></app-icon>
                            </button>
                            <button
                              *ngIf="
                                option?.value?.length > 1 ||
                                (joyRideService.isTourInProgress() && option.type !== 'none')
                              "
                              class="btn-icon success btn-sm"
                              (click)="updateOption(option)"
                              [ngClass]="{ 'mr-16': options?.length > 2 && option?.index > 1 }"
                            >
                              <app-icon [icon]="'check'"></app-icon>
                            </button>
                            <button
                              class="btn-icon error btn-sm"
                              *ngIf="options?.length > 2 && option?.index > 1"
                              (click)="deleteOption(option?.index)"
                            >
                              <app-icon [icon]="'trash'"></app-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <button
                      (click)="addOption()"
                      class="btn btn-secondary w-full"
                      id="add-option"
                      joyrideStep="cq9.1"
                      [title]="joyRideService?.isTourInProgress() ? 'Create Creative Query' : ''"
                      stepPosition="left"
                      text="To add additional answer options, click here"
                    >
                      +Add Another Option
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="cover"
              joyrideStep="cq5"
              [title]="joyRideService?.isTourInProgress() ? 'Create Creative Query' : ''"
              stepPosition="left"
              (next)="chooseOptionType('text', 0)"
              text="By default, a cover image and colour are assigned, but can be modified using the available menus."
            >
              <h4 class="mb-16 font-semibold pb-8 border-b border-gray-border">
                Choose a cover for your Creative Query card
              </h4>
              <div class="grid grid-cols-1 sm:grid-cols-3">
                <div>
                  <fieldset class="form-group mb-16">
                    <app-dcp-select
                      *ngIf="colorList"
                      [options]="colorList"
                      (next)="selectColor(colorList[0])"
                      [placeholder]="'Color'"
                      [selectedDefault]="creativeQuery?.cover_color?.length ? creativeQuery.cover_color : selectedColor"
                      class="select-color"
                      (selected)="selectColor($event)"
                    >
                    </app-dcp-select>
                    <p class="error-msg" *ngIf="!form.get('cover_color').value && hasError">
                      Select a Background Color.
                    </p>
                  </fieldset>
                  <fieldset class="form-group mb-16">
                    <app-dcp-select
                      *ngIf="brandList"
                      [options]="brandList"
                      [placeholder]="'Cover'"
                      [selectedDefault]="creativeQuery?.cover?.length ? creativeQuery.cover : selectedBrand"
                      class="select-img"
                      (selected)="selectBrand($event)"
                    >
                    </app-dcp-select>
                    <p class="error-msg" *ngIf="!form.get('cover').value && hasError">Select a Cover Image.</p>
                  </fieldset>
                </div>

                <div class="col-span-2">
                  <div class="cover-preview mx-auto color-{{ selectedColor }} bg-{{ selectedBrand }}"></div>
                </div>
              </div>
            </div>

            <div *ngIf="creativeQuery && questions">
              <div
                joyrideStep="cq6"
                [title]="joyRideService?.isTourInProgress() ? 'Create Creative Query' : ''"
                stepPosition="left"
                text="Set the Creative Query Campaign Details. Choose the number of days active and total Reward Pool for the submission. Enable Chat will be on by default. This will create a discord channel where the community can discuss the Creative Query."
              >
                <h4 class="mt-40 mb-24 font-semibold border-b border-gray-border pb-8">
                  Creative Query Campaign Details
                </h4>
                <div class="sm:grid grid-cols-2 gap-24">
                  <fieldset class="form-group">
                    <label>How many Days? (Between 1 and 30) </label>
                    <div class="form-group number" [ngClass]="{ focus: isFocusTitle === true }">
                      <input
                        placeholder="e.g. 20 days"
                        id="title"
                        formControlName="query_age"
                        required
                        type="number"
                        (change)="onChangeQueryAge()"
                        appInputNumber
                      />
                      <span>
                        <button (click)="AddQueryAge(1)">
                          <app-icon [icon]="'arrow-left'"></app-icon>
                        </button>
                        <button (click)="AddQueryAge(-1)">
                          <app-icon [icon]="'arrow-right'"></app-icon>
                        </button>
                      </span>
                      <app-icon [icon]="'calendar'" class="w-32 h-32 absolute top-16 right-40"></app-icon>
                    </div>
                    <p
                      *ngIf="
                        form.get('query_age').errors &&
                        form.get('query_age').errors.required &&
                        form.get('query_age').touched
                      "
                      class="error-msg"
                    >
                      Creative Query duration is Required.
                    </p>
                    <p
                      class="error-msg"
                      *ngIf="
                        form.get('query_age').errors &&
                        form.get('query_age').errors.range &&
                        form.get('query_age').touched
                      "
                    >
                      Creative Query length range must be between 1 and 30 days
                    </p>
                    <p class="text-sm opacity-50" *ngIf="form.get('query_age').touched === false">
                      Creative Queries can run between 1 and 30 days.
                    </p>
                  </fieldset>
                </div>
              </div>
              <div class="grid grid-cols-1 md-gap-24">
                <div class="form-group pb-0">
                  <div class="info-box">
                    <p>Enable Chat (Create a discussion channel for your Creative Query in the DCP Discord)</p>
                    <label class="switch mt-4">
                      <input type="checkbox" formControlName="enable_chat" />
                      <span class="slider rounded"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div
                joyrideStep="cq7"
                [title]="joyRideService?.isTourInProgress() ? 'Create Creative Query' : ''"
                stepPosition="left"
                (next)="buildPreview()"
                text="Set your demographics. This step is optional, the Creative Query will be open to everyone by default."
              >
                <h4 class="my-24 font-semibold border-b border-gray-border pb-8">Demographic Target</h4>

                <p class="mb-16">
                  By default, Creative Queries are open to everyone in the community. If you'd like to refine your
                  target demographic, you can specify it in the categories listed below, but this is not required.
                </p>
                <div class="grid sm:grid-cols-3 gap-24 mb-24" formGroupName="demography">
                  <app-dcp-multiselect
                    *ngIf="genderList"
                    [options]="genderList"
                    [placeholder]="'Gender'"
                    (selected)="selectGender($event)"
                    [selectedOptions]="selectedGender"
                  >
                  </app-dcp-multiselect>

                  <app-dcp-multiselect
                    *ngIf="ageGroupList"
                    [options]="ageGroupList"
                    [placeholder]="'Age'"
                    (selected)="selectAge($event)"
                    [selectedOptions]="selectedAge"
                  >
                  </app-dcp-multiselect>

                  <app-dcp-multiselect
                    *ngIf="countryList$ | async"
                    [options]="countryList$ | async"
                    [placeholder]="'Region'"
                    (selected)="selectCountry($event)"
                    [selectedOptions]="selectedRegion"
                  >
                  </app-dcp-multiselect>
                </div>
              </div>
              <div
                id="tab-1"
                class="sticky bottom-0 flex justify-between h-72 bg-white border-t border-gray-border z-50 items-center"
              >
                <button
                  *ngIf="questions && questions.length < 1"
                  class="btn btn-primary mr-16"
                  color="accent"
                  (click)="addQuestion(questions.length)"
                >
                  +Add Question
                </button>

                <button
                  *ngIf="creativeQuery?.status === processStatus.APPROVED"
                  [disabled]="!readyToSave"
                  (click)="saveCreativeQuery()"
                  class="btn btn-secondary mr-16"
                >
                  Save
                </button>

                <button class="btn btn-primary ml-auto" (click)="buildPreview()">Next</button>
              </div>
            </div>
          </form>
        </div>
      </app-tab-body>
    </app-tab-item>

    <app-tab-item>
      <app-tab-label>
        <app-icon [icon]="'proposal'"></app-icon>
        <label>Preview</label>
        <p class="absolute top-0 left-0 w-full h-full" id="tab2" (click)="activateTab(2)" #tab2></p>
        <p class="absolute top-0 left-0 w-full h-full" (click)="$event.preventDefault(); $event.stopPropagation()"></p>
      </app-tab-label>
      <app-tab-body class="flex-grow">
        <div
          class="overflow-hidden h-full"
          [title]="joyRideService?.isTourInProgress() ? 'Create Creative Query' : ''"
          joyrideStep="cq8"
          (next)="moveToTab3()"
          text="Preview the Creative Query, if correct proceed to Checkout."
        >
          <div class="grid grid-cols-1 sm:grid-cols-5 gap-24 p-24 overflow-y-auto" style="height: calc(100% - 64px)">
            <div class="col-span-3">
              <h4 class="text-xl font-semibold border-b border-gray-border pb-8">Your Creative Query Details</h4>
              <app-preview-create-query *ngIf="questionsPreview !== undefined" [questions]="questionsPreview">
              </app-preview-create-query>
            </div>
            <div class="col-span-2">
              <h4 class="text-xl font-semibold border-b border-gray-border pb-8 mb-16">Your Creative Query Card</h4>
              <div class="preview-query-card mb-24" *ngIf="form">
                <div class="cover-preview color-{{ form.value.cover_color }} bg-{{ form.value.cover }}">
                  <div class="flex items-center absolute top-16 left-16">
                    <app-icon [icon]="'calendar'" class="w-20 h-20 white mr-8"></app-icon>
                    <span class="text-sm text-white">Created Today</span>
                  </div>
                  <h3 class="text-left pl-16 text-xl font-bold query-title">
                    {{ form.value.title }}
                  </h3>
                  <p class="text-sm pl-16 query-subtitle" *ngIf="questions?.length">
                    {{ questions[0].description }}
                  </p>
                  <div class="flex justify-between absolute bottom-24 left-16 right-0 w-full px-16">
                    <div class="flex" *ngIf="!isOwner">
                      <div class="flex items-center mr-16">
                        <app-icon [icon]="'vote'" class="w-16 h-16 white mr-4"></app-icon>
                        <p appTooltip="Votes" placement="top" class="text-sm font-bold text-white">0</p>
                      </div>
                      <div class="flex items-center mr-16">
                        <app-icon [icon]="'bounty'" class="w-16 h-16 white mr-4"></app-icon>
                        <p appTooltip="Votes" class="text-sm font-bold text-white bounty">
                          {{ form.value.bounty }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="tab-2"
            class="sticky bottom-0 flex justify-between h-72 bg-white border-t border-gray-border z-50 items-center mx-24 w-[calc(100% - 48px)]"
          >
            <button type="button" class="btn btn-secondary mr-16" (click)="moveToTab1()">Back</button>
            <button class="btn btn-primary" (click)="moveToTab3()">Next</button>
          </div>
        </div>
      </app-tab-body>
    </app-tab-item>

    <app-tab-item>
      <app-tab-label>
        <app-icon [icon]="'bag'"></app-icon>
        <label>Checkout</label>
        <p class="absolute top-0 left-0 w-full h-full" id="tab3" (click)="activateTab(3)" #tab3></p>
        <p class="absolute top-0 left-0 w-full h-full" (click)="$event.preventDefault(); $event.stopPropagation()"></p>
      </app-tab-label>
      <app-tab-body class="flex-grow">
        <div
          *ngIf="submitting"
          class="absolute w-full h-full top-0 left-0 flex justify-center items-center backdrop backdrop-blur"
        >
          <app-local-loader class="h-48" [hasTimeout]="false"></app-local-loader>
        </div>
        <div
          class="overflow-y-auto h-full p-24 pb-0"
          joyrideStep="cq9"
          [title]="joyRideService?.isTourInProgress() ? 'Create Creative Query' : ''"
          text="Read & Accept Submission Terms and then Submit for Moderation."
        >
          <form autocomplete="off" class="h-full" *ngIf="checkoutFormGroup" [formGroup]="checkoutFormGroup">
            <div class="h-full">
              <div class="grid sm:grid-cols-2 gap-24">
                <div>
                  <p class="mb-16 text-lg text-semibold">
                    Thank you for being a part of our platform. Good luck with your submission.
                  </p>
                  <app-logo class="logo-black w-128 block mb-16"></app-logo>
                  <app-dcp-checkout
                    [films]="totalAmount"
                    [user]="user"
                    (changeCheckoutType)="changeCheckoutType($event)"
                    (formValidity)="checkoutFormValidityChange($event)"
                  ></app-dcp-checkout>
                  <p class="-mt-8 mb-8 text-error" *ngIf="totalAmount > user.balance && !isExternalCheckout">
                    You have an insufficient Current Balance for this amount. Please choose another payment method to
                    continue with this amount.
                  </p>
                  <div class="flex mb-24">
                    <app-checkbox formControlName="termsAccepted" [formGroup]="checkoutFormGroup"></app-checkbox>
                    <p>
                      By clicking accept, you agree to the Submission Terms
                      <a href="https://decentralized.pictures/terms-of-service" rel="noopener" target="_blank"
                        ><strong>here.</strong></a
                      >
                    </p>
                  </div>
                </div>
                <div>
                  <app-bounty-info
                    *ngIf="!creativeQuery.blockchain_confirmed"
                    [title]="'Receipt'"
                    [additional]="form.value.bounty"
                    [submissionFee]="submitFeeFilm"
                    (recalculateBountyValue)="handleBounty($event)"
                    [minimumBounty]="minimumBounty"
                    [isExternalCheckout]="isExternalCheckout"
                    [resubmissionFee]="null"
                    class="mb-16"
                  >
                  </app-bounty-info>
                  <app-bounty-info
                    *ngIf="creativeQuery.blockchain_confirmed"
                    [title]="'Receipt'"
                    [canAdd]="false"
                    [additional]="null"
                    [resubmissionFee]="submitFeeFilm"
                    (recalculateBountyValue)="handleBounty($event)"
                    [minimumBounty]="null"
                    [submissionFee]="null"
                    class="mb-16"
                  >
                  </app-bounty-info>
                </div>
              </div>
            </div>
            <div
              id="tab-3"
              class="sticky bottom-0 flex justify-between h-72 bg-white border-t border-gray-border z-50 items-center"
            >
              <button class="btn btn-secondary" (click)="moveToTab2()">Back</button>
              <div>
                <button
                  class="mr-16 btn btn-secondary"
                  *ngIf="creativeQuery?.status === processStatus.APPROVED"
                  [disabled]="!form.valid"
                  (click)="saveCreativeQuery()"
                >
                  Save
                </button>

                <button
                  class="btn btn-primary"
                  [disabled]="
                    (!checkoutFormGroup.valid ||
                      this.form.invalid ||
                      submitting ||
                      !checkoutFormValidity ||
                      (totalAmount > user.balance && !isExternalCheckout)) &&
                    !joyRideService.isTourInProgress()
                  "
                  (click)="submitAction()"
                >
                  Submit for Moderation
                </button>
              </div>
            </div>
          </form>
        </div>
      </app-tab-body>
    </app-tab-item>
  </app-tabs>

  <!-- Imge and Video Modal -->
  <div *ngIf="isOpenModal" class="backdrop flex flex-wrap items-center justify-center w-full h-full">
    <app-upload-dialog
      [type]="addOptionType"
      [from]="'creativeQuery'"
      [mode]="creativeQueryMode"
      [imageUploaded]="optionImageUploaded"
      [videoUploaded]="optionVideoUploaded"
      [isDirectVideoUpload]="isOptionDirectVideoUpload"
      [modalTitle]="optionModalTitle"
      [modalValue]="optionModalValue"
      [file_id]="fileId"
      [modalDescription]="optionModalDescription"
      [fileUploaded]="fileUploaded"
      (cancelModal)="cancelModal()"
      (saveOption)="saveOption($event)"
    ></app-upload-dialog>
  </div>
</section>
