export function dcpDateFormat(dateString) {
  let d = new Date(dateString);
  const offset = d.getTimezoneOffset();
  d = new Date(d.getTime() + offset * 60000);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [month, day, year].join('/');
}
