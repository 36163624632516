<div class="flex justify-between items-center gap-24 action-block" *ngIf="!isDistributionPurchase">
  <div class="w-full">
    <ng-container *ngIf="!isActivePurchase && !isOwner && !isPendingPayment && !isSubmitting">
      <ng-container *ngTemplateOutlet="pay; context: { $implicit: types.RENT }"></ng-container>
      <ng-container *ngTemplateOutlet="pay; context: { $implicit: types.BUY }"></ng-container>
    </ng-container>
    <div class="relative" *ngIf="isActivePurchase || isOwner || isSubmitting">
      <button (click)="buyRentVideo(null)" class="btn btn-primary btn-sm w-full">Watch Feature</button>
    </div>
    <ng-container *ngIf="isPendingPayment">
      <div class="flex items-center">
        <button (click)="continuePayment()" class="btn btn-primary btn-sm w-full mr-16">Continue Payment</button>
        <button
          class="btn-icon-transparent ml-16"
          appTooltip="Edit Payment Mode"
          placement="left"
          (click)="buyRentVideo(distribution.purchase.purchase_type)"
        >
          <app-icon [icon]="'edit-proposal'"></app-icon>
        </button>
      </div>
      <div class="bg-warning-45 border border-warning rounded-full text-white mt-16">
        <marquee class="mt-4 text-sm">
          <ng-container> Please complete your payment to continue </ng-container>
        </marquee>
      </div>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="isDistributionPurchase">
  <span class="text-base block">Please choose a Payment Method below to continue.</span>
  <app-bounty-info
    [title]="'Receipt'"
    [canAdd]="false"
    [submissionFee]="submissionFee"
    [minimumBounty]="0"
    [isDistributionPurchase]="true"
    [resubmissionFee]="null"
    [isExternalCheckout]="isExternalCheckout"
    class="mb-16 mt-8 border border-gray-border"
  >
  </app-bounty-info>
  <app-dcp-checkout
    [user]="user"
    [films]="submissionFee"
    [externalOnly]="!distribution.is_film_supported"
    [isUSDC]="distribution.is_usdc_supported"
    (changeCheckoutType)="changeCheckoutType($event)"
    (formValidity)="handleCheckoutFormValidity($event)"
  >
  </app-dcp-checkout>
  <div class="h-72 p-16 mt-16 border-t border-gray-border flex justify-between">
    <button
      class="btn btn-secondary"
      (click)="purchaseType = types.NONE; isDistributionPurchase = false; this.purchaseAction.emit(false)"
    >
      Back
    </button>
    <button class="btn btn-primary" [disabled]="!isCheckoutFormValid" (click)="purchaseDistribution()">
      {{ purchaseType | titlecase }} Video Now
    </button>
  </div>
  <div [hidden]="!chart" class="chart-container transition-all border border-gray-border rounded delay-150 p-16">
    <p class="text-base font-semibold mb-16 w-full text-center">How your transaction is returned to DCP+ Community</p>
    <canvas id="canvas"></canvas>
  </div>
</ng-container>
<div
  *ngIf="isSubmitting"
  class="flex flex-col justify-center items-center h-full w-full absolute loader-background top-0 rounded z-10"
>
  <app-local-loader color="white" [hasTimeout]="false" class="w-24 h-24"></app-local-loader>
</div>
<ng-template #pay let-type>
  <button class="btn btn-primary w-full mb-12" (click)="buyRentVideo(type)">
    {{ type === types.RENT ? 'Rent for 72hr' : 'Buy' }}
    <span class="flex ml-8 bg-[#f7f2f21f] items-center rounded-sm py-2 px-6">
      ${{ type === types.RENT ? distribution.rental_price : distribution.purchase_price }}
    </span>
  </button>
</ng-template>
