import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-control-error-message',
  templateUrl: './control-error-message.component.html',
  styleUrls: ['./control-error-message.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ]
})
export class ControlErrorMessageComponent implements OnInit {
  @Input({required: true}) controlName: string;
  formControl: AbstractControl;

  constructor(private parentForm: FormGroupDirective) {}

  ngOnInit(): void {
    this.formControl = this.parentForm.form.get(this.controlName)
  }
}
