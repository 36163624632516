import { SafeHtml, SafeUrl } from '@angular/platform-browser';
import { UserVerificationStatus as VerificationStatusEnum } from './shared/enumerations';
import { Observable } from 'rxjs';
import { EntityAction, EntityTypeEnum } from './shared/interface/entity-type-enum';
import { DistributionReview, ProposalReview } from './shared/interface/review';

export type Moderations = Proposal | Distribution | CreativeQuery | ProposalReview | DistributionReview;

export class HorizontalSliderProperties {
  page?: number;
  paginationCount?: number;
  leftPositionSlider?: number;
  nxtDisabled?: boolean;
  hideEntities?: boolean;
  customWidth?: number;
  cardWidth?: number;
  parentWidth?: number;
  title: string;
  skip?: number;
  data$?: Observable<Moderations[]>;
  entityType?: EntityTypeEnum;
  id?: string;
  count?: number;
  minimum_participants_to_go?: number;
  start_date?: Date;
  submission_deadline_date?: Date;
  end_date?: Date;
  status?: string;
}
export class ProposalRound {
  id: string;
  title: string;
  hide_scores?: boolean;
  synopsis?: string;
  cover_url?: string;
  cover_type?: string;
  cover_file_id?: string;
  thumbnailUrl?: string;
  cover_thumbnail_url?: string;
  status: string;
  project_type?: string;
  start_date: Date;
  end_date?: Date;
  submission_deadline_date?: Date;
  minimum_bounty?: number;
  submission_fee?: number;
  reward?: number;
  round_tooltip?: string;
  rules: string;
  eligibility?: string;
  scores?: Scores[];
  proposals?: Proposal[];
  total_proposals?: number;
  total_reviews?: number;
  total_likes?: number;
  top_proposal_score?: number;
  top_proposal_review_bounty?: number;
  demography?: Demography;
  is_demography?: boolean;
  is_open?: boolean;
  is_general_review_round?: boolean;
  total_stake?: number;
  avg_user_reputation?: number;
  is_finalized?: boolean;
  proposal_count: number;
  proposal_vote_count: number;
  showRoundDetails?: boolean;
  is_completely_paid?: boolean;
  minimum_participants_to_go?: number;
  project_cut_off_date?: Date;
  delay_to_submission_deadline?: number;
  delay_to_end?: number;
  proposal_header_fields?: {
    block_title: string;
    block_subtitle: string;
    title: {
      caption: string;
      tooltip: string;
      placeholder: string;
    };
  };
  proposal_description_fields?: {
    proposal_detail_changed: boolean;
    block_title: string;
    block_subtitle: string;
    summary: {
      title: string;
      tooltip: string;
      placeholder: string;
    };
    description: {
      title: string;
      tooltip: string;
      placeholder: string;
    };
  };
  additional_fields?: AdditionalField[];
  order_weight?: number;
  updated_at?: string;
  raw_updated_at?: string;
  created_at?: string;
  raw_created_at?: string;
  cover_video_data?: Resolution[];
  total_bounty?: number;
  total_review_stake?: number;
  rep_to_review_no_limit?: number;
  stake_to_review_no_limit?: number;
  min_review_score_limit?: number;
  max_review_score_limit?: number;
}

export interface BetaTester {
  id: string;
  name: string;
  invites_allowed: number;
  token: string;
  users_joined: string[];
  updated_at: string;
  raw_updated_at: string;
  created_at: string;
  raw_created_at: string;
}

export interface AdditionalField {
  title: string;
  tooltip: string;
  placeholder: string;
  value?: string;
}

export interface RoundExplorerData {
  round: ProposalRound;
  proposals?: Proposal[];
}

export interface RoundProgress {
  count: number;
  completed: boolean;
  percentProgress: number;
  type?: string;
  tag?: string;
}

export interface ProposalRoundType {
  id: string;
  stats?: ProposalRoundStats;
  rules?: string;
  table?: any; // any is ok here
}

export interface ProposalRoundStats {
  rules?: string;
  total_reviews?: number;
  total_likes?: number;
  top_proposal_score?: number;
  top_proposal_review_bounty?: number;
}

export class ProposalRoundResults {
  id: string;
  total_proposals: number;
  total_reviews: number;
  total_likes: number;
  top_proposal_score: number;
  top_proposal_review_bounty: number;
  total_bounty: number;
  is_demography: boolean;
  is_open: boolean;
  proposal_count: number;
  proposal_vote_count: number;
  total_stake: number;
  total_numerical_scores?: number;
}

export class CountryCode {
  name: string;
  twoLetterCode: string;
  threeLetterCode: string;
  countryCode: string;
}

export class State {
  id: number;
  name: string;
  country_id: number;
  country_code: string;
  country_name: string;
  state_code: string;
  type: any;
  latitude: string;
  longitude: string;
}

export class Demography {
  id?: string;
  type?: string;
  reference_mongo_id?: string;
  reference_id?: string;
  updated_at?: string;
  raw_updated_at?: string;
  created_at?: string;
  raw_created_at?: string;
  gender: { gender: string }[];
  region: { region: string }[];
  country: { country: string }[];
  age_group: { age_group: string; age_from: number; age_to: number }[];
}

export interface DistributionDemography {
  country_exclude: { country: string }[];
}

export interface PurchaseRequest {
  id: string;
  reference_id: string;
  user_id: string;
  status: string;
  updated_at: string;
  raw_updated_at: string;
  created_at: string;
  raw_created_at: string;
  amount_film: number;
  amount_usdc: number;
  amount_usd: number;
  purchase_fee: number;
  expires_at: string;
  purchase_type: string;
  submission_transaction: string;
  nonce?: number;
  signature?: string;
  pk?: string;
}

export interface ExternalCheckout {
  id: string;
  type: string;
  reference_id: string;
  acttion: string;
  status: string;
  stripe_redirect_url: string;
  coinbase_hosted_url: string;
}

export class Proposal {
  id: string;
  title: string;
  summary: string;
  content: string;
  user_mongo_id: string;
  user_id: string;
  bounty: number;
  extra_bounty?: number;
  balance: number;
  status: string;
  proposal_finance_type: string;
  proposal_finance_tier: string;
  proposal_content_type: string;
  blockchain_id?: string;
  blockchain_confirmed?: boolean;
  submission_contribution: string;
  submission_transaction: string;
  resubmission_contribution?: string;
  resubmission_transaction?: string;
  extension_fee?: number;
  extension_contribution?: string;
  extension_transaction?: string;
  score: number; // blockchain score
  avg_score: number; // average review score
  staked: number;
  created_at: Date;
  updated_at: Date;
  raw_updated_at: Date;
  raw_created_at: Date;

  /** Proposal round related */
  has_participated?: boolean; // is participated in proposal round
  proposal_round_id?: string;
  proposal_round_mongo_id?: string;
  /** Auth user related */
  is_own_proposal: boolean; // is current proposal owned by current auth user
  has_reviewed_proposal: boolean; // is current proposal reviewed by current user
  own_review_status?: string; // if user has_reviewed_proposal this attr shows review's status
  liked_related_review: boolean; // is current proposal.review liked by current user. User can like only one related to current proposal review.

  genres?: Genre[] | string[];
  statistics?: ProposalStatistic;
  user: User;
  reviews?: ProposalReview[];
  revisions?: Array<Revision>;
  proposal_content_type_full: ContentType;
  moderations?: Array<Moderation>;
  languages?: Language[] | string[];
  contributions?: Array<Contribution>;

  /** Files related */
  cover_id: string; // foreign key
  cover?: UploadedFile; // cover object
  cover_type: string;
  cover_url: string;
  cover_image: string;
  videos?: VideoItem[];
  images?: ImageItem[];
  docs?: DocsItem[];
  additional_fields?: AdditionalField[];

  attached_crew: string;
  is_general_review: boolean;
  locked_by?: string;
  locked_at?: Date;

  // ADDED extra Fields  avoid type error
  proposal_round?: ProposalRound;
  last_extended_at?: string;
  approved_at?: Date;
  reviews_skip?: number;
  reviews_scroll?: number;
  reviews_api_reached: boolean;
  votes_score?: number;
  reviews_score?: number;
  evaluations_score?: number;
  likes_score?: number;
  stake_score?: number;
  ai_report_id?: string;
  submission_fee?: number;
  resubmission_fee?: number;
  status_changes?: UserActivityLog[];
  is_expired?: boolean;
  external_checkout_payment?: ExternalCheckout;

  is_finalist?: boolean; // is proposal round finalist (winner)
  is_completely_paid?: boolean;
  is_finalized?: boolean;
  is_published_token_gains?: boolean;
  is_paid_reputation_gains?: boolean;
  is_unlocked_stakes?: boolean;
  is_completely_stored?: boolean;
  is_stored_token_gains?: boolean;
  is_stored_reputation_gains?: boolean;
  is_stored_unlock_stakes?: boolean;

  // calculated attr
  total_stake?: number;
  total_contributed_bounty?: number;
  enable_chat?: boolean;
  hidden_publicly?: boolean;
  chat_channel_id?: string;
  results?: ProposalResult;
  rewards?: string;
  video_data_url?: string;
  pending_payment_id?: string;
  log_line?: string;
  description?: string;
  complete_data_fetched?: boolean;
  teaser?: Video;
  video_data?: Video;
}

export interface DistributionEarning {
  id: string;
  amount: number;
  amount_usd: number;
  user_id: string;
  type: string;
  video_distribution_id: string;
  master_earning_id: any;
  op_hash: string;
  updated_at: string;
  raw_updated_at: string;
  created_at: string;
  raw_created_at: string;
}

export interface DistributionList extends HorizontalSliderProperties {
  title: string;
  skip: number;
}

export interface CastCrew {
  character_name?: string;
  role?: string;
  name: string;
  imdb_link: string;
}
export class Distribution extends Proposal {
  balance_usdc?: number;
  is_earnable_review?: boolean;
  balance_film_available: number;
  total_rent?: number;
  is_featured: boolean;
  is_stared: boolean;
  isTopRated?: boolean;
  stars: number;
  total_buy?: number;
  total_pending_rent?: number;
  total_pending_buy?: number;
  total_income_film?: number;
  total_paid?: number;
  is_external_checkout?: boolean;
  is_updating_royalty_map?: boolean;
  royalty_map: RoyaltyMap[];
  access_type: string;
  purchase_price: number;
  rental_price: number;
  review_discount_percentage: number;
  review_reward_percentage: number;
  reward_contribution: number;
  isStepOneValid?: boolean;
  isStepTwoValid?: boolean;
  moderation_fee: number;
  demography?: DistributionDemography;
  distribution_id?: string;
  mpa_rating: MpaRating;
  is_paying_out: boolean;
  affiliate_marketing: boolean;
  affiliate_marketing_percentage: number;
  purchase: VideoDistributionPurchase;
  priority_stakeholder_amount_film: number;
  is_usdc_supported: boolean;
  is_film_supported: boolean;
  priority_stakeholder_amount_usd: number;
  wallet_address_usdc: string;
  has_reviewed_video_distribution: boolean;
  cast: CastCrew[];
  crew: CastCrew[];
  is_reviewable?: boolean;
  has_reviewed: boolean;
  expired_at: string;
}

export interface VideoDistributionPurchase {
  id: string;
  reference_id: string;
  user_id: string;
  status: string;
  updated_at: string;
  raw_updated_at: string;
  created_at: string;
  raw_created_at: string;
  amount_film: number;
  amount_usd: number;
  expires_at: string;
  is_external_checkout: boolean;
  purchase_type: string;
  submission_transaction: string;
  pending_payment_id: string;
  external_checkout_payment: ExternalCheckout;
  video_distribution: Distribution;
  play_duration: number;
}

export interface RoyaltyMap {
  wallet_address: string;
  name: string;
  reward_percentage: number;
  priority_stakeholder_amount: number;
  is_priority_stakeholder: boolean;
}

export interface ProposalResult {
  id: string;
  proposal_id: string;
  gender_stat: GenderResults;
  region_stat: RegionResults;
  ethnicity_stat: EthnicityResults;
  age_stat: AgeResults;
  all_stat: ProposalResultItem;
  top_ten_stat: ProposalResultItem;
}

export interface GenderResults {
  M: ProposalResultItem;
  F: ProposalResultItem;
  O: ProposalResultItem;
  'N/A': ProposalResultItem;
  'All genders': ProposalResultItem;
}

export interface RegionResults {
  Africa: ProposalResultItem;
  Americas: ProposalResultItem;
  Asia: ProposalResultItem;
  Europe: ProposalResultItem;
  Oceania: ProposalResultItem;
  Other: ProposalResultItem;
  'All regions': ProposalResultItem;
}

export interface AgeResults {
  'Ages 15-24': ProposalResultItem;
  'Ages 25-54': ProposalResultItem;
  'Ages 55-64': ProposalResultItem;
  'Ages 65+': ProposalResultItem;
  Other: ProposalResultItem;
  'All ages': ProposalResultItem;
}

export interface EthnicityResults {
  'Choose not to answer': ProposalResultItem;
  'Person of African Origin': ProposalResultItem;
  'Person of Asian Origin': ProposalResultItem;
  'Person of European Origin': ProposalResultItem;
  'Person of Hispanic or Latin Origin': ProposalResultItem;
  'Person of Indigenous Origin': ProposalResultItem;
  'Person of Middle Eastern Origin': ProposalResultItem;
}

export interface ProposalResultItem {
  total_votes: number;
  total_stake: number;
  total_evaluations: number;
  total_reviews: number;
  total_likes: number;
  total_reputations: number;
  avg_score: number;
  alg_score: number;
  demographic: string;
}

export interface VideoItem {
  file_id?: string;
  url: string;
  thumbnailUrl?: string;
  mediaTitle: string;
  description?: string;
  isDirectVideoUpload?: boolean;
  converted?: boolean;
  failed?: boolean;
  resolutions?: Resolution[];
  videoBlob?: Blob;
}

export interface ImageItem {
  file_id?: string;
  url: string;
  thumbnailUrl?: string;
  mediaTitle?: string;
  description?: string;
  isDirectVideoUpload?: boolean;
  converted?: boolean;
  failed?: boolean;
}

export interface DocsItem {
  url: string;
  docTitle?: string;
  description?: string;
  file_preview_url?: string;
}

export interface ProposalStatistic {
  score?: number;
  total_votes: number;
  balance: number;
  total_stake: number;
  reviews_score: number;
  evaluations_score: number;
  likes_score: number;
  average_votes: number;
  total_bounty: number;
  average_bounty: number;
  scores?: { date: string; score: number }[];
  bounty?: { date: string; total: number }[];
  votes?: { date: string; total: number }[];
  stake?: { date: string; total: number }[];
}

export class CreativeQuery {
  id?: string;
  blockchain_id?: string;
  blockchain_confirmed?: boolean;
  submission_transaction: string;
  submission_fee: number;
  resubmission_transaction: string;
  resubmission_fee: number;
  title: string;
  bounty: number;
  balance: number;
  status: string;
  staked?: number;
  total_stake: number;
  total_votes: number;
  has_answer_waiting_for_submission: boolean;
  query_age: number;
  created_at?: Date;
  expired_at?: Date;
  approved_at?: Date;
  raw_created_at?: string;
  updated_at?: Date;
  raw_updated_at?: Date;
  current_user_submit?: CreativeQuerySubmit;
  cover?: string;
  cover_color?: string;
  locked_by?: string;
  locked_at?: Date;
  user: User;
  user_id?: string;
  revisions?: Array<Revision>;
  questions?: Array<Question>;
  submissions?: Array<CreativeQuerySubmit>;
  demography?: Demography;

  is_finalized?: boolean;
  reviewers_payed_out?: boolean;
  stake_unlocked?: boolean;
  refund_payed_out?: boolean;
  enable_chat?: boolean;
  external_checkout_payment?: ExternalCheckout;
  pending_payment_id?: string;
}

export class Question {
  id?: string;
  index: number;
  description: string;
  type: string;
  creative_query_id: string;
  creative_query_mongo_id?: string;
  user_id?: string;
  created_at?: string;
  raw_created_at?: string;
  updated_at?: string;
  raw_updated_at?: string;
  options?: Array<Option>;
}

export class Option {
  id?: string;
  index: number;
  value: string;
  title?: string;
  description?: string;
  type: string;
  question_id: string;
  video_converted?: boolean;
  video_conversion_failed?: boolean;
  thumbnail_url?: string;
  question_mongo_id?: string;
  video_resolutions?: Resolution[];
  user_id?: string;
  file_id?: string;
  created_at?: string;
  updated_at?: string;
  isSaved?: boolean;
  isEdit?: boolean;
  videoData?: SafeHtml | string;
  isDirectVideoUpload?: boolean;
  videoBlob?: Blob;
  videoBlobUrl?: SafeUrl;
  updatedValue?: string;
}

export class CreativeQueryResult {
  basic_stat: CQBasicStatistic[];
  gender_stat: CQGenderStatistic[];
  region_stat: CQRegionStatistic[];
  age_stat: CQAgeStatistic[];
  ethnicity_stat;
  question_option_mapping: QuestionOptionMapping[];
  length?: number;
}

export class CQBasicStatistic {
  id: string;
  options: CqBasicStatisticOption[];
}

export class QuestionOptionMapping {
  question_id: string;
  total_options: number;
}

export class CqBasicStatisticOption {
  option: string;
  total_votes: number;
  option_index: number;
}

export class CQGenderStatistic {
  id: string;
  options: CqGenderStatisticOption[];
}

export class CqGenderStatisticOption {
  option: string;
  option_index?: number;
  votes_by_gender: {
    user_gender: string;
    total_votes: number;
    demographic?: string;
  }[];
}

export class CQRegionStatistic {
  id: string;
  options: CQRegionStatisticOption[];
}

export class CQRegionStatisticOption {
  option: string;
  option_index?: number;
  votes_by_region: {
    user_region: string;
    total_votes: number;
    demographic?: string;
  }[];
}

export class CQAgeStatistic {
  id: string;
  options: CQAgeStatisticOption[];
}

export class CQAgeStatisticOption {
  option: string;
  option_index?: number;
  votes_by_age: {
    age_group: string;
    total_votes: number;
    demographic?: string;
  }[];
}

export class CQResultTable {
  [_: string]: CQResultOptionColumn;
}

export class CQResultOptionColumn {
  option_name: string;
  statistic_data: {
    reputation: CQResultOptionRow;
    stake: CQResultOptionRow;
    score: CQResultOptionRow;
    votes: CQResultOptionRow;
  };
}

export class CQResultOptionRow {
  Total: number;
  gender: {
    M: number;
    F: number;
    O: number;
    'N/A': number;
  };
  age_groups: {
    '15-24 years': number;
    '25-54 years': number;
    '55-64 years': number;
    '65 years and over': number;
    'N/A': number;
  };
  regions: {
    Africa: number;
    Americas: number;
    Asia: number;
    Europe: number;
    Oceania: number;
    'N/A': number;
  };
}

export class CreativeQuerySubmit {
  id: string;
  creative_query_mongo_id: string;
  creative_query_id: string;
  creative_query_blockchain_id: string;
  creative_query_question_id: string;
  user_mongo_id: string;
  user_id: string;
  updated_at: number;
  raw_updated_at: number;
  created_at: number;
  raw_created_at: number;
  chosen_option: Array<number>;
  selected_value: string;
  selected_index: number;
  blockchain_id: string;
  stake: number;
  additional_stake: number;
  status: string;
  submission_stake: string;
  submission_transaction: string;
}

export class PastQuizResults {
  id: string;
  title: string;
  bounty: number;
  participants_qty: number;
  questions_qty: number;
  has_finished_quiz: boolean;
  rounds: [];
}

export interface QuizResult {
  id: string;
  title: string;
  bounty: number;
  participants_qty: number;
  rounds: Array<QuizDetails>;
}

export interface QuizShortStatistic {
  answers_given: number;
  correct_answers_given: number;
}

export interface QuizDetails {
  id: string;
  user_mongo_id: string;
  user_id: string;
  quiz_mongo_id: string;
  quiz_id: string;
  quiz_questions?: Array<{ valid: string }>;
  score: number;
  user: {
    id: string;
    name: string;
  };
}

export interface SwagShippings {
  id: string;
  name: string;
  rate: number;
  currency: string;
  minDeliveryDays: number;
  maxDeliveryDays: number;
  minDeliveryDate: string;
  maxDeliveryDate: string;
}

export interface SwagShippingRequest {
  recipient: Recipient;
  items: SwagItem[];
}

export interface Recipient {
  name: string;
  address1: string;
  city: string;
  state_code: string;
  country_code: string;
  zip: string;
  email: string;
  phone: string;
}

export interface SwagItem {
  variant_id: string;
  quantity: number;
}

export class ProposalRequest {
  title: string;
  project_type?: string;
  languages?: string[];
  genres?: string[];
  summary: string;
  content: string;
  bounty?: number;
  extra_bounty?: number;
  attached_crew?: string;
  rewards?: string;
  videos?: VideoItem[];
  images?: ImageItem[];
  docs?: DocsItem[];
  cover_url?: string;
  is_external_checkout?: boolean;
  cover_type?: string;
  password?: string;
  submission_fee?: number;
  resubmission_fee?: number;
  enable_chat: boolean;
}

export class SubmitProposalRequest {
  proposalId: string;
  signature: string;
  nonce: number;
  pk: string;
}

export class SubmitCreativeQueryRequest {
  creativeQueryId: string;
  signature: string;
  nonce: number;
  pk: string;
}

export class SubmitTransactionRequest {
  transactionId: string;
  sig: string;
  nonce: number;
  pk: string;
}

export class TransferToMainnetRequest {
  password: string;
  main_address: string;
  amount: number;
}

export class SubmitLikeRequest {
  reviewId: string;
  likeId: string;
  is_like: boolean;
  signature: string;
  nonce: number;
  pk: string;
}

export class AnswerCreativeQueryRequest {
  chosen_option: [];
  stake: number;
  creative_query_id: string;
  creative_query_question_id: string;
}

export class SubmitCreativeQuerySubmissionRequest {
  submitId: string;
  signature: string;
  nonce: number;
  pk: string;
}

export class ProposalHistory {
  key: string;
  old_value: string;
  new_value: string;
  created_at: number;
}

export class User {
  id: string;
  email: string;
  name: string;
  user_type_id: number;

  sms_verification_status: VerificationStatusEnum;
  sms_verification_code?: string;
  email_verification_status: VerificationStatusEnum;
  email_verification_code?: string;
  kyc_verification_status: VerificationStatusEnum;

  submission_id?: string;
  cognito_flow_id?: string;
  wallet_address?: string;
  has_client_key?: boolean;
  wallet_restore_required?: boolean;
  reputation: number;
  google2fa_enabled?: boolean;
  balance?: number;
  locked?: number;
  avatar?: string;
  transactions_in?: Array<Transaction>;
  transactions_out?: Array<Transaction>;
  proposals?: Array<Proposal>;
  activity?: UserActivityLog[];
  creative_queries?: CreativeQuery[];
  moderations?: Moderation[];
  mainnet_from_requested?: boolean;
  created_at: string;
  notifications_count?: number;
  is_dark_theme: boolean;
  is_grayscale: boolean;
  is_suspended?: boolean;
  is_beta_tester?: boolean;
  favoriteGenre?: string;
  favoriteFilm?: string;
  favoriteDirector?: string;
  bio?: string;
  ethnicity?: string[];
  personal_website_url?: string;
  facebook_url?: string;
  youtube_url?: string;
  instagram_url?: string;
  twitter_url?: string;
  vimeo_url?: string;
  imdb_url?: string;
  visible_public_profile_data?: string[];
  first_name?: string;
  last_name?: string;
  sex?: string;
  dob?: string;
  street?: string;
  zip_postal?: string;
  country?: string;
  avatar_url?: string;
  mobile?: string;
  moderation_in_progress?: boolean;
  to_be_moderated_entity?: { id: string; entity: string };
  user_engagement?: UserEngagementResponse;
  reputation_earnings?: UserReputationEarning[];
  token_earnings?: UserTokenEarning[];
  could_be_rewarded?: boolean;
  appeal_name?: string;
  age_group?: string | null;
  email_notifications?: boolean;
  updated_at?: string;
  wallet_connected_at?: any;
  external_balance?: any;
  languages?: any;
  simplex_uuid?: string;
  emailChanges?: any;
  wallet_type?: any;
  badges?: any;
}

export interface UserReputationEarning {
  id: string;
  amount: number;
  user_id: string;
  type: string;
  round_id: string;
  proposal_id: string;
  paid_at: Date;
}

export interface UserTokenEarning {
  id: string;
  amount: number;
  user_id: string;
  type: string;
  round_id: string;
  proposal_id: string;
  paid_at: Date;
}

export class UserAccessLog {
  created_at: string;
  created_by: string;
  raw_created_at: string;
  raw_updated_at: string;
  updated_at: string;
  user_id: string;
  user_name: string;
  user_type_id: number;
  id: string;
}

export class UserActivityLog {
  id: string;
  type: string;
  action: string;
  model_id: string;
  created_at: string;
  updated_at: string;
  raw_created_at: string;
  raw_updated_at: string;
  updates?: [];
  user_mongo_id?: string;
  user_id?: string;
  is_deleted?: boolean;
  key?: string;
  old_value?: string;
  new_value?: string;
  actor?: string;
  entity?: string;
  message?: string;
  user: User;
}

export class Transaction {
  id: string;
  status: string;
  type: string;
  payment_id: string;
  recipient_user_id?: string;
  sender_user_id?: string;
  recipient_mainnet?: string;
  seed: string;
  reference_id: string;
  blockchain_reference_id: string;
  blockchain_id?: string;
  confirm_block?: number;
  event?: string;
  sender_external_address?: string;
  recipient_external_address?: string;
  recipient_wallet?: string;
  recipient?: User;
  sender: User;
  amount: number;
  to_address: string;
  from_address: string;
  is_zero_day?: boolean;
}

export class UserVerification {
  id: string;
  type: string;
  status: string;
  jumioAuthorizationToken: string;
  jumioIdScanReference: string;
  jumioTimestamp: number;
  cognito_profile_id: string;
  onfidoApplicantId: string;
}

export class Price {
  FILM: number;
  last_updated: number;
}

export class PaymentRequest {
  type: string;
  amount: number;
  tokens: number;
  price: number;
  is_fake: boolean;
  is_zero_day?: boolean;
  simplex_quote_id?: string;
  action?: string;
  reference_id?: string;
}

export class ExternalCheckoutRequest {
  action: string;
  reference_id: string;
  payment_id: string;
  type: string;
  internal_portion?: number;
}

export class Payment {
  id: string;
  type: string;
  status: string;
  amount_usd: number;
  amount_fees: number;
  amount_total: number;
  token_amount: number;
  coinbase_pricing?: string;
  coinbase_addresses?: string;
  coinbase_hosted_url?: string;
  coinbase_metadata?: string;
  coinbase_expires_at?: Date;
  blockchain_id?: string;
  coinbase_code: string;
  transaction?: Transaction;
  simplex_payment_success_url?: string;
  simplex_payment_failed_url?: string;
  simplex_payment_id?: string;
  stripe_redirect_url?: string;
  stripe_checkout_id?: string;
}

export class DashboardResponse {}

export class UserCreateRequest {
  email: string;
  name: string;
  password: string;
  password_confirmation: string;
}

export class UserRoleRequest {
  user_role_code: number;
}

export class UserVerificationStatusRequest {
  verification_status_code: number;
}

export class Language {
  id: string;
  iso: string;
  name: string;
}

export class TokenEarning {
  id: string;
  amount: number;
  user_id: string;
  type: string;
  proposal_id: string;
  user: User;
  paid_at?: Date;
  proposal?: Proposal;
}

export class ReputationEarning {
  id: string;
  amount: number;
  user_id: string;
  type: string;
  proposal_id: string;
  user: User;
}

export class Country {
  id: string;
  iso_2: string;
  iso_3: string;
  name: string;
  country_code: string;
}

export class Ethnicity {
  id: string;
  name: string;
}

export class UserVerificationStatus {
  id: string;
  code: number;
  name: string;
}

export class UserRole {
  id: string;
  code: number;
  name: string;
}

export class SimpleList {
  id?: string;
  slug: string;
  name: string;
}
export class MpaRating extends SimpleList {}

export class Genre extends SimpleList {}

export class Region extends SimpleList {}

export class FinanceType extends SimpleList {}

export class FinanceTier extends SimpleList {}

export class ContentType extends SimpleList {}

export class UploadedFile {
  id: string;
  file_url: string; // URL path
  file_rel: string; // server(AWS) path
  file_preview_url?: string;
  updated_at: number;
  raw_updated_at: number;
  created_at: number;
  raw_created_at: number;
  type: string;
  conversion_failed?: boolean;
  converted?: boolean;
  data?: UploadedImagePath;
}

export class UploadedImagePath {
  image_path?: CustomFileData;
}

export interface EmailChangeRequest {
  token: string;
  email: string;
  email_confirmation: string;
}

export interface PasswordChangeRequest {
  token: string;
  password: string;
  password_confirmation: string;
}

export interface ModerationRejectReason {
  id: string;
  description: string;
  value: string;
}

export interface Moderation {
  id: string;
  title?: string;
  content?: string;
  status: string;
  user_mongo_id: string;
  user_id: string;
  created_at: number;
  updated_at: number;

  /** Model related */
  proposal_mongo_id?: string;
  proposal_id?: string;

  review_id?: string;
  review_mongo_id?: string;

  creative_query_id?: string;
  creative_query_mongo_id?: string;

  slim_proposal?: string;
  slim_review?: any;
  type: string;
  creative_query?: string;
  reason?: string;
  comments?: string;
  user?: User;
  raw_updated_at?: string;
  raw_created_at?: string;
  quiz_question?: any;
}

export interface ModerationRequest {
  moderation_type: string;
  model_id: string;
  status: string;
  comments: string;
  reason?: string;
}

export interface Contribution {
  id: string;
  amount: number;
  message: string;
  status: string;
  reference_id: string;
  reference_mongo_id: string;
  proposal_id: string;
  proposal_mongo_id: string;
  user_id: string;
  user_mongo_id: string;
  blockchain_id: string;
  created_at: number;
  raw_created_at: number;
  updated_at: number;
  raw_updated_at: number;
}

export interface Revision {
  id: string;
  revisionable_type: string;
  revisionable_id: string;
  key: string;
  old_value: string;
  new_value: string;
  user_id: string;
  created_at: Date;
  updated_at: Date;
}

export interface GlobalPricesResponse {
  proposal_submit: number;
  proposal_resubmit: number;
  creative_query_submit: number;
  like_review: number;
  general_review_extension: number;
}

export interface GlobalStatisticResponse {
  daily_quick_rewards: number;
  highest_rated_submission_in_round: number;
  highest_contribution_per_round: number;
  top_trending: number;
}

export interface LaravelPassportAuthResponse {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
}

export interface Google2FaResponse {
  qrCodeURI: string;
  secret: string;
}

export interface TransactionRequest {
  address: string;
  amount: number;
  confirm_address?: string;
}

export interface RegisterRequest {
  email: string;
  name: string;
  mobile: string;
}

export interface ProfileUpdateRequest {
  first_name: string;
  last_name: string;
  sex: string;
  dob: Date;
  street: string;
  zip_postal: string;
  country: string;
  languages: string[];
  email?: string;
  name?: string;
  mobile?: string;
}

export interface ProfileAboutUpdateRequest {
  bio: string;
  favoriteGenre: string;
  favoriteFilm: string;
  favoriteDirector: string;
  ethnicity: string;
}

export enum DcpPlusType {
  FREE = 'free-to-watch',
  PAID = 'pay-to-watch',
}

export enum MediaType {
  IMAGE = 'image',
  VIDEO = 'video',
  FILE = 'file',
  DOC = 'doc',
  TEXT = 'text',
}

export enum ExploreActions {
  OPEN = 'open',
  MOUSEOVER = 'mouseOver',
  MOUSEOUT = 'mouseOut',
  CLOSE = 'close',
}

export enum ProcessStatus {
  WAITING_FOR_SUBMISSION = 'waiting-for-submission',
  WAITING_FOR_CONFIRMATION = 'waiting-for-confirmation',
  WAITING_FOR_PAYMENT = 'waiting-for-payment',
  TO_BE_MODERATED = 'to-be-moderated',
  APPROVED = 'approved',
  DENIED = 'denied',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  REMOVED = 'removed',
  FINISHED = 'finished',
  ARCHIVED = 'archived',
  CONFIRMED = 'confirmed',
  ACTIVE = 'active',
}

export enum MultiViewActions {
  CLICK_PROPOSAL = 'click-proposal',
  CLICK_DISTRIBUTION = 'click-distribution',
  CLICK_CQ = 'click-cq',
  CLICK_PROPOSAL_ACTION = 'click-proposal-action',
  MOUSE_OVER = 'mouse-over',
  MOUSE_OUT = 'mouse-out',
  MOVE_SLIDER = 'move-slider',
  EXPLORE = 'explore',
  PURCHASE = 'purchase',
}

export enum ExternalCheckoutAction {
  PROPOSAL_SUBMIT = 'proposal_submit',
  DISTRIBUTION_SUBMIT = 'distribution_submit',
  PROPOSAL_RESUBMIT = 'proposal_resubmit',
  DISTRIBUTION_RESUBMIT = 'distribution_resubmit',
  CREATIVE_QUERY_SUBMIT = 'cq_submit',
  CREATIVE_QUERY_RESUBMIT = 'cq_resubmit',
  REVIEW_SUBMIT = 'review_submit',
  VIDEO_DISTRIBUTION_PURCHASE_SUBMIT = 'vd_purchase_submit',
}

export interface PricingFees {
  proposal_resubmission_fee: number;
  proposal_moderation_fee: number;
  review_moderation_fee: number;
  proposal_extension_fee: number;
  creative_query_submission_fee: number;
  creative_query_resubmission_fee: number;
  creative_query_moderation_fee: number;
  proposal_resubmission_fee_in_dollar: number;
  proposal_moderation_fee_in_dollar: number;
  review_moderation_fee_in_dollar: number;
  proposal_extension_fee_in_dollar: number;
  creative_query_moderation_fee_in_dollar: number;
  creative_query_resubmission_fee_in_dollar: number;
  creative_query_submission_fee_in_dollar: number;
  video_distribution_submission_fee?: number;
  video_distribution_submission_fee_in_dollar?: number;
  video_distribution_resubmission_fee?: number;
  video_distribution_resubmission_fee_in_dollar?: number;
  video_distribution_moderation_fee_in_dollar?: number;
  sign_up_fee: number;
  kyc_fee: number;
  price_of_film: number;
  price_of_film_sale: number;
  price_of_film_last_updated: string;
  available_film_tokens: number;
  credit_card_percent_fee: number;
  credit_card_fee_minimum_in_dollar: number;
}

export class FileProgressObject {
  file: File;
  state: string;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  cover?: boolean;
  type: string; // pdf or image
}

export class ModerationApplication {
  id?: string;
  reviewedBy: string;
  reviewDate: Date;
  status: string; // pending // approved // rejected
  reviewComments: string;
}

export interface UserNotification {
  id: string;
  activity_id: string;
  user_id: string;
  title: string;
  description: string;
  channel: string;
  status: string;
  is_deleted: string;
  read_time: UserNotificationReadTime;
  read_device: string;
  updated_at: Date;
  raw_updated_at: string;
  created_at: Date;
  raw_created_at: string;
}

export interface UserNotificationReadTime {
  date: string;
  timezone_type: number;
  timezone: string;
}

export interface Activity {
  id: string;
  type: string;
  action: string;
  id_deleted: boolean;
  model_id: string;
  updated_at: Date;
  raw_updated_at: string;
  created_at: Date;
  raw_created_at: string;
}

export interface ProposalAIReport {
  id?: string;
  result?: any; // discussed with Jon, is fine for now
  status?: string;
  genre?: any;
  age?: any;
  similar_films?: any;
  top_genes?: any;
  top_genes_correlation?: any;
}

export interface CircleCard {
  id: string;
  circle_id: string;
  circle_network: string;
  circle_last4: string;
  circle_exp_month: number;
  circle_exp_year: number;
}

export interface AuthData {
  grant_type: string;
  username?: string;
  password?: string;
  refresh_token?: string;
  client_id: string;
  client_secret: string;
}

export interface AuthWithWalletData {
  wallet_address: string;
  pk: string;
  sig: string;
  nonce: string;

  client_id: string;
  client_secret: string;
  grant_type: string;
}

export class SellRequest {
  amount: number;
  tokens: number;
  price: number;
  destination: string;
  password: string;
}

export class SubmitSellRequest {
  paymentId: string;
  password: string;
  signature?: string;
  nonce?: number;
}

export class UserEngagementData {
  likes: number;
  likesRight: number;
  review: number;
  reviewRight: number;
  creativeQuery: number;
  creativeQueryRight: number;
  fillChart: number;
  fill: number;
  dasharay: string;
  all: number;
  taken: number;
  potentialEarningTime?: PotentialEarningTime;
  potentialEarning?: number;
}

export interface UserEngagementResponse {
  id: string;
  user_id: string;
  total_engagement_percent: number;
  reviewed_proposal_bounty: number;
  reviewed_proposal_count: number;
  liked_proposal_bounty: number;
  liked_proposal_review_count: number;
  total_proposal_bounty: number;
  reviewed_creative_query_count: number;
  reviewed_creative_query_bounty: number;
  total_proposal_engagement_percent: number;
  total_proposal_review_engagement_percent: number;
  total_proposal_like_engagement_percent: number;
  total_creative_query_engagement_percent: number;
  active_proposal_count: number;
  active_proposal_bounty: number;
  active_creative_query_count: number;
  active_creative_query_bounty: number;
  no_activity_found: boolean;
  updated_at: string;
}

export interface PotentialEarningTime {
  hours?: number;
  minutes?: number;
  seconds?: number;
}

export interface PotentialUserEarnings {
  review_proposal_tokens: number;
  like_proposal_review_tokens: number;
  review_creative_query_tokens: number;
  moderate_proposal_review_tokens: number;
  total_proposal_tokens: number;
  total_creative_query_tokens: number;
  review_proposal_reputation: number;
}

export class Poll {
  id: string;
  title: string;
  description: string;
  questions: [PollQuestion];
  cover: PollCover;
}

export class PollCover {
  id: string;
  alternativeText: string;
  url: string;
}

export class PollQuestion {
  id: string;
  title: string;
  type: string;
  options: [PollQuestionOption];
}

export class PollQuestionOption {
  id: string;
  answer: string;
  name: string; // same as answer
}

export class Quote {
  id: string;
  author: string;
  content: string;
  Media: [Media];
}

export class Media {
  id: string;
  url: string;
  ext: string;
  height: number;
  width: number;
  alternativeText: string;
  caption: string;
  formats: {
    thumbnail: MediaFormat;
    small: MediaFormat;
    medium: MediaFormat;
  };
}

export class MediaFormat {
  ext: string;
  height: number;
  width: number;
  mime: string;
  name: string;
  url: string;
}

export class Announcement {
  id: string;
  content: string;
  type: string; // info system urgent;
  description: string;
  cover: Media;
}

export interface UserPublicProfile {
  visible_public_profile_data: [];
}

export interface Badges {
  id: string;
  name: string;
  slug: string;
  image_url: string;
  about: string;
  description: string;
  is_visible: boolean;
  updated_at: string;
  raw_updated_at: string;
  created_at: string;
  raw_created_at: string;
  is_active?: boolean;
}

export interface UserBadges {
  id: UserBadgesId;
  name: string;
  slug: string;
  image_url: string;
  about: string;
  description: string;
  is_visible: boolean;
  raw_updated_at: string;
  raw_created_at: string;
  is_active?: boolean;
  created_at: UserBadgesDate;
  updated_at: UserBadgesDate;
}

export interface UserBadgesDate {
  $date: {
    $numberLong: string;
  };
}

export interface UserBadgesId {
  $oid: string;
}

export class Instruction {
  id: string;
  pages: {
    id: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  }[];
  title: string;
  published_at: string;
  createdAt: string;
  updatedAt: string;
  __v: number;

  video: {
    id: string;
    __v: number;
    name: string;
    alternativeText: string;
    caption: string;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    provider: string;
    width?: number;
    height?: number;
  };
}

export interface SelectedData {
  direction?: string;
  sortBy?: string;
  status?: string;
}

export interface FilterData {
  search?: string;
  sortBy?: string;
  createdAt?: string;
  direction?: string;
  status?: string;
}

export interface Filters {
  field: string;
  reviewed: string;
  liked: string;
  genres: string[];
  proposal_rounds: string[];
  search: string;
  status: string;
  type: string;
}

export interface DistributionAffiliatedEarning {
  videoDistribution: {
    title: string;
  };
  affiliated_amount_total: number;
  affiliated_payments: number;
}

export interface FilterConfig {
  slug?: string;
  name?: string;
  id?: string;
  selected?: boolean;
}

export interface SearchData {
  search?: string;
}

export interface ProposalMediaData {
  videos?: VideoItem[];
  images?: ImageItem[];
  docs?: DocsItem[];
  cover_id: string;
  cover_url: string;
  cover_type: string;
  rewards?: string;
  proposal_id?: string;
}

export interface AddImageData {
  url?: string;
  mediaTitle?: string;
  description?: string;
}

export interface MediaData {
  title?: string;
  description?: string;
  url?: string;
  video?: string | SafeHtml;
  type?: string;
  docTitle?: DcoTitle;
  mediaTitle?: string;
  id: string;
}

export interface DcoTitle {
  docTitle?: string;
}

export interface FileData {
  url: string;
  type: string;
  name?: string;
  description?: string;
  title?: string;
}

export class DiscordVerificationSuccessResponse {
  discord_user_id: string;
  discord_username: string;
}

export interface ProposalRoundData {
  proposal_round_id: string;
  isValid: boolean;
  selectedRound?: ProposalRound;
}

export class BountyData {
  bounty?: number;
  extra_bounty?: number;
  isValid: boolean;
}

export interface ProposalCoverImageData {
  url?: string;
  type?: string;
  thumb?: string;
}

export interface CoverImageFiles {
  data?: any; // any is ok here
  state?: string;
  inProgress?: boolean;
  progress?: number;
  canRetry?: boolean;
  canCancel?: boolean;
  cover?: boolean;
  type?: string;
}

export interface SaveProposalMediaList {
  index?: number;
  type?: MediaType;
  value?: string | SafeUrl;
  title?: string;
  description?: string;
  fileData?: string;
  videoData?: string | SafeHtml;
  thumbnailUrl?: string;
  file_id?: string;
  isDirectVideoUpload?: boolean;
  videoBlob?: Blob;
}

export interface Video {
  converted?: boolean;
  mediaTitle?: string;
  description?: string;
  url?: string | SafeHtml;
  thumbnailUrl?: string;
  file_id?: string;
  videoBlob?: Blob;
  resolutions?: Resolution[];
}

export interface AboutProposalData {
  title: string;
  content: string;
  summary: string;
  genres: string[];
  languages: string[];
  genreAdd: string;
  isValid: boolean;
  isCoverUploaded: boolean;
  mediaList: SaveProposalMediaList[];
  coverId: string;
  cover_url: string;
  enable_chat: boolean;
  hidden_publicly?: boolean;
  additional_fields?: AdditionalField[];
  rent_price?: number;
  buy_price?: number;
  teaser_url?: string;
  video_data?: Video;
  log_line?: string;
  description?: string;
}

export interface ProposalRoundData {
  proposal_round_id: string;
}

export interface CheckoutData {
  termsAccepted: boolean;
  userBounty?: number;
  bounty: number;
  extra_bounty: number;
  isValid: boolean;
  is_external_checkout: boolean;
}

export interface ProposalActionPreview {
  url: string;
  type: string;
  name?: string;
}
export interface VideoUrlData {
  provider?: string;
  src?: string;
}

export interface ResponseData {
  data?: any; // any is ok here
  type?: any; // any is ok here
  items?: any; // any is ok here
}

export interface DialogData {
  data?: any; // any is ok here
  map?: any; // any is ok here
}

export interface ReputationDialogData {
  id?: string;
  reputation?: number;
}

export interface SuspendDialogData {
  id?: string;
  is_suspended?: boolean;
  name?: string;
  reputation?: number;
}

export interface ProposalExtendDialogData {
  proposal?: Proposal;
  charges?: number;
}

export interface CancelRemoveDialogData {
  action?: EntityAction;
  type?: string;
  confirmRequired?: boolean;
  entity?: Proposal;
}

export interface ConfirmAddressDialogData {
  confirmAddress?: string;
}

export interface LikeConfirmDialogData {
  type?: string;
  entity?: Proposal;
  isLike?: boolean;
}

export interface ModerateDialogData {
  type?: string;
  entity?: Proposal;
}

export interface ModerationInProgressDialogData {
  id?: string;
  entity?: Proposal;
}

export interface PasswordDialogData {
  error?: any; // any is ok here
}

export interface RedirectDialogData {
  title?: string;
  message?: string;
  button?: string;
  link?: string;
}

export interface ConfirmBuyDialogData {
  proposal?: Proposal;
  queryParams?: QueryParamsData;
}

export interface CropperDialogData {
  imageFile: any;
  roundCropper: boolean;
  aspectRatio: number;
  resizeToWidth: number;
}

export interface QueryParamsData {
  redirectUri: string;
  minBuy: number;
}

export interface WalletCheckData {
  walletStatus?: string;
  action?: string;
}

export interface HighScores {
  rank: number;
  score: number;
  user: HighScoresUser;
  user_id: string;
  id: string;
}

export interface HighScoresUser {
  avatar_url: string;
  name: string;
  id: string;
}

export interface HighScoresDaily {
  created_at: string;
  score: number;
  user: HighScoresUser;
  user_id: string;
  id: string;
}

export interface VimeoThumbnailUrl {
  pictures?: any; // any is ok here
}

export interface QuizResult {
  answers_given: number;
  correct_answers_given: number;
}

export interface WalletLog {
  id: string;
  user_id: string;
  action: string;
  address: string;
}

export class PreSignedUrlResponse {
  uploadURL: string;
  fileRel: string;
}

export class UsernameSuggestion {
  name_suggestion: string;
}

export interface CustomFile extends Blob {
  lastModified: number;
  name: string;
  webkitRelativePath: string;
  data?: CustomFileData;
}

export interface CustomFileData {
  id?: string;
  file_url?: string;
  file_preview_url?: string;
}

export interface VideoOption {
  autoplay?: boolean;
}

export interface Scores {
  name: string;
}

export interface CreativeQueryOption {
  text?: string;
  type?: string;
  source?: VideoUrlData[];
  queryId?: string;
  title?: string;
  thumbnailUrl?: string;
  values?: {
    reputation?: CQResultOptionRow;
    score?: CQResultOptionRow;
    stake?: CQResultOptionRow;
    votes?: CQResultOptionRow;
  };
}

export interface VideoSlidePlyr {
  src?: string;
  provider?: string;
}

export interface SystemSetting {
  name: string;
  title?: string;
  type?: string;
  value: any; // any is ok here
  rule?: string;
}

export interface CirclePublicKey {
  keyId: string;
  publicKey: string;
}

export interface NFT {
  id: string;
  image_url: string;
  name: string;
  user_id: string;
  contract_address: string;
  edition: string;
  copy_number: number;
  attributes: {
    name: string;
    value: string;
  };
}

export interface ReferralData {
  referralURL: string;
  usersJoined: number;
  totalEarned: number;
  timesAwarded: number;
  usersTier: string;
  monthlyInvitesQuota: number;
  invitesThisMonth: number;
}

export interface UserBalanceData {
  internalBalance: number;
  externalBalance: number;
  lockedBalance: number;
  total: number;
}

export interface Resolution {
  file_url: string;
  video_details: {
    widthInPx: number;
    heightInPx: number;
    averageBitrate?: number;
    qvbrAvgQuality?: number;
    qvbrMinQuality?: number;
    qvbrMaxQuality?: number;
    qvbrMinQualityLocation?: number;
    qvbrMaxQualityLocation?: number;
    duration_sec?: number;
  };
  isActive?: boolean;
}

export interface WalletLoginData {
  address: string;
  pk: string;
  nonce: string;
  email: string;
  sig: string;
  opt_2faCode?: string;
}

export interface SwagProduct {
  id: string;
  name: string;
  thumbnail_url: string;
  product: { description: string };
  product_variants: ProductVariant[];
  images: [string];
  usd_price: number;
  options: any;
  addedToCart: boolean;
  quantity: number;
  size: string;
  color: string;
  variant_id: string;
}
export interface ProductVariant {
  id: number;
  external_id: string;
  sync_product_id: number;
  name: string;
  synced: boolean;
  variant_id: number;
  main_category_id: number;
  warehouse_product_variant_id: any;
  retail_price: string;
  sku: string;
  currency: string;
  is_ignored: boolean;
  size: string;
  color: string;
  availability_status: string;
  variant_options?: string[];
}

export const MediaTypes = [
  {
    label: 'Videos',
    type: MediaType.VIDEO,
    media: 'video/*',
    description:
      'Add any videos you wish to include with your project submission. The first file uploaded will be used for auto-play feature. For optimal performance please ensure a 2-5Mbps file of 2GB or less.',
  },
  {
    label: 'Images',
    type: MediaType.IMAGE,
    media: 'image/*',
    description: 'Add any images you wish to include with your project submission.',
  },
  {
    label: 'PDFs',
    type: MediaType.FILE,
    media: 'application/pdf',
    description: 'Add any pdfs you wish to include with your project submission.',
  },
];
