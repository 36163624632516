import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  Renderer2,
  OnChanges,
} from '@angular/core';
import { SharedService } from '../../../services';

@Component({
  selector: 'app-dcp-select',
  templateUrl: './dcp-select.component.html',
  styleUrls: ['./dcp-select.component.scss'],
})
export class DcpSelectComponent implements OnInit, OnChanges {
  @Input() id: string;
  @Input() options: any; // any is ok here
  @Input() placeholder: string;
  @Input() selectedDefault = '';
  @Input() filterOption = false;
  @Input() autoReset = false;
  @Input() disabled = false;
  @Input() optionNameField = 'name';
  @Output() selected = new EventEmitter<any>();

  @ViewChild('dhCompId', { static: false }) dhCompId: ElementRef;
  @ViewChild('ddCompId', { static: false }) ddCompId: ElementRef;
  @ViewChild('bdCompId', { static: false }) bdCompId: ElementRef;
  @ViewChild('filterTextInputCompId', { static: false }) filterTextInputCompId: ElementRef;

  compId: string;
  selectedOption = null;
  isSelectOpen = false;
  filterText = '';

  constructor(private sharedService: SharedService, private renderer: Renderer2) {
    this.sharedService.getResetFilter().subscribe(() => {
      this.selectedOption = null;
      for (const iterator of this.options) {
        iterator.selected = false;
      }
    });
  }

  ngOnChanges(): void {
    if (this.selectedDefault?.toString() !== '') {
      this.options.forEach((option: { selected: boolean }) => (option.selected = false));
      for (const iterator of this.options) {
        if (iterator.slug == this.selectedDefault) {
          // do not fix this lint error == to === cose here compare values without compare types - its completely legit.
          // fixing brakes prefill in select
          this.selectedOption = iterator;
          iterator.selected = true;
          break;
        }
      }
    }
  }

  ngOnInit(): void {
    const dateInit = new Date();
    setTimeout(() => {
      this.compId = dateInit.getTime() + '-' + Math.random();
    }, 100);
  }

  openClose(): void {
    this.isSelectOpen = !this.isSelectOpen;
    const ddWrapper: HTMLElement = this.renderer.selectRootElement('#dcp-dd-wrapper', true);
    if (this.isSelectOpen) {
      const dh = this.dhCompId?.nativeElement;
      const dd = this.ddCompId?.nativeElement;

      setTimeout(() => {
        const bd = this.bdCompId?.nativeElement;
        ddWrapper.append(bd);
      }, 1000);

      const dhTop = dh.getBoundingClientRect().top;
      const dhLeft = dh.getBoundingClientRect().left;
      if (document.getElementsByTagName('body')[0].offsetHeight < dhTop + 330) {
        dd.style['bottom'] = '40px';
        dd.style['top'] = 'inherit';
        dd.style['left'] = dhLeft + 'px';
      } else {
        dd.style['bottom'] = 'inherit';
        dd.style['top'] = dhTop + 40 + 'px';
        dd.style['left'] = dhLeft + 'px';
      }
      ddWrapper.append(dd);
    } else {
      this.selected.emit(this.selectedOption);
      const dh = this.dhCompId?.nativeElement;
      const dd = this.ddCompId?.nativeElement;

      dh.append(dd);
      if (this.autoReset) {
        this.selectedOption = null;
        this.options.forEach((res) => (res.selected = false));
      }
    }
  }

  openCloseKey(): void {
    this.isSelectOpen = !this.isSelectOpen;
    if (this.isSelectOpen) {
      const dh = this.dhCompId?.nativeElement;
      const dd = this.ddCompId?.nativeElement;
      const dhTop = dh.getBoundingClientRect().top;

      if (document.getElementsByTagName('body')[0].offsetHeight < dhTop + 330) {
        dd.style['bottom'] = '40px';
        dd.style['top'] = 'inherit';
      } else {
        dd.style['bottom'] = 'inherit';
        dd.style['top'] = '40px';
      }
    } else {
      this.selected.emit(this.selectedOption);
    }
  }

  select(option): void {
    if (option.selected) {
      return;
    }
    for (const iterator of this.options) {
      iterator.selected = false;
    }
    if (option.selected === undefined) {
      option.selected = false;
    }
    option.selected = !option.selected;

    for (const iterator of this.options) {
      if (iterator.selected) {
        this.selectedOption = iterator;
      }
    }
    this.openClose();
  }

  filterOptions(evt): void {
    evt.stopImmediatePropagation();
    const text = evt.target.value;
    this.filterText = text.toLowerCase();

    if (this.filterText === '') {
      for (const iterator of this.options) {
        iterator.visible = true;
      }
    } else {
      for (const iterator of this.options) {
        const caseUnsensitive = iterator.name.toLowerCase();
        if (caseUnsensitive.indexOf(this.filterText) !== -1) {
          iterator.visible = true;
        } else {
          iterator.visible = false;
        }
      }
    }
  }

  clearText(): void {
    this.renderer.setValue(this.filterTextInputCompId?.nativeElement, '');
    this.filterText = '';
    if (this.filterText === '') {
      for (const iterator of this.options) {
        iterator.visible = true;
      }
    }
  }

  @HostListener('keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    const focusId = document.activeElement.id;
    if (focusId === this.id) {
      if (event.key === 'Enter') {
        this.openCloseKey();

        setTimeout(() => {
          const dd = this.ddCompId?.nativeElement;
          const firstListItem = dd.getElementsByClassName('option')[0];
          const FLIid = firstListItem.id;
          const fliDOMEL: HTMLElement = this.renderer.selectRootElement(`#${FLIid}`, true);
          fliDOMEL.focus();
        }, 1000);
      }
    }
  }

  @HostListener('keydown', ['$event'])
  handleKeyboardDownEvent(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      setTimeout(() => {
        const focusId = document.activeElement.id;
        if (this.renderer.selectRootElement(`#${this.id}`, true) !== null) {
          const ddEle = this.renderer.selectRootElement(`#${this.id}`, true);
          const dd = ddEle.getElementsByClassName('select-dropdown')[0];
          if (focusId !== this.id && dd.classList.contains('open') === true) {
            this.openCloseKey();
          }
        }
      }, 10);
    }
  }
}
