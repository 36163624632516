import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Inject,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Input,
  DestroyRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProposalService, ToasterService, ProgressRef } from '../../services';
import { environment } from '../../../../environments';
import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of, from, throwError, combineLatest } from 'rxjs';
import { tap, catchError, last, map, switchMap } from 'rxjs/operators';
import { FileProgressObject, MediaType, PreSignedUrlResponse, SaveProposalMediaList } from '../../../app.datatypes';
import { S3UploadService } from '../../services/s3-upload.service';
import MediaInfoFactory, { MediaInfo, ReadChunkFunc, ResultMap } from 'mediainfo.js';
import { DOCUMENT } from '@angular/common';
import { JoyrideService } from 'ngx-joyride';
import { Platform } from '@angular/cdk/platform';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { VideoPlayerComponent } from '../../modules/components/video-player/video-player.component';
import { VideoConfig } from '../../interface/video-config.interface';

const MIN_VIDEO_HEIGHT = environment.min_video_upload_height;

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
})
export class UploadDialogComponent implements OnInit {
  @Input() type: MediaType; // video, image, file
  @Input() from: string; // proposal, creativeQuery
  @Input() mode: string;
  @Input() isVideoDistribution = false;
  @Input() proposalIndex: number;
  @Input() imageUploaded: string;
  @Input() fileUploaded: string;
  @Input() videoUploaded: string;
  @Input() isDirectVideoUpload: boolean;
  @Input() modalTitle: string;
  @Input() modalValue: string;
  @Input() modalDescription: string;
  @Input() videoBlob: Blob;
  @Input() mediaFile: File;
  @Output() cancelModal = new EventEmitter();
  @Output() saveOption = new EventEmitter();
  @Output() saveMedia = new EventEmitter();
  mediaType = MediaType;
  @ViewChild(VideoPlayerComponent) uploadedVideo: VideoPlayerComponent;
  @ViewChild('videoInputSeconds') videoSecondsElement: ElementRef;

  videoForm: UntypedFormGroup;
  mediaForm: UntypedFormGroup;
  loading = false;
  directVideoFile: FileList;
  directVideoUploaded: string;
  imageFiles = [];
  docFiles: FileProgressObject[] = [];
  @Input() file_id: string;
  uploadedFileName: string;
  progress: ProgressRef;
  uploadProgress = '0%';
  isUploadProgress = false;
  isUploading = false;
  isUploaded = false;
  thumbnailUrl: string;
  uploadVideoBlob: Blob;
  videoSeconds: number;
  videoFrames: number;
  isSecondsError = false;
  secondsErrorMsg: string;
  isSecondsChanged = false;
  coverType = '';
  isVideoTypeMkv = false;
  isCoverEror = false;
  coverErrorMsg: string;
  coverImageName = '';
  isSaveClicked = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: UntypedFormBuilder,
    private toastService: ToasterService,
    private proposalService: ProposalService,
    private s3UploadService: S3UploadService,
    private cdRef: ChangeDetectorRef,
    public joyRideService: JoyrideService,
    public platform: Platform,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    if (this.mode === 'edit') {
      this.isUploaded = true;
      if (this.type === MediaType.VIDEO) {
        this.directVideoUploaded = this.modalValue;
        this.uploadVideoBlob = this.videoBlob;
      } else if (this.type === MediaType.FILE || this.type === MediaType.DOC) {
        this.uploadedFileName = this.modalValue;
      }
    } else {
      this.fileUploaded = null;
    }
    this.buildForm();
    if (this.mediaFile && this.mode !== 'edit') {
      if (this.type === MediaType.IMAGE) {
        this.imageUpload();
      } else if (this.type === MediaType.VIDEO) {
        this.onVideoInputChange();
      } else {
        this.docUpload();
      }
    }
  }

  generateVideoConfig(): VideoConfig {
    return {
      autoplay: this.platform.IOS,
      classes: 'absolute top-0 left-0 w-full h-full',
      src: this.directVideoUploaded,
    };
  }

  buildForm(): void {
    this.buildVideoForm();
    this.buildMediaForm();
  }

  buildVideoForm() {
    this.videoForm = this.formBuilder.group({
      value: ['', [Validators.required]],
      title: ['', [Validators.required]],
      cover: ['', [Validators.required]],
      description: [],
    });

    this.videoForm.get('cover').disable();

    if (this.type) {
      this.videoForm.patchValue({
        value: this.modalValue,
        title: this.modalTitle,
        description: this.modalDescription,
      });
      this.directVideoUploaded = this.modalValue;
    }
    if (this.from === 'round') {
      this.videoForm.get('title').setValidators(null);
      this.videoForm.get('title').updateValueAndValidity();
    }
  }

  buildMediaForm() {
    this.mediaForm = this.formBuilder.group({
      value: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: [],
    });

    if (this.type) {
      this.mediaForm.patchValue({
        value: this.modalValue,
        title: this.modalTitle,
        description: this.modalDescription,
      });
    }
  }

  attachImage() {
    this.imageUploaded = this.mediaForm.value.value;
    this.isUploading = true;
    this.proposalService
      .uploadImageByUrl(this.imageUploaded)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        this.mediaForm.controls['value'].setValue(event.file_url);
        this.imageUploaded = event.file_url;
        this.isUploading = false;
      });
  }

  attachFile() {
    this.fileUploaded = this.mediaForm.value.value;
  }

  onVideoInputChange(ev?): void {
    let file = ev?.srcElement?.files[0];
    let preSignedUrl: string[];
    if (!file && this.mediaFile) {
      file = this.mediaFile;
    }
    this.directVideoUploaded = null;
    this.isUploaded = false;
    this.isDirectVideoUpload = false;
    if (file.name.includes('.mkv')) {
      this.isVideoTypeMkv = true;
    } else {
      this.isVideoTypeMkv = false;
    }
    const fileSize = file?.size;
    if (fileSize > (this.isVideoDistribution ? environment.vd_max_file_size : environment.max_video_size)) {
      this.toastService.openErrorToastr(
        `File too Big. The file size can not exceed ${this.isVideoDistribution ? '30GB' : '2GB'}`,
        'Video upload error'
      );
      return;
    }
    this.isUploading = true;
    this.isUploadProgress = true;
    this.videoForm.controls['value'].disable();
    this.uploadProgress = '1%';
    this.directVideoFile = file;
    const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length);
    const fileName =
      Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '.' + fileExt;

    const validateVideoData = (result) => {
      const videoTrack =
        result.media.track && result.media.track.length ? result.media.track.find((t) => t['@type'] === 'Video') : null;
      if (!videoTrack) {
        throw new Error('Could not find any video track');
      }
      if (Number(videoTrack['Height']) < MIN_VIDEO_HEIGHT) {
        throw new Error(
          `The video height ${videoTrack['Height']} is less than the minimum required height of ${MIN_VIDEO_HEIGHT}`
        );
      }
    };

    let bucketType = this.isVideoDistribution ? 'vd' : '';

    MediaInfoFactory({ format: 'object' }, (mediainfo: MediaInfo) => {
      from(this.getVideoMediaInfo(mediainfo, file))
        .pipe(
          tap((res) => {
            validateVideoData(res);
          }),
          switchMap(() => {
            return combineLatest([
              this.s3UploadService.getPreSignedURL(fileName, file.type, bucketType),
              from(file.arrayBuffer()),
            ]);
          }),
          switchMap(([preSignedUrlResponse, arrayBuffer]: [PreSignedUrlResponse, ArrayBuffer]) => {
            const file_rel = preSignedUrlResponse.fileRel;
            preSignedUrl = preSignedUrlResponse.uploadURL.split('?');

            this.uploadVideoBlob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });

            return combineLatest([
              this.s3UploadService.uploadToS3(preSignedUrlResponse.uploadURL, this.uploadVideoBlob),
              this.proposalService.createFileRecordOnUpload({ file_rel }),
            ]);
          }),
          catchError((err) => {
            this.isUploading = false;
            this.isUploadProgress = false;
            this.toastService.openErrorToastr(
              `There was a problem uploading the video: ${err.message}`,
              'Video upload error'
            );
            return throwError(() => new Error(err));
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(([response, fileDocument]) => {
          if (response === null) {
            this.directVideoUploaded = preSignedUrl[0];
            this.isUploadProgress = false;
            this.uploadProgress = '0%';
            this.isUploaded = true;
            this.isUploading = false;
            this.videoForm.controls['value'].setValue(this.directVideoUploaded);
            this.videoForm.controls['value'].setErrors(null);
            this.videoForm.get('cover').enable();
            this.isDirectVideoUpload = true;
            this.file_id = fileDocument.id;
            this.videoSeconds = 0;
            this.videoFrames = 0;
            this.cdRef.detectChanges();
          } else {
            if (response < 1) {
              this.uploadProgress = '1%';
            } else {
              this.uploadProgress = response + '%';
            }
          }
        });
    });

    this.type = MediaType.VIDEO;
  }

  imageUpload(ev?) {
    let file = ev?.srcElement?.files[0];
    if (!file && this.mediaFile) {
      file = this.mediaFile;
    }
    this.isUploading = true;
    const formData: FormData = new FormData();
    formData.append('cover', file, file.name);

    if (!file.type.split('/').includes(MediaType.IMAGE)) {
      this.toastService.openToastr('File is not image type', 'Image', 'error', 3000);
      this.isUploading = false;
      return;
    }
    // Validate for Filesize in mbs
    if (Math.round(file.size / 1024 / 1024) > environment.max_image_size) {
      this.toastService.openToastr('Upload File Size is greater than 2.5 Mb ', 'FILE SIZE ERROR', 'error', 3000);
      this.isUploading = false;
      return;
    }

    this.imageFiles.push({
      data: file,
      state: 'in',
      inProgress: false,
      progress: 0,
      canRetry: false,
      canCancel: true,
    });

    this.uploadImages();
    this.type = MediaType.IMAGE;
  }

  uploadImages() {
    this.imageFiles.forEach((file, index) => {
      if (!file.inProgress) {
        this.uploadImage(file);
      }
    });
  }

  private uploadImage(file) {
    const fd = new FormData();
    fd.append('image', file.data);
    const req = this.proposalService
      .uploadImage(fd)
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round((event.loaded * 100) / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        last(),
        catchError(() => {
          file.inProgress = false;
          file.canRetry = true;
          return of(`${file.data.name} upload failed.`);
        })
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (event) => {
          if (typeof event === 'object') {
            this.mediaForm.controls['value'].setValue(event.body.data.file_url);
            const index = this.imageFiles.indexOf(file);
            if (index > -1) {
              this.imageFiles.splice(index, 1);
            }
            this.imageUploaded = event.body.data.file_url;
          }
        },
        () => {
          this.isUploading = false;
        }
      );
  }

  docUpload(ev?) {
    let file = ev?.srcElement?.files[0];
    if (!file && this.mediaFile) {
      file = this.mediaFile;
    }
    this.isUploading = true;
    // VALIDATE For PDF
    if (file.type !== 'application/pdf') {
      alert('Document File must be in PDF format');
      this.isUploading = false;
      return;
    }
    // Validate for Filesize
    if (file.size / 1048576 >= environment.max_pdf_doc_size) {
      alert(file.name + ' size is greater than ' + environment.max_pdf_doc_size + ' Mb ');
      this.isUploading = false;
      return;
    }

    this.docFiles.push({
      file: file,
      state: 'in',
      inProgress: false,
      progress: 0,
      canRetry: false,
      canCancel: true,
      type: 'pdf',
    } as FileProgressObject);
    this.uploadFiles();
    this.uploadedFileName = file?.name;
  }

  uploadFiles() {
    this.docFiles.forEach((file) => {
      if (!file.inProgress) {
        this.uploadFile(file);
      }
    });
  }

  private uploadFile(file) {
    // this.progress = this.progressService.showSpinner(this.progress, this.elRef);
    const fd = new FormData();
    fd.append('pdf', file?.file);
    const req = this.proposalService.uploadPdfDoc(fd);
    req
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round(((event.total ? event.total : 1) * 100) / (event.total ? event.total : 1));
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        tap((message) => {}),
        last(),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          file.canRetry = true;
          return of(`${file?.file.name ? file?.file.name : ''} upload failed.`);
        })
      )
      .subscribe(
        (event) => {
          if (typeof event === 'object') {
            this.mediaForm.controls['value'].setValue(event.body.data.image_path.file_url);
            const index = this.docFiles.indexOf(file);
            if (index > -1) {
              this.docFiles.splice(index, 1);
            }
            this.fileUploaded = event.body.data.image_path.file_preview_url;
          }
        },
        (error) => {}
      );
  }

  formSave() {
    this.isSaveClicked = true;
    if (this.type === MediaType.VIDEO) {
      if (this.mode === 'edit') {
        if (this.videoSeconds === undefined) {
          this.save(this.videoForm);
        } else {
          this.setupThumbnail();
        }
      } else {
        this.setupThumbnail();
      }
    } else if (this.type === MediaType.IMAGE || this.type === MediaType.FILE || this.type === MediaType.DOC) {
      this.save(this.mediaForm);
    }
  }

  async save(form: UntypedFormGroup) {
    form.markAllAsTouched();
    if (form.valid) {
      if (this.from === 'creativeQuery') {
        this.saveCreativeQuery(form);
      }
      if (this.from === 'proposal' || this.from === 'round') {
        this.saveProposal(form);
      }
    }
  }

  saveCreativeQuery(form) {
    const data = {
      isSaved: true,
      isEdit: false,
      type: this.type,
      value: form.value.value,
      title: form.value.title,
      description: form.value.description,
      videoData: this.type === MediaType.VIDEO ? this.directVideoUploaded : '',
      isDirectVideoUpload: this.isDirectVideoUpload,
      videoBlob: this.uploadVideoBlob,
      thumbnailUrl: this.thumbnailUrl,
      file_id: this.file_id,
    };
    if (data.type === MediaType.VIDEO && this.isDirectVideoUpload) {
      data.value = typeof this.directVideoUploaded === 'string' ? this.directVideoUploaded : '';
    }
    if (data.type === MediaType.DOC) {
      data.thumbnailUrl = this.fileUploaded;
    }
    this.saveOption.emit(data);
  }

  saveProposal(form) {
    let data: SaveProposalMediaList;
    if (this.mode === 'edit') {
      data = {
        type: this.type,
        index: this.proposalIndex,
        value: form.value.value,
        title: form.value.title,
        description: form.value.description,
        fileData: this.type === MediaType.FILE ? this.fileUploaded : '',
      };
    } else {
      data = {
        type: this.type,
        value: form.value.value,
        title: form.value.title,
        description: form.value.description,
        fileData: this.type === MediaType.FILE ? this.fileUploaded : '',
      };
    }
    if (data.type === MediaType.VIDEO) {
      data.isDirectVideoUpload = this.isDirectVideoUpload;
      data.value = typeof this.directVideoUploaded === 'string' ? this.directVideoUploaded : '';
      data.videoData = this.type === MediaType.VIDEO ? this.directVideoUploaded : '';
      data.file_id = this.file_id;
      data.thumbnailUrl = this.thumbnailUrl;
      data.videoBlob = this.uploadVideoBlob;
      this.saveMedia.emit(data);
    } else {
      this.saveMedia.emit(data);
    }
  }

  getVideoMediaInfo(mediainfo, videoFile: File) {
    return new Promise<ResultMap>((resolve) => {
      if (!videoFile) {
        return Promise.reject("Can't get media information, no file");
      }
      const getSize = () => videoFile.size;
      const readChunk: ReadChunkFunc = (chunkSize, offset) =>
        new Promise((innerResolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event: ProgressEvent<FileReader>) => {
            if (event.target.error) {
              reject(event.target.error);
            }
            innerResolve(new Uint8Array(event.target.result as ArrayBuffer));
          };
          reader.readAsArrayBuffer(videoFile.slice(offset, offset + chunkSize));
        });
      const p = <Promise<ResultMap>>mediainfo.analyzeData(getSize, readChunk);
      p.then((result: ResultMap) => resolve(result));
    });
  }

  cancelUploadModal() {
    this.cancelModal.emit();
  }

  imageLoaded() {
    this.isUploaded = true;
    this.isUploading = false;
  }

  videoLoaded(video): void {
    if (video?.target?.readyState === 4) {
      this.isUploadProgress = false;
      this.uploadProgress = '0%';
      this.isUploaded = true;
      this.isUploading = false;
    }
  }

  async setupThumbnail(): Promise<void> {
    if (this.coverType === 'frame') {
      const video = this.uploadedVideo.videoPlayer.videoElement.nativeElement;
      const videoDuration = video?.duration;
      if (this.videoSeconds <= videoDuration && this.videoFrames <= videoDuration * 24) {
        this.thumbnailUrl = await this.createThumbnail(this.uploadVideoBlob, this.videoSeconds);
        this.save(this.videoForm);
      } else {
        this.isSecondsError = true;
        this.secondsErrorMsg = 'Seconds and Frames must be less then video seconds and frames';
      }
    }
    if (this.coverType === MediaType.IMAGE) {
      if (this.thumbnailUrl) {
        this.save(this.videoForm);
      } else {
        this.isCoverEror = true;
        this.coverErrorMsg = 'Cover Image is required.';
      }
    }
  }

  async createThumbnail(videoFile: Blob, seconds: number): Promise<string> {
    const video: HTMLVideoElement = this.document.createElement('video');
    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');

    return new Promise<string>((resolve, reject) => {
      canvas.addEventListener('error', reject);
      video.preload = 'auto';
      video.src = window.URL.createObjectURL(videoFile);
      video.load();
      video.addEventListener('error', reject);
      video.addEventListener('loadeddata', () => {
        if (this.isSecondsChanged) {
          video.currentTime = seconds;
        } else {
          video.currentTime = 2;
        }
        video.addEventListener('seeked', () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL());
        });
      });
      if (videoFile.type) {
        video.setAttribute('type', videoFile.type);
      }
    });
  }

  async getCurrentFrame(): Promise<void> {
    this.isSecondsChanged = true;
    const video = this.uploadedVideo.videoPlayer.videoElement.nativeElement;
    const currentTime = video.currentTime;
    this.videoSeconds = Number(Math.round(currentTime * 10) / 10);
    const currentFrames = this.videoSeconds * 24;
    this.videoFrames = Number(Math.round(currentFrames * 1) / 1);
  }

  onKeyupSeconds(seconds: number): boolean {
    if (seconds) {
      this.isSecondsChanged = true;
      const video = this.uploadedVideo.videoPlayer.videoElement.nativeElement;
      const videoDuration = video?.duration;
      if (seconds <= videoDuration) {
        this.videoSeconds = Number(seconds);
        const currentFrames = this.videoSeconds * 24;
        this.videoFrames = Number(Math.round(currentFrames * 1) / 1);
        video.currentTime = seconds;
        this.isSecondsError = false;
        this.secondsErrorMsg = null;
        return true;
      } else {
        this.isSecondsError = true;
        this.secondsErrorMsg = 'Seconds must be less than video duration';
      }
    }
    return false;
  }

  onKeyupFrames(frames: number): boolean {
    if (frames) {
      this.isSecondsChanged = true;
      const video = this.uploadedVideo.videoPlayer.videoElement.nativeElement;
      const videoDuration = video?.duration;
      if (frames <= videoDuration * 24) {
        this.videoFrames = frames;
        const seconds = frames / 24;
        this.videoSeconds = Number(Math.round(seconds * 10) / 10);
        video.currentTime = this.videoSeconds;
        this.isSecondsError = false;
        this.secondsErrorMsg = null;
        return true;
      } else {
        this.isSecondsError = true;
        this.secondsErrorMsg = 'Frames must be less than video frames';
      }
    }
    return false;
  }

  addVideoSeconds(value: number): void {
    if (this.isUploaded) {
      this.isSecondsChanged = true;
      const video = this.uploadedVideo.videoPlayer.videoElement.nativeElement;
      const videoDuration = video?.duration;
      if (this.videoSeconds + value >= 0) {
        this.videoSeconds = this.videoSeconds + value;
        if (this.videoSeconds <= videoDuration) {
          const currentFrames = this.videoSeconds * 24;
          this.videoFrames = Number(Math.round(currentFrames * 1) / 1);
          video.currentTime = this.videoSeconds;
          this.isSecondsError = false;
          this.secondsErrorMsg = null;
        } else {
          this.isSecondsError = true;
          this.secondsErrorMsg = 'Seconds must be less than video duration';
        }
      }
    }
  }

  addVideoFrames(value: number): void {
    if (this.isUploaded) {
      this.isSecondsChanged = true;
      const video = this.uploadedVideo.videoPlayer.videoElement.nativeElement;
      const videoDuration = video?.duration;
      if (this.videoFrames + value >= 0) {
        this.videoFrames = this.videoFrames + value;
        if (this.videoFrames <= videoDuration * 24) {
          const seconds = this.videoFrames / 24;
          this.videoSeconds = Math.round(seconds * 10) / 10;
          video.currentTime = this.videoSeconds;
          this.isSecondsError = false;
          this.secondsErrorMsg = null;
        } else {
          this.isSecondsError = true;
          this.secondsErrorMsg = 'Frames must be less than video frames';
        }
      }
    }
  }

  onFrameKeypress(event): boolean {
    if (event.charCode >= 48 && event.charCode <= 57) {
      return true;
    }
    return false;
  }

  onKeypressSeconds(event: KeyboardEvent) {
    if (event.charCode >= 48 && event.charCode <= 57) {
      const regex: RegExp = new RegExp(/^(?:0|[1-9]\d+|)?(?:.?\d{0,2})?$/);
      const specialKeys: Array<string> = [
        'Backspace',
        'Tab',
        'End',
        'Home',
        '-',
        'ArrowLeft',
        'ArrowRight',
        'Del',
        'Delete',
      ];

      if (specialKeys.indexOf(event.key) !== -1) {
        return;
      }
      const current: string = this.videoSecondsElement.nativeElement.value;
      const position = this.videoSecondsElement.nativeElement.selectionStart;
      const next: string = [
        current.slice(0, position),
        event.key === 'Decimal' ? '.' : event.key,
        current.slice(position),
      ].join('');
      if (next && !String(next).match(regex)) {
        event.preventDefault();
      }
    } else {
      return false;
    }
  }

  onChangeCoverType(type: string): void {
    this.coverType = type;
    this.thumbnailUrl = null;
    this.coverImageName = '';
  }

  onCoverInputChange(event) {
    if (!event?.target?.files[0]) {
      return;
    }
    const file = event.target.files[0];
    this.coverImageName = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.thumbnailUrl = reader.result.toString();
    };
    this.isCoverEror = false;
    this.coverErrorMsg = '';
  }
}
