import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlErrorMessageComponent } from './control-error-message.component';
import { DashReplaceModule } from '../../../pipes/dash-replace/dash-replace.module';

@NgModule({
  declarations: [
    ControlErrorMessageComponent
  ],
  exports: [
    ControlErrorMessageComponent
  ],
  imports: [
    CommonModule,
    DashReplaceModule
  ]
})
export class ControlErrorMessageModule { }
