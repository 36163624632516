import { Component, DestroyRef, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DistributionService } from '../../../../shared/services/distribution.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Distribution } from '../../../../../app/app.datatypes';
import { LocalLoaderModule } from '../../../../shared/modules/components/local-loader/local-loader.module';
import { IconModule } from '../../../../shared/modules/components/icon/icon.module';
import { environment } from 'environments';
declare let shaka: any;

@Component({
  selector: 'app-distribution-player',
  standalone: true,
  imports: [CommonModule, LocalLoaderModule, IconModule],
  templateUrl: './distribution-player.component.html',
  host: {
    class: 'w-full block',
  },
})
export class DistributionPlayerComponent implements OnInit {
  @ViewChild('videoPlayer') videoElement: ElementRef | undefined;
  @ViewChild('videoContainer') videoContainerRef: ElementRef | undefined;

  @Input() distribution: Distribution;
  @Input() expanded: boolean;
  streamLoading: boolean;

  constructor(private readonly distributionService: DistributionService, private readonly destroyRef: DestroyRef) {}

  ngOnInit(): void {
    shaka.polyfill.installAll();
    this.startStreaming();
  }

  initPlayer(url: string): void {
    const player = new shaka.Player();
    player.addEventListener('error', (event) => this.onError(event));
    new shaka.ui.Overlay(player, this.videoContainerRef.nativeElement, this.videoElement.nativeElement);
    if (!environment.local) {
      player.getNetworkingEngine().registerRequestFilter(function (_, request) {
        request.allowCrossSiteCredentials = true;
      });
    }
    player.attach(this.videoElement.nativeElement).then(() => {
      player
        .load(
          environment.local
            ? 'https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8'
            : url
        )
        .catch((error) => this.onError(error));
    });
  }

  onError(error: Error): void {
    console.error('Shaka Player Error:', error);
  }

  startStreaming(): void {
    this.streamLoading = true;
    this.distributionService
      .getVideoStream(this.distribution.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((url: string) => {
        if (shaka.Player.isBrowserSupported()) {
          this.initPlayer(url);
        }
        this.streamLoading = false;
      });
  }
}
