import { NgModule } from '@angular/core';

import { DistributionCreateRoutingModule } from './distribution-create-routing.module';
import { DistributionCreateComponent } from './distribution-create/distribution-create.component';
import { SharedModule } from '../../../shared';
import { TabsModule } from '../../../shared/modules/components/tabs/tabs.module';
import { DistributionCreateStepOneComponent } from './distribution-create-step-one/distribution-create-step-one.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { UploadAvatarModule } from '../../../shared/modules/components/image-cropper/upload-avatar.module';
import { MultiselectModule } from '../../../shared/modules/components/dcp-multiselect/multiselect.module';
import { DistributionCreateStepTwoComponent } from './distribution-create-step-two/distribution-create-step-two.component';
import { VideoPlayerModule } from '../../../shared/modules/components/video-player/video-player.module';
import { SafeHtmlModule } from '../../../shared/pipes/safe-html/safe-html.module';
import { GalleryModule } from '../../../shared/components/gallery/gallery.module';
import { DistributionCreateStepThreeComponent } from './distribution-create-step-three/distribution-create-step-three.component';
import { DcpCheckoutModule } from '../../../shared/modules/components/checkout/dcp-checkout.module';
import { LogoModule } from '../../../shared/modules/components/logo/logo.module';
import { GalleryService } from '../../../shared/services/gallery.service';
import { AttachmentModule } from '../../../shared/components/attachments/attachment.module';
import { DcpSelectModule } from '../../../shared/modules/components/dcp-select/dcp-select.module';
import { DistributionRoyaltyMapComponent } from './distribution-royalty-map/distribution-royalty-map.component';
import { OnlyNumberDirective } from '../../../shared/modules/directives/only-number/only-number.directive';
import { DistributionPlayerComponent } from '../watch-distribution/distribution-player/distribution-player.component';
import { DatePickerModule } from 'app/shared/modules/components/dcp-date-picker/date-picker.module';

@NgModule({
  declarations: [
    DistributionCreateComponent,
    DistributionCreateStepOneComponent,
    DistributionCreateStepTwoComponent,
    DistributionCreateStepThreeComponent,
  ],
  exports: [DistributionCreateComponent],
  imports: [
    SharedModule,
    DistributionCreateRoutingModule,
    TabsModule,
    CKEditorModule,
    UploadAvatarModule,
    MultiselectModule,
    DcpSelectModule,
    VideoPlayerModule,
    SafeHtmlModule,
    GalleryModule,
    DcpCheckoutModule,
    LogoModule,
    AttachmentModule,
    DistributionRoyaltyMapComponent,
    OnlyNumberDirective,
    DistributionPlayerComponent,
    DatePickerModule,
  ],
  providers: [GalleryService],
})
export class DistributionCreateModule {}
