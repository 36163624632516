<div class="rounded w-full h-auto overflow-hidden relative">
  <div
    [ngClass]="{ 'fixed top-0 left-0 w-full h-full bg-backdrop-dark z-50': expanded }"
    data-shaka-player-container
    #videoContainer
  >
    <button
      class="btn-icon btn-white-bg mb-16 right-12 top-12 absolute z-[60]"
      *ngIf="expanded"
      (click)="expanded = false"
    >
      <app-icon icon="close"></app-icon>
    </button>
    <video
      #videoPlayer
      data-shaka-player
      [poster]="distribution.cover_url"
      [autoplay]="true"
      [muted]="true"
      id="videoPlayer"
      class="w-full h-full object-cover aspect-16/9"
    ></video>
    <ng-container *ngIf="streamLoading">
      <div
        class="flex flex-col justify-center items-center h-full w-full absolute top-0 z-50 infinite-loading-msg cursor-pointer hover:bg-backdrop-dark transition-all duration-300 bg-backdrop-dark"
        (click)="startStreaming()"
      >
        <app-local-loader class="md:w-[3vw] md:h-[3vw]" color="white" [hasTimeout]="false"></app-local-loader>
        <p class="text-white mt-16 font-semibold text-lg">Please wait, your video is loading…</p>
      </div>
    </ng-container>
  </div>
</div>
