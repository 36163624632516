import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import Echo from 'laravel-echo';
import { environment } from '../../../environments';
import { ProposalStateService } from '../state/proposal/proposal-state.service';
import { ReviewStateService } from '../state/review/review-state.service';
import { CreativeQueryStateService } from '../state/creative-query/creative-query-state.service';
import { AuthenticatedUserService } from '../state/authenticated-user/authenticated-user.service';
import { PopupMessageService } from './popup-message.service';
import { PaymentStateService } from '../state';
import { take } from 'rxjs/operators';
import { TransactionStateService } from '../state/transactions/transaction-state.service';
import { PricingFees } from '../../app.datatypes';
import { DistributionService } from './distribution.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private host = environment.echo_server_url;
  private echoServerAuthKey = environment.echo_server_key;
  private echo: Echo;
  private authToken;

  constructor(
    private readonly proposalService: ProposalStateService,
    private readonly creativeQueryService: CreativeQueryStateService,
    private readonly reviewService: ReviewStateService,
    private readonly authenticatedUserService: AuthenticatedUserService,
    private readonly popupMessageService: PopupMessageService,
    private readonly transactionService: TransactionStateService,
    private readonly paymentStateService: PaymentStateService,
    private readonly distributionService: DistributionService
  ) {}

  connect(authToken): Promise<SocketService> {
    // If already connected, disconnect and create another connection with latest auth token
    if (this.echo) {
      this.disconnect();
    }
    return new Promise((resolve, reject) => {
      try {
        if (this.echo) {
          resolve(this);
        }
        this.authToken = authToken;
        this.echo = new Echo({
          auth: { headers: { Authorization: 'Bearer ' + this.authToken } },
          client: io,
          host: this.host,
          broadcaster: 'socket.io',
          key: this.echoServerAuthKey,
        });
        resolve(this);
      } catch (e) {
        reject(e);
      }
    });
  }

  disconnect() {
    if (this.echo) {
      this.echo.disconnect();
      this.echo = null;
    }
  }

  joinMandatoryChannels() {
    this.echo
      .private('auth-users')
      .listen('.proposal-status-change', (event) => {
        this.proposalService.updateFullProposalState(event.data.id, event.data);
      })
      .listen('.proposal-approved', (event) => {
        this.proposalService.addProposal(event.data);
      })
      .listen('.proposal-score-change', (event) => {
        this.proposalService.updateScores(event.data.id, {
          votes_score: event.data.votes_score,
          reviews_score: event.data.reviews_score,
          evaluations_score: event.data.evaluations_score,
          likes_score: event.data.likes_score,
          score: event.data.score,
        });
      })
      .listen('.proposal-toggle-lock', (event) => {
        this.proposalService.setLockedData(
          event.data.id,
          event.data.locked_by ? event.data.locked_by : null,
          event.data.locked_at ? event.data.locked_at : null
        );
      })
      .listen('.creative-query-status-change', (event) => {
        this.creativeQueryService.update(event.data.id, {
          status: event.data.status,
          blockchain_confirmed: event.data.blockchain_confirmed,
          blockchain_id: event.data.blockchain_id,
        });
      })
      .listen('.pricing-fees-update', (event) => {
        const fees: PricingFees = event.data;
        this.paymentStateService.updateFeesStore(fees);
      })
      .listen('.creative-query-updated', (event) => {
        this.creativeQueryService.update(event.data.id, { status: event.data.status });
      })
      .listen('.creative-query-answered', (event) => {
        this.creativeQueryService.onAnswerCreateUpdate(event.data.id, event.data.total_votes, event.data.total_stake);
      })
      .listen('.creative-query-toggle-lock', (event) => {
        this.creativeQueryService.setLockedData(
          event.data.id,
          event.data.locked_by ? event.data.locked_by : null,
          event.data.locked_at ? event.data.locked_at : null
        );
      })
      .listen('.review-liked', (event) => {
        this.reviewService.upsertReview({
          id: event.data.id,
          total_number_of_likes: event.data.total_number_of_likes,
          average_score: event.data.average_score,
        });
      })
      .listen('.review-status-change', (event) => {
        this.reviewService.upsertReview({ id: event.data.id, status: event.data.status });
      })
      .listen('.review-toggle-lock', (event) => {
        this.reviewService.upsertReview({
          id: event.data.id,
          locked_by: event.data.locked_by,
          locked_at: event.data.locked_at,
        });
      })
      .listen('.video-distribution-status-change', (distribution) => {
        this.distributionService.updateStatusRoyaltyMap(distribution.data.id, distribution.data.status, false);
      })
      .listen('.video-distribution-review-status-change', (event) => {
        this.reviewService.upsertReview({ id: event.data.id, status: event.data.status });
      });

    /** User related events */
    this.authenticatedUserService.userByToken().subscribe(async (user) => {
      const userID = (await user) ? user.id : null;

      this.echo
        .private('user-updates.' + userID)
        .listen('.user-balance-update', (event) => {
          this.authenticatedUserService.setUserBalance(event.data.balance);
          this.authenticatedUserService.setUserExternalBalance(event.data.external_balance);
          this.authenticatedUserService.setUserLockedBalance(event.data.locked);
        })
        .listen('.user-reputation-update', (event) => {
          this.authenticatedUserService.setUserReputation(event.data.reputation);
        })
        .listen('.user-email-verified', (event) => {
          this.authenticatedUserService.setUserTypeID(event.data.user_type_id);
        })
        .listen('.moderator-access-requested', (event) => {
          this.authenticatedUserService.setModeratorAttributes(
            event.data.user_type_id,
            event.data.awaiting_become_moderator_request
          );
          this.popupMessageService.onWsEvent('moderator-access-flow', 'access-requested');
        })
        .listen('.refuse-moderator-access-requested', (event) => {
          this.authenticatedUserService.setModeratorAttributes(
            event.data.user_type_id,
            event.data.awaiting_become_moderator_request
          );
          this.popupMessageService.onWsEvent('moderator-access-flow', 'refuse-requested');
        })
        .listen('.moderator-access-granted', (event) => {
          this.authenticatedUserService.setModeratorAttributes(
            event.data.user_type_id,
            event.data.awaiting_become_moderator_request,
            event.data.can_be_moderator,
            event.data.can_refuse_moderator_access
          );
          this.popupMessageService.onWsEvent('moderator-access-flow', 'access-granted');
        })
        .listen('.moderator-access-refused', (event) => {
          this.authenticatedUserService.setModeratorAttributes(
            event.data.user_type_id,
            event.data.awaiting_become_moderator_request,
            event.data.can_be_moderator,
            event.data.can_refuse_moderator_access
          );
          this.popupMessageService.onWsEvent('moderator-access-flow', 'access-refused');
        })
        .listen('.proposal-status-change-owner', (event) => {
          this.proposalService
            .getProposal(event.data.id)
            .pipe(take(1))
            .subscribe((proposal) => {
              if (event && event?.data?.status && event?.data?.status === proposal?.status) {
                this.popupMessageService.onWsEvent('proposal-status-change', proposal);
              }
            });
        })
        .listen('.creative-query-answered-owner', (event) => {
          this.creativeQueryService.update(event.data.id, {
            total_votes: event.data.total_votes,
            total_stake: event.data.total_stake,
            has_answer_waiting_for_submission: event.data.has_answer_waiting_for_submission,
          });
        })
        .listen('.creative-query-status-change-owner', (event) => {
          this.creativeQueryService
            .getCreativeQuery(event.data.id)
            .pipe(take(1))
            .subscribe((creativeQuery) => {
              this.creativeQueryService.update(event.data.id, {
                status: event.data.status,
                blockchain_confirmed: event.data.blockchain_confirmed,
                blockchain_id: event.data.blockchain_id,
              });
              this.popupMessageService.onWsEvent('creative-query-status-change', creativeQuery);
            });
        })
        .listen('.review-status-change-owner', (event) => {
          this.reviewService.upsertReview({ id: event.data.id, status: event.data.status });
          this.proposalService.setOnOwnReviewStatusChange(event.data.proposal_id, event.data.status);
        })
        .listen('.transaction-status-change-owner', (event) => {
          this.transactionService.updateTransactionStatus(event.data.id, event.data.status);
        })
        .listen('.payment-status-change-owner', (event) => {
          this.paymentStateService.updatePaymentStatus(event.data.id, event.data.status);
        })
        .listen('.proposal-updated', (event) => {
          this.proposalService.updateFullProposalState(event.data.id, event.data);
        })
        .listen('proposal-extend-general-review', (event) => {
          this.proposalService.updateFullProposalState(event.data.id, event.data);
          this.popupMessageService.onWsEvent('proposal-extend-general-review', event.data);
        })
        .listen('.moderation-process-toggle', (event) => {
          this.authenticatedUserService.setModeratedEntity(event.data.to_be_moderated_entity);
        })
        .listen('.video-distribution-status-change-owner', (distribution) => {
          this.distributionService.updateStatusRoyaltyMap(
            distribution.data.id,
            distribution.data.status,
            distribution.data.is_updating_royalty_map
          );
        })
        .listen('.video-distribution-purchased', (distribution) => {
          this.distributionService.update(distribution.data);
        })
        .listen('.video-distribution-purchased', (distribution) => {
          this.distributionService.update(distribution.data);
        })
        .listen('.video-distribution-reviewed', (distribution) => {
          this.distributionService.update(distribution.data);
        })
        .listen('.video-distribution-review-status-change-owner', (event) => {
          this.reviewService.upsertReview({ id: event.data.id, status: event.data.status });
          this.distributionService.updateReviewStatus(event.data.video_distribution_id, event.data.status);
        });
    });
  }
}
