<div class="relative">
  <div
    class="login-wrapper bg-body md:rounded-tl-lg md:rounded-bl-lg fixed right-0 top-0 h-full w-full md:w-login flex flex-col justify-center items-center px-32 md:px-96 overflow-y-auto z-10 pb-24 ng-tns-c308-2 ng-trigger ng-trigger-panelIn"
    [@panelIn]
  >
    <button class="absolute top-8 right-8 m-8" (click)="closePanel()">
      <app-icon [icon]="'close'" class="w-16 h-16 black opacity-75 hover:opacity-100"></app-icon>
    </button>
    <app-logo class="logo-blue w-180 md:hidden my-32"></app-logo>
    <div>
      <div class="mb-32">
        <div class="flex gap-16 mb-16 justify-center">
          <span class="rounded w-12 h-12 {{ user?.name ? 'bg-blue' : 'bg-body' }}"></span>
          <span class="rounded w-12 h-12 {{ user?.name && smsVerified() ? 'bg-blue' : 'bg-body' }}"></span>
          <span class="rounded w-12 h-12 {{ smsVerified() && emailVerified() ? 'bg-blue' : 'bg-body' }}"> </span>
          <span
            class="rounded w-12 h-12 {{
              smsVerified() && emailVerified() && personalDetailsComplete ? 'bg-blue' : 'bg-body'
            }}"
          ></span>
        </div>
        <h3 class="text-center mb-16">Heads up!</h3>
        <p class="text-sm mb-8 font-normal text-center">
          Please provide the following information to create your DCP account:
        </p>
        <form class="w-full" [formGroup]="form" *ngIf="form">
          <ng-container *ngIf="!user?.name">
            <div class="form-group" [ngClass]="{ error: form.controls.name.hasError('required') }">
              <label>Choose a Username</label>
              <input
                spellcheck="false"
                #name
                maxlength="100"
                formControlName="name"
                placeholder="Username"
                (keydown.space)="$event.preventDefault()"
                tabindex="2"
                autocomplete="“off”"
                autocomplete="new-username"
              />
              <p class="error-msg" *ngIf="form.controls.name.hasError('required') && form.controls.name.touched">
                Username is <strong>Required</strong>
              </p>
              <div
                class="error-block"
                *ngIf="form.get('name').errors && form.get('name').errors.notAvailable"
                [@openDown]
              >
                <p>Username {{ form.controls['name'].value }} is already taken.</p>
                <button class="btn btn-primary btn-sm" (click)="suggestName()" *ngIf="!suggestion">
                  Suggest Username.
                </button>
                <ng-container *ngIf="suggestion">
                  <a (click)="useSuggestedName($event)">{{ suggestion }}</a
                  ><br />
                  <span>Is Available!</span>
                </ng-container>
              </div>
              <p
                class="error-msg"
                i18n
                style="white-space: initial; position: relative"
                *ngIf="form.controls.name.hasError('pattern') && form.controls.name.touched"
              >
                Username should be alphanumeric 1-30 characters long, without spaces. Allowed special characters "._-"
              </p>
            </div>
            <div class="flex gap-8 items-center">
              <app-checkbox text="" formControlName="termsAccepted"></app-checkbox>
              <p>
                I have read and accept the
                <a href="https://decentralized.pictures/community-guidelines" target="_blank" rel="noopener noreferrer"
                  >Community Guidelines</a
                >
                and
                <a href="https://decentralized.pictures/terms-of-service" target="_blank" rel="noopener noreferrer"
                  >Terms of Service
                </a>
              </p>
            </div>
            <button
              class="btn btn-primary btn-block mt-16"
              color="accent"
              [disabled]="form.invalid || !form.controls.termsAccepted.value || isRegisterLoading"
              (click)="submit()"
            >
              Register Now
            </button>
          </ng-container>

          <div class="mt-32">
            <ng-container *ngIf="!smsVerified() && user?.name">
              <ng-container *ngTemplateOutlet="mobile"></ng-container>
              <div
                class="form-group"
                [ngClass]="{ error: form.get('code')?.errors && form.get('code')?.touched }"
                *ngIf="mobileVerificationCodeSent && !smsVerified()"
              >
                <label> Enter 6 Digit Code</label>
                <input
                  type="text"
                  placeholder=" 6 Digit Code"
                  spellcheck="false"
                  formControlName="code"
                  tabindex="1"
                  appOnlyNumber
                  maxlength="6"
                />
                <p class="error-msg" i18n *ngIf="form.get('code')?.errors && form.get('code')?.touched">
                  Please check your code and try again or contact
                  <a
                    href="https://support-dcp.atlassian.net/servicedesk/customer/portal/1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Support</a
                  >.
                </p>
              </div>
              <button
                class="btn btn-primary btn-block mt-16"
                *ngIf="!mobileVerificationCodeSent"
                color="accent"
                [disabled]="form.invalid || isRegisterLoading"
                (click)="handleSmsVerification()"
              >
                {{ mobileVerificationCodeSent ? 'Verify Code' : 'Send Code' }}
              </button>
              <ng-container *ngIf="mobileVerificationCodeSent">
                <p class="text-sm font-normal text-center">
                  We’ve sent you a 6 digit code via the number provided. If you didn’t receive one, click below to
                  resend.
                </p>
                <button class="btn btn-primary btn-block mt-16" color="accent" (click)="sendSMSVerificationMessage()">
                  Resend Code
                </button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="smsVerified() && !emailVerified()">
              <div
                class="form-group"
                [ngClass]="{ error: form.controls.email.hasError('email') && form.controls.email.hasError('required') }"
              >
                <label>Your Email</label>
                <input placeholder="Email Address" spellcheck="false" formControlName="email" tabindex="1" />
                <p
                  class="error-msg"
                  i18n
                  *ngIf="
                    form.controls.email.hasError('email') &&
                    !form.controls.email.hasError('required') &&
                    form.controls.email.touched
                  "
                >
                  Invalid Email.Please enter a Valid Email.
                </p>
              </div>
              <div
                class="form-group"
                [ngClass]="{ error: form.get('emailCode')?.errors && form.get('emailCode')?.touched }"
                *ngIf="emailVerificationCodeSent && !emailVerified()"
              >
                <label> Enter 6 Digit Code</label>
                <input
                  type="text"
                  placeholder=" 6 Digit Code"
                  spellcheck="false"
                  formControlName="emailCode"
                  tabindex="1"
                  appOnlyNumber
                  maxlength="6"
                />
                <p class="error-msg" i18n *ngIf="form.get('emailCode')?.errors && form.get('emailCode')?.touched">
                  Please check your code and try again or contact
                  <a
                    href="https://support-dcp.atlassian.net/servicedesk/customer/portal/1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Support</a
                  >.
                </p>
              </div>
              <button
                class="btn btn-primary btn-block mt-16"
                *ngIf="!emailVerificationCodeSent"
                color="accent"
                [disabled]="form.invalid || isRegisterLoading"
                (click)="sendEmailCode()"
              >
                Send Code
              </button>
              <ng-container *ngIf="emailVerificationCodeSent">
                <p class="text-sm font-normal text-center">
                  We’ve sent you a 6 digit code via the email provided. If you didn’t receive one, click below to resend
                </p>
                <button class="btn btn-primary btn-block mt-16" color="accent" (click)="sendEmailCode()">
                  Resend Code
                </button>
              </ng-container>
              <button
                class="btn btn-primary btn-block mt-16"
                *ngIf="emailVerificationCodeSent"
                color="accent"
                [disabled]="form.invalid || isRegisterLoading"
                (click)="confirmEmailCode()"
              >
                Verify Code
              </button>
            </ng-container>
          </div>
          <ng-template #mobile>
            <div class="form-group" *ngIf="form.controls.mask">
              <label>Phone Number</label>
              <div class="items-center form-group flex gap-8 pb-0">
                <app-flag-picker
                  [selectedCountryCode]="selectedCountryCode"
                  [countryCodes]="countryCodes"
                  (changedCountryCode)="changeSelectedCountryCode($event)"
                >
                </app-flag-picker>
                <p class="text-lg">+{{ phoneCode }}</p>
                <input
                  #phoneUpdateFirstField
                  formControlName="mask"
                  appPhoneMask
                  placeholder="Enter a phone number"
                  maxlength="20"
                  class="phone-input"
                />
                <input class="hidden" type="text" formControlName="mobile" maxlength="20" />
              </div>
              <p class="error-msg mt-8" *ngIf="form.get('mobile')?.errors && form.get('mask')?.touched">
                Invalid number! Please enter valid number and try again.
              </p>
            </div>
          </ng-template>
        </form>
        <ng-container *ngIf="user?.name && smsVerified() && emailVerified() && !personalDetailsComplete">
          <app-personal-detail-update-dialog (closePanel)="closePanel()"></app-personal-detail-update-dialog>
        </ng-container>
        <button type="button" class="btn btn-link btn-sm mt-8" (click)="closePanel()" style="padding: 0 !important">
          Skip for now
        </button>
      </div>
    </div>
    <div
      class="w-full h-full flex flex-col items-center justify-center absolute top-0 left-0 bg-backdrop"
      *ngIf="isRegisterLoading"
    >
      <app-local-loader [hasTimeout]="false" class="w-32 h-32"></app-local-loader>
    </div>
  </div>
</div>
