import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ExternalCheckoutRequest, Payment, User } from '../../../../app.datatypes';
import { AuthenticatedUser, PaymentStateService } from '../../../state';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, throwError, timer } from 'rxjs';
import { ToasterService } from '../../../services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AvailableDailyLimitService } from '../../../services/available-daily-limit.service';
import { catchError, switchMap, tap } from 'rxjs/operators';

enum PaymentMethodSlug {
  WALLET = 'wallet',
  STRIPE = 'stripe',
  CRYPTO = 'crypto',
}

@Component({
  selector: 'app-dcp-checkout',
  templateUrl: './checkout.component.html',
})
export class CheckoutComponent implements OnInit, OnChanges {
  @Input() user: User | AuthenticatedUser;
  @Input() films: number;
  @Input() isLoading = false;
  @Input() doCheckout: Observable<void>;
  @Input() disabled: boolean;
  @Input() externalOnly: boolean;
  @Input() isUSDC = false;
  @Input() selectedPaymentMethod = PaymentMethodSlug.WALLET;
  @Output() changeCheckoutType = new EventEmitter<boolean>();
  @Output() formValidity = new EventEmitter<boolean>();
  showUSDC = false;
  dollars: number;
  filmPrice: number;
  internalPortion = 0;
  minUsdAmountAllowed = this.selectedPaymentMethod === PaymentMethodSlug.WALLET ? 0 : 1;
  maxInternalPortionAvailable: number;
  paymentMethodSlug = PaymentMethodSlug;
  paymentMethods = [
    { slug: PaymentMethodSlug.WALLET, name: 'Current Balance' },
    { slug: PaymentMethodSlug.STRIPE, name: 'Credit/Debit Card' },
    { slug: PaymentMethodSlug.CRYPTO, name: 'Cryptocurrency (BTC/ETH/etc.)' },
  ];
  checkoutForm: FormGroup;
  paymentError: string;
  showMessage = true;
  fullyPaidInternal = true;
  availableDailyLimitService = inject(AvailableDailyLimitService);

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly paymentService: PaymentStateService,
    private readonly toasterService: ToasterService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.checkoutForm && changes.films?.previousValue !== changes.films?.currentValue && !this.isLoading) {
      this.handleFields();
    }
  }

  ngOnInit(): void {
    if (this.externalOnly) {
      this.paymentMethods.shift();
    }
    this.checkoutForm = this.formBuilder.group({
      films: [this.films?.toFixed(2)],
      dollars: [null, [Validators.min(this.minUsdAmountAllowed), this.availableDailyLimitService.getValidator()]],
      internalPortion: [this.internalPortion],
    });

    this.checkoutForm.valueChanges.subscribe(() => {
      this.formValidity.emit(this.checkoutForm.valid);
    });

    this.setDefaultMethod();

    this.paymentService.externalCheckout$
      .pipe(
        switchMap((externalCheckoutRequest) => this.handleExternalCheckout(externalCheckoutRequest)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
    if (!this.externalOnly) {
      this.checkoutForm.get('internalPortion').valueChanges.subscribe((internalPortion) => {
        const isFullyPaidInternal = internalPortion === this.films;
        this.handleFilmChange(internalPortion);
        this.handleFields();
        this.showMessage = isFullyPaidInternal && internalPortion > 0;
        const control = this.checkoutForm.get('internalPortion');
        if (internalPortion > this.maxInternalPortionAvailable) {
          control.setErrors({
            max: 'This value cannot be greater than your current balance, or the total value of your submission.',
          });
        } else {
          this.fullyPaidInternal = isFullyPaidInternal;
          this.changeCheckoutType.emit(this.isExternalCheckout());
        }
      });
    }
    this.availableDailyLimitService.setDailyLimit().subscribe();
    this.changePaymentMethod(this.selectedPaymentMethod);
  }

  setDefaultMethod(): void {
    const paymentMethod =
      this.films > this.user.balance || this.externalOnly
        ? { method: PaymentMethodSlug.STRIPE, portion: this.externalOnly ? 0 : this.user.balance }
        : { method: PaymentMethodSlug.WALLET, portion: 0 };
    this.changePaymentMethod(paymentMethod.method);
    this.checkoutForm.get('internalPortion').setValue(paymentMethod.portion);
  }

  handleMaxInternalPortion(): void {
    this.maxInternalPortionAvailable = Math.min(this.films, this.user.balance);
    this.checkoutForm.controls['internalPortion'].setValidators([
      Validators.min(0),
      Validators.max(this.maxInternalPortionAvailable),
    ]);
  }

  changePaymentMethod(method: PaymentMethodSlug): void {
    this.selectedPaymentMethod = method;

    if (this.films > this.user.balance) {
      this.checkoutForm.get('internalPortion').setValue(this.user.balance);
    }

    if (this.selectedPaymentMethod !== PaymentMethodSlug.WALLET) {
      this.showUSDC = this.isUSDC;
      this.handleFields();
      this.handleMaxInternalPortion();
      this.fullyPaidInternal = false;
      this.checkoutForm.get('internalPortion').updateValueAndValidity({ onlySelf: false, emitEvent: true });
      this.minUsdAmountAllowed = 0.01;
    } else {
      this.checkoutForm.get('internalPortion').setValue(0);
      this.showMessage = false;
      this.fullyPaidInternal = true;
      this.minUsdAmountAllowed = 0;
    }
    this.checkoutForm
      .get('dollars')
      .setValidators([Validators.min(this.minUsdAmountAllowed), this.availableDailyLimitService.getValidator()]);
    this.checkoutForm.get('dollars').updateValueAndValidity({ onlySelf: false, emitEvent: true });
    this.changeCheckoutType.emit(this.isExternalCheckout());
  }

  handleFields(): void {
    const externalAmount = this.films - this.checkoutForm.get('internalPortion').value;
    this.checkoutForm.get('films').setValue(Number(externalAmount)?.toFixed(2));
    this.checkoutForm.get('films').updateValueAndValidity();
    this.filmPrice = this.paymentService.getSaleFilmPricing();
    const dollars = this.paymentService.calculateFilmToDollarPricing(Number(externalAmount)).toFixed(2);
    this.checkoutForm.get('dollars').setValue(Number(dollars));
    this.checkoutForm.get('dollars').updateValueAndValidity();
    if (this.showUSDC) {
      this.checkoutForm.get('films').setValue(Number(dollars));
    }
    this.paymentError = null;
  }

  handleFilmChange(change: number): void {
    if (change === this.films) {
      this.checkoutForm.get('dollars').setValue(null);
      this.checkoutForm.get('dollars').clearValidators();
      this.checkoutForm.get('dollars').updateValueAndValidity();
      this.showMessage = true;
    }
  }

  handleExternalCheckout(externalCheckoutRequest: ExternalCheckoutRequest): Observable<number> {
    externalCheckoutRequest.type = this.selectedPaymentMethod;
    externalCheckoutRequest.internal_portion = this.checkoutForm.get('internalPortion').value ?? 0;
    this.toasterService.openToastr('Please wait till your purchase is processed...', 'New Purchase');
    return this.paymentService.processExternalCheckout(externalCheckoutRequest).pipe(
      switchMap((payment: Payment) => this.onPaymentSuccess(payment)),
      catchError((error) => {
        if (error?.error) {
          this.paymentError = error.error.message;
          this.toasterService.openToastr(error.error.message, 'Charge cancelled', 'error');
        }
        return throwError(() => error);
      })
    );
  }

  private onPaymentSuccess(payment: Payment): Observable<number> {
    if (payment.coinbase_code) {
      this.toasterService.openToastr(
        'You have successfully created a new charge for buying tokens. You must transfer the required crypto within the next hour to complete your order of FILM.',
        'Transfer Required'
      );
      if (payment.coinbase_hosted_url) {
        return timer(1000).pipe(
          tap((_next) => {
            window.location.href = payment.coinbase_hosted_url;
          })
        );
      }
    } else if (payment.stripe_redirect_url) {
      this.toasterService.openToastr(
        'You will now be redirected to Stripe to complete the payment.',
        'Payment Required'
      );
      return timer(1000).pipe(
        tap((_next) => {
          window.location.href = payment.stripe_redirect_url;
        })
      );
    }
  }

  private isExternalCheckout(): boolean {
    return this.selectedPaymentMethod !== PaymentMethodSlug.WALLET && !this.fullyPaidInternal;
  }
}
