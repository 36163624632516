import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  AboutProposalData,
  AdditionalField,
  Genre,
  Language,
  MediaType,
  MediaTypes,
  Proposal,
  ProposalRound,
  SaveProposalMediaList,
} from '../../../../../app.datatypes';
import { ToasterService } from '../../../../../shared';
import BaloonEditor from '@ckeditor/ckeditor5-build-balloon';
import { SafeHtml, SafeUrl } from '@angular/platform-browser';
import { GlobalTourService } from '../../../../../shared/services/global-tour-service.service';
import { JoyrideService } from 'ngx-joyride';
import { CroppedImage } from '../../../../../shared/modules/components/image-cropper/cover-image-cropper/cover-image-cropper.component';
import { validateFile } from '../../../../../shared/modules/components/image-cropper/dcp-image-cropper/utils/blob.utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EditorConfig } from '../../../../../shared/constant';

@Component({
  selector: 'app-proposal-create-step-two',
  templateUrl: './proposal-create-step-two.component.html',
  styleUrls: ['./proposal-create-step-two.component.scss'],
})
export class ProposalCreateStepTwoComponent implements OnInit, OnChanges {
  @Input() proposal: Proposal;
  @Input() languageList: Language[];
  @Input() genreList: Genre[];
  @Input() selectedRound: ProposalRound;
  @Output() setStepTwo = new EventEmitter<AboutProposalData>();

  stepTwoForm: FormGroup;
  uploadedCroppedCoverImage: string;
  isImageLoaded = false;
  isCropperLoaded = false;
  coverId: string;
  isCoverUploaded = false;
  isOpenAdditionalModal = false;
  isOpenModal = false;
  uploadType: MediaType;
  mode: string;
  proposalImageUploaded: string | SafeUrl;
  proposalFileUploaded: string;
  proposalVideoUploaded: string | SafeHtml;
  isProposalDirectVideoUpload = false;
  proposalModalTitle: string;
  proposalModalValue: string;
  proposalModalDescription: string;
  proposalVideoBlob: Blob;
  proposalIndex: number;
  mediaList = [];
  mediaTypes = MediaTypes;
  ckConfig = EditorConfig;
  /* Baloon Editor */
  Editor = BaloonEditor;
  selectedMediaFile: any;
  imageToUploadBlob: Blob;
  createTitle: string = 'Project';
  mediaType = MediaType;

  get additionalFields() {
    return this.stepTwoForm?.get('additional_fields') as FormArray;
  }
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly toastService: ToasterService,
    private readonly cdRef: ChangeDetectorRef,
    protected readonly globalTour: GlobalTourService,
    protected readonly joyrideService: JoyrideService,
    private readonly destroyRef: DestroyRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRound?.currentValue?.id !== changes.selectedRound?.previousValue?.id) {
      this.ckConfig.placeholder = this.selectedRound?.proposal_description_fields?.description.placeholder;
      this.handleAdditionalFields();
    }
  }

  handleAdditionalFields(): void {
    if (this.selectedRound?.additional_fields?.length && this.stepTwoForm) {
      this.selectedRound.additional_fields.forEach((additionalField, i) => {
        this.addField(additionalField, i);
      });
    } else if (this.stepTwoForm) {
      this.additionalFields.clear();
    }
    this.validateFormData();
  }

  ngOnInit(): void {
    this.globalTour.handleFormValidation.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      let form = document.getElementById('stepTwo');
      let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
      firstInvalidControl.scrollIntoView();
      (firstInvalidControl as HTMLElement).focus();
      this.stepTwoForm.markAllAsTouched();
    });
    this.stepTwoForm = this.formBuilder.group({
      title: [this.proposal.title, [Validators.required, Validators.maxLength(191)]],
      content: [this.proposal.content ?? this.proposal.log_line, [Validators.required, Validators.maxLength(500)]],
      summary: [this.proposal.summary ?? this.proposal.description, Validators.required],
      genres: [this.proposal.genres ? this.proposal.genres.map((value) => value.slug) : null, Validators.required],
      languages: [
        this.proposal.languages ? this.proposal.languages.map((value) => value.iso) : null,
        Validators.required,
      ],
      genreAdd: null,
      enable_chat: this.proposal.enable_chat ?? true,
      hidden_publicly: this.proposal.hidden_publicly ?? false,
      additional_fields: new FormArray([]),
    });
    this.handleAdditionalFields();
    this.buildMedia();
    this.validateFormData();
  }

  addField(additionalField: AdditionalField, index: number): void {
    this.additionalFields.push(
      new FormGroup({
        title: new FormControl(additionalField?.title),
        tooltip: new FormControl(additionalField?.tooltip),
        placeholder: new FormControl(additionalField?.placeholder),
        value: new FormControl(
          this.proposal?.additional_fields?.length > index ? this.proposal?.additional_fields[index].value : '',
          Validators.required
        ),
      })
    );
  }

  openModal(ev?: Event): void {
    const files = (<HTMLInputElement>ev.target)?.files;
    this.selectedMediaFile = files[0];
    let type = validateFile(files[0], this.toastService);
    if (!type) {
      return;
    }
    this.uploadType = type;
    this.mode = 'add';
    this.proposalImageUploaded = '';
    this.proposalFileUploaded = '';
    this.proposalVideoUploaded = '';
    this.isProposalDirectVideoUpload = false;
    this.proposalModalTitle = '';
    this.proposalModalValue = '';
    this.proposalVideoBlob = null;
    this.proposalModalDescription = '';
    this.isOpenModal = true;
  }

  openAdditionalMedia(): void {
    this.isOpenAdditionalModal = true;
  }

  cancelModal(): void {
    this.isOpenAdditionalModal = false;
    this.isOpenModal = false;
  }

  saveMedia(data: SaveProposalMediaList): void {
    if (data?.index > 0) {
      for (let i = 0; i < this.mediaList?.length; i++) {
        if (this.mediaList[i]?.index === data?.index) {
          this.mediaList[i].value = data?.value;
          this.mediaList[i].title = data?.title;
          this.mediaList[i].description = data?.description;
          this.mediaList[i].fileData = data?.fileData;
          this.mediaList[i].videoData = data?.videoData;
          this.mediaList[i].thumbnailUrl = data?.thumbnailUrl;
          this.mediaList[i].file_id = data?.file_id;
          this.mediaList[i].isDirectVideoUpload = data?.isDirectVideoUpload;
          this.mediaList[i].videoBlob = data?.videoBlob;
          break;
        }
      }
      this.cdRef.detectChanges();
    } else {
      const index = this.mediaList.length;
      data.index = index + 1;
      this.mediaList.push(data);
    }
    this.validateFormData();
    this.isOpenModal = false;
  }

  deleteMedia(index: number): void {
    if (index) {
      this.mediaList = this.mediaList.filter((x) => x?.index !== index);
      this.validateFormData();
    }
  }

  editMedia(data: SaveProposalMediaList): void {
    if (data) {
      this.uploadType = data?.type;
      this.mode = 'edit';
      this.proposalIndex = data?.index;
      this.proposalImageUploaded = data?.value;
      this.proposalFileUploaded = data?.fileData;
      this.proposalVideoUploaded = data?.videoData;
      this.isProposalDirectVideoUpload = data?.isDirectVideoUpload;
      this.proposalModalTitle = data?.title;
      this.proposalModalDescription = data?.description;
      this.proposalVideoBlob = data?.videoBlob;
      this.isOpenModal = true;
    }
  }

  selectGenre(evt: Genre[]): void {
    const result = [];
    if (evt.length) {
      for (const item of evt) {
        result.push(item.slug);
      }
    }
    this.stepTwoForm.controls['genres'].setValue(result);
    this.validateFormData();
  }

  selectLanguage(evt: Language[]): void {
    const result = [];
    if (evt.length) {
      for (const item of evt) {
        result.push(item.iso);
      }
    }
    this.stepTwoForm.controls['languages'].setValue(result);
    this.validateFormData();
  }

  coverUploaded(event: CroppedImage): void {
    this.isCoverUploaded = true;
    this.coverId = event.id;
    this.uploadedCroppedCoverImage = event.url;
    this.validateFormData();
  }

  validateFormData(): void {
    if (this.stepTwoForm) {
      let data = this.stepTwoForm.value;
      data.log_line = data.content;
      data.description = data.summary;
      const isValid = { isValid: this.stepTwoForm.valid && this.isCoverUploaded };
      const mediaData = {
        mediaList: this.mediaList,
        isCoverUploaded: this.isCoverUploaded,
        coverId: this.coverId,
        cover_url: this.uploadedCroppedCoverImage,
        cover_type: 'image',
      };
      data = { ...data, ...isValid, ...mediaData };
      this.setStepTwo.emit(data);
    }
  }

  buildMedia(): void {
    if (this.proposal) {
      if (this.proposal?.cover_url) {
        this.uploadedCroppedCoverImage = this.proposal?.cover_url;
        this.isCoverUploaded = true;
      }
      // Reset mediaList when rebuilding the list to prevent duplicated entries.
      this.mediaList = [];
      let index = 1;
      this.proposal?.videos?.forEach((video) => {
        const data = {
          index: index + 1,
          type: MediaType.VIDEO,
          value: video?.url,
          title: video?.mediaTitle,
          description: video?.description,
          videoData: video?.url,
          thumbnailUrl: video?.thumbnailUrl,
          isDirectVideoUpload: video?.isDirectVideoUpload,
          videoBlob: video?.videoBlob,
          file_id: video?.file_id,
        };
        index = index + 1;
        this.mediaList.push(data);
      });
      this.proposal?.images?.forEach((image) => {
        const data = {
          index: index + 1,
          type: MediaType.IMAGE,
          value: image?.url,
          title: image?.mediaTitle,
          description: image?.description,
        };
        index = index + 1;
        this.mediaList.push(data);
      });
      this.proposal?.docs?.forEach((doc) => {
        const data = {
          index: index + 1,
          type: MediaType.FILE,
          value: doc?.url,
          title: doc?.docTitle,
          description: doc?.description,
          fileData: doc?.file_preview_url,
        };
        index = index + 1;
        this.mediaList.push(data);
      });
    }
    this.cdRef.detectChanges();
  }
}
