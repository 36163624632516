import { Component, Input, OnInit } from '@angular/core';
import { RoundProgress } from '../../../../app.datatypes';

@Component({
  selector: 'app-round-progress-widget',
  templateUrl: './round-progress-widget.component.html',
  styleUrls: ['./round-progress-widget.component.scss'],
})
export class RoundProgressWidgetComponent implements OnInit {
  @Input() progress: RoundProgress;
  @Input() reverted: boolean;
  circumference: number = 15 * 2 * Math.PI;
  strokeDasharray: string = `${this.circumference} ${this.circumference}`;
  strokeDashoffset: string;

  ngOnInit(): void {
    if (this.progress) {
      this.strokeDashoffset = (
        this.circumference -
        (this.progress.percentProgress / 100) * this.circumference
      ).toString();
    }
  }
}
