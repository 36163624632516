import { AbstractControl, ValidationErrors } from '@angular/forms';

export function getPositionForKey(key: string): string {
  switch (key) {
    case 'ArrowUp':
      return 'top';
    case 'ArrowRight':
      return 'right';
    case 'ArrowDown':
      return 'bottom';
    case 'ArrowLeft':
    default:
      return 'left';
  }
}

export function getInvertedPositionForKey(key: string): string {
  switch (key) {
    case 'ArrowUp':
      return 'bottom';
    case 'ArrowRight':
      return 'left';
    case 'ArrowDown':
      return 'top';
    case 'ArrowLeft':
    default:
      return 'right';
  }
}

export function getEventForKey(key: string, stepSize: number): any {
  switch (key) {
    case 'ArrowUp':
      return { clientX: 0, clientY: stepSize * -1 };
    case 'ArrowRight':
      return { clientX: stepSize, clientY: 0 };
    case 'ArrowDown':
      return { clientX: 0, clientY: stepSize };
    case 'ArrowLeft':
    default:
      return { clientX: stepSize * -1, clientY: 0 };
  }
}

export function greaterThanZeroValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value !== null && control.value <= 0) {
    return { greaterThanZero: true };
  }
  return null;
}
