import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DcpDatePickerComponent } from './dcp-date-picker.component';
import { FormsModule } from '@angular/forms';
import {
  ControlErrorMessageModule
} from "../../../components/inputs/control-error-message/control-error-message.module";



@NgModule({
  declarations: [DcpDatePickerComponent],
  imports: [CommonModule, DpDatePickerModule, FormsModule, ControlErrorMessageModule],
  exports: [DcpDatePickerComponent]
})
export class DatePickerModule {}
