import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, tap, switchMap, map } from 'rxjs/operators';
import { environment } from '../../../../environments';
import { ApiService } from '../../services';
import { AuthenticatedUserService } from '../authenticated-user';
import { ProposalStateService } from '../proposal';
import { DistributionService } from '../../services/distribution.service';
import {
  DistributionReview,
  ProposalReview,
  Review,
  ReviewLikeResponse,
  SubmitReviewRequest,
} from 'app/shared/interface/review';
import { HttpErrorResponse } from '@angular/common/http';
import { ProcessStatus, SubmitLikeRequest } from 'app/app.datatypes';
import { FormControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class ReviewStateService {
  url = 'api/reviews/';
  distributionUrl = 'api/distributions/reviews/';
  reviews$: BehaviorSubject<Review[]> = new BehaviorSubject([]);
  constructor(
    private readonly proposalStateService: ProposalStateService,
    private readonly apiService: ApiService,
    private readonly authenticatedUserService: AuthenticatedUserService,
    private readonly distributionService: DistributionService
  ) {}

  handleError(error: HttpErrorResponse) {
    return this?.apiService.catchError(error) || EMPTY;
  }

  resetModeratioReviews(): void {
    let reiews = this.reviews$.getValue();
    reiews = reiews.filter((review) => review.status !== ProcessStatus.TO_BE_MODERATED);
    this.reviews$.next(reiews);
  }

  loadReview(id: string, isDistribution = false): Observable<Review> {
    let url = this.url;
    if (isDistribution) {
      url = this.distributionUrl;
    }
    return this.reviews$.pipe(
      switchMap((array) => {
        const foundItem = array.find((item) => item.id === id);
        return foundItem
          ? of(foundItem)
          : this.apiService.get(`${url}${id}`).pipe(
              tap((review: ProposalReview) => {
                this.upsertReview(review);
              })
            );
      })
    );
  }

  private getReviewsByEntity(id: string, evaluationOnly: boolean): Observable<Review[]> {
    return this.reviews$.pipe(
      map((reviews) => reviews.filter((review) => this.checkExisting(review, id, evaluationOnly)))
    );
  }

  addReviews(data: Review[]): void {
    data.forEach((review) => this.upsertReview(review));
  }

  checkExisting(review: Review, id: string, evaluationOnly: boolean) {
    const isRelatedToId =
      (review as ProposalReview).proposal_id === id || (review as DistributionReview).video_distribution_id === id;
    const hasContent = evaluationOnly
      ? !review.content
      : !!review.content || review.user_id === this.authenticatedUserService.getUser().id;
    return isRelatedToId && hasContent;
  }

  getReviews(
    id: string,
    evaluationOnly: boolean,
    isDistribution: boolean,
    field = 'newest',
    skip = 0,
    reset = false
  ): Observable<Review[]> {
    const currentReviews = this.reviews$.getValue();
    const hasReviews = currentReviews.some((review) => this.checkExisting(review, id, evaluationOnly));
    if (hasReviews && !skip && !reset) {
      return this.getReviewsByEntity(id, evaluationOnly);
    } else {
      let api = `${isDistribution ? 'api/distributions' : 'api/proposals'}/${id}/reviews?limit=${
        environment.review_skip_limit
      }&skip=${reset ? 0 : skip}&field=${field}&${evaluationOnly ? 'evaluations' : 'reviews'}`;
      return this.apiService.get(api).pipe(
        tap((reviews) => {
          if (reset) {
            const filteredReviews = currentReviews.filter((review) => !this.checkExisting(review, id, evaluationOnly));
            filteredReviews.push(...reviews);
            this.reviews$.next(filteredReviews);
          } else {
            this.addReviews(reviews);
          }
        }),
        switchMap(() => this.getReviewsByEntity(id, evaluationOnly)),
        catchError(this.handleError.bind(this))
      );
    }
  }

  postDistributionReview(review: Partial<DistributionReview>): Observable<DistributionReview> {
    return this.apiService.post(this.distributionUrl, review).pipe(
      tap((review) => {
        this.distributionService.update({ id: review.video_distribution_id, is_reviewable: false });
        this.upsertReview(review);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  putDistributionReview(review: Partial<DistributionReview>): Observable<DistributionReview> {
    return this.apiService.put(this.distributionUrl + review.id, review).pipe(
      tap((review) => {
        this.upsertReview(review);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  upsertReview(review: Partial<Review>, unshift = false): void {
    const reviews = this.reviews$.getValue();
    const index = reviews.findIndex((obj) => obj.id === review.id);
    if (index !== -1) {
      reviews[index] = { ...reviews[index], ...review };
    } else {
      reviews.splice(unshift ? 0 : reviews.length, 0, review as Review);
    }
    this.reviews$.next(reviews);
  }

  postReview(review: Partial<ProposalReview>): Observable<ProposalReview> {
    return this.apiService.post(this.url, review).pipe(
      tap((review: ProposalReview) => {
        this.upsertReview(review, true);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  putReview(review: Partial<ProposalReview>): Observable<ProposalReview> {
    return this.apiService.put(this.url + review.id, review).pipe(
      tap((review: ProposalReview) => {
        this.upsertReview(review);
      }),
      catchError(this.handleError.bind(this))
    );
  }

  submitReview(req: SubmitReviewRequest, isDistribution = false): Observable<ProposalReview> {
    const url = !isDistribution ? this.url : this.distributionUrl;
    return this.apiService
      .post(url + req.reviewId + '/submit', {
        sig: req.signature,
        nonce: req.nonce,
        pk: req.pk,
      })
      .pipe(
        tap((review: Review) => {
          if (!isDistribution) {
            const propsoalReview = review as ProposalReview;
            this.proposalStateService.setOnOwnReviewStatusChange(propsoalReview.proposal_id, review.status);
          } else {
            this.distributionService.updateReviewStatus(
              (review as DistributionReview).video_distribution_id,
              review.status
            );
          }
          this.upsertReview(review);
        }),
        catchError(this.handleError.bind(this))
      );
  }

  cancel(id: string): Observable<ProposalReview> {
    return this.apiService.get(this.url + id + '/cancel');
  }

  getAuthenticatedUserStateReviews(userid: string, skip = 0, search = ''): Observable<ProposalReview[]> {
    let apiUrl = this.url + 'user-reviews/';
    if (userid) {
      apiUrl += userid;
    }
    apiUrl += '?skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : '');
    return this.apiService.get(apiUrl);
  }

  lockReview(reviewId: string, isDistribution = false): Observable<ProposalReview> {
    let api = `${isDistribution ? this.distributionUrl : this.url}${reviewId}/lock`;
    return this.apiService.put(api, null).pipe(
      tap((response: ProposalReview) => {
        this.authenticatedUserService.setModeratedEntity({ entity: 'review', id: reviewId });
        this.upsertReview(response);
      })
    );
  }

  unlockReview(reviewId: string, isDistribution = false): Observable<ProposalReview> {
    let api = `${isDistribution ? this.distributionUrl : this.url}${reviewId}/unlock`;
    return this.apiService.put(api, null).pipe(
      tap((response: ProposalReview) => {
        this.upsertReview(response);
      })
    );
  }

  reviewModerated(review: ProposalReview): void {
    this.upsertReview(review);
    this.authenticatedUserService.setUnlocked();
  }

  getAllModerationReview(userId: string, isDistribution = false): Observable<Review[]> {
    return this.reviews$.pipe(
      map((reviews) =>
        reviews.filter((review) => {
          if (review.user_id === userId) return false;

          const isModerationNeeded = review.status === ProcessStatus.TO_BE_MODERATED;

          if (isDistribution) {
            return isModerationNeeded && !!review['video_distribution_id'];
          } else {
            return isModerationNeeded && !review['video_distribution_id'];
          }
        })
      )
    );
  }

  likeReview(reviewId: string, proposalId: string, is_like: boolean): Observable<ReviewLikeResponse> {
    return this.apiService.post(`${this.url}${reviewId}/like`, { is_like }).pipe(
      tap((response) => {
        this.proposalStateService.updateProposalReview(proposalId, {
          totalVotes: response.newEntityTotalVotes,
          reviews_score: response.newEntityTotalReviews,
          evaluations_score: response.newEntityTotalEvaluations,
          likes_score: response.newEntityTotalLikes,
          reviewsArray: response.reviewsArray,
        });
      })
    );
  }

  likeDistributionReview(reviewId: string, is_like: boolean): Observable<ReviewLikeResponse> {
    return this.apiService.post(`${this.distributionUrl}${reviewId}/like`, {
      is_like,
    });
  }

  submitLikeReview(req: SubmitLikeRequest, isDistribution = false): Observable<Review> {
    const request = {
      sig: req.signature,
      nonce: req.nonce,
      pk: req.pk,
      is_like: req.is_like ? 1 : 0,
    };
    let url = !isDistribution ? this.url : this.distributionUrl;
    return this.apiService.post(`${url}${req.reviewId}/like/${req.likeId}/submit`, request).pipe(
      tap((res) => {
        const reviews = this.reviews$.getValue();
        reviews.forEach(
          (review) => (review.is_liked_by_current_user = res.id === review.id && res.is_liked_by_current_user)
        );
        this.reviews$.next(reviews);
      })
    );
  }

  setEditable(review: Review): Observable<ProposalReview> {
    return this.apiService.put(`${this.url}${review.id}/set-editable`, null);
  }

  getAuthenticatedUserStateEvaluations(userid, skip = 0, search = ''): Observable<ProposalReview[]> {
    let apiUrl = 'api/users/evaluations/';
    if (userid) {
      apiUrl += userid + '/';
    }
    apiUrl += '?&skip=' + skip + (search ? '&search=' + encodeURIComponent(search) : '');
    return this.apiService.get(apiUrl);
  }

  removeHyperLinks(form: FormControl): void {
    const content = form.value.replace(/<a[^>]*>(.*?)<\/a>/g, '$1');
    form.setValue(content, { emitEvent: false });
  }

  setRecommendation(avgScore: number): string {
    let recommendation = '';
    switch (true) {
      case avgScore <= 3 && avgScore > 0:
        recommendation = 'pass';
        break;
      case avgScore <= 7 && avgScore > 3:
        recommendation = 'consider';
        break;
      case avgScore <= 10 && avgScore > 7:
        recommendation = 'recommend';
        break;
    }
    return recommendation;
  }
}
