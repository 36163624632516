<form [formGroup]="stepForm" id="stepForm" class="pt-1" autocomplete="off">
  <h4 class="mb-8 font-semibold">Project Title</h4>
  <div class="form-group">
    <input
      placeholder="What is title of your project"
      id="title"
      formControlName="title"
      required
      trim="blur"
      (blur)="validateFormData()"
      (keydown.enter)="$event.preventDefault()"
    />
    <p
      class="error-msg mt-8"
      *ngIf="stepForm.get('title').errors && stepForm.get('title').errors.required && stepForm.get('title').touched"
    >
      Project Title is <strong>Required</strong>
    </p>
    <p
      class="error-msg mt-8"
      *ngIf="stepForm.get('title').errors && stepForm.get('title').errors.maxlength && stepForm.get('title').touched"
    >
      Title is <strong>too long</strong>. Only {{ stepForm.get('title').errors.maxlength.requiredLength }} allowed,
      current length is {{ stepForm.get('title').errors.maxlength.actualLength }}.
    </p>
  </div>
  <h4 class="mb-16 font-semibold pb-8 border-b border-gray-border">Project Media</h4>
  <div>
    <p class="text-lg font-semibold">Cover Image</p>
    <p class="mb-16">
      Add a cover image below. This will be the first image of your project that users will see. (required)
    </p>
    <div class="md:w-2/3">
      <app-cover-image-cropper
        [ngClass]="{ 'block rounded border border-error ng-invalid': !uploadedCroppedCoverImage && stepForm.touched }"
        [data]="{ url: uploadedCroppedCoverImage }"
        (uploadedCover)="coverUploaded($event)"
      ></app-cover-image-cropper>
      <p class="error-msg mt-8" *ngIf="!uploadedCroppedCoverImage && stepForm.touched">
        Cover image is <strong>Required</strong>
      </p>
      <p class="text-lg font-semibold mt-16">Add Teaser/Trailer</p>
      <p class="mb-16">
        Add a teaser/trailer video below. This video will be the primary preview video for your project. (required)
      </p>
      <div class="relative mb-16" *ngIf="teaser">
        <div class="rounded ratio-16-9 overflow-hidden border border-gray-border video">
          <ng-container *ngIf="teaser?.thumbnailUrl">
            <img
              class="absolute top-1/2 left-1/2 w-full h-full object-cover ng-star-inserted transform -translate-y-1/2 -translate-x-1/2"
              [src]="teaser.thumbnailUrl"
              alt="thumbnail"
            />
          </ng-container>
        </div>
        <div class="flex absolute top-0 right-0 pt-8 pr-8">
          <button (click)="deleteDisribution(true)" class="btn-icon error btn-sm mr-8 btn-inverted deleteMedia">
            <app-icon [icon]="'trash'"></app-icon>
          </button>
          <button
            (click)="distributionType = 1; editDistribution(teaser)"
            class="btn-icon btn-sm edit btn-inverted editMedia"
          >
            <app-icon [icon]="'edit'"></app-icon>
          </button>
        </div>
        <p>{{ teaser?.title }}</p>
      </div>
      <div
        class="bg-body ratio-16-9 relative rounded cursor-pointer hover:opacity-75 add-media"
        *ngIf="!teaser"
        (click)="distributionType = 1; openModal(null, 'video')"
      >
        <div
          class="w-full h-full absolute flex flex-col justify-center items-center"
          [ngClass]="{ 'block rounded border border-error ng-invalid': !teaser && stepForm.touched }"
        >
          <app-icon [icon]="'video'" class="box-icon w-40 h-40 opacity-25 mb-16"></app-icon>
          <p class="text-blue text-center text-sm">Click to add your Teaser/Trailer</p>
        </div>
      </div>
      <p class="error-msg mt-8" *ngIf="!teaser && stepForm.touched">Teaser is <strong>Required</strong></p>
      <p class="text-lg font-semibold mt-16">Add Featured Content</p>
      <p class="mb-16">
        Add your featured content below. This will be the video available to the DCP community to rent or buy.
        (required)
      </p>
      <div class="relative" *ngIf="videoData">
        <div class="rounded ratio-16-9 overflow-hidden border border-gray-border video">
          <ng-container *ngIf="videoData?.thumbnailUrl">
            <img
              class="absolute top-1/2 left-1/2 w-full h-full object-cover ng-star-inserted transform -translate-y-1/2 -translate-x-1/2"
              [src]="videoData.thumbnailUrl"
              alt="thumbnail"
            />
          </ng-container>
        </div>
        <div class="flex absolute top-0 right-0 pt-8 pr-8">
          <button (click)="deleteDisribution(false)" class="btn-icon error btn-sm mr-8 btn-inverted deleteMedia">
            <app-icon [icon]="'trash'"></app-icon>
          </button>
          <button
            (click)="distributionType = 2; editDistribution(videoData)"
            class="btn-icon btn-sm edit btn-inverted editMedia"
          >
            <app-icon [icon]="'edit'"></app-icon>
          </button>
        </div>
        <p>{{ videoData?.title }}</p>
      </div>
      <div
        class="bg-body ratio-16-9 relative rounded cursor-pointer hover:opacity-75 add-media !mb-0"
        (click)="distributionType = 2; openModal(null, 'video')"
        *ngIf="!videoData"
      >
        <div
          class="w-full h-full absolute flex flex-col justify-center items-center"
          [ngClass]="{ 'block rounded border border-error ng-invalid': !videoData && stepForm.touched }"
        >
          <app-icon [icon]="'video'" class="box-icon w-40 h-40 opacity-25 mb-16"></app-icon>
          <p class="text-blue text-center text-sm">Click to add Featured Content</p>
        </div>
      </div>
      <p class="error-msg mt-8" *ngIf="!videoData && stepForm.touched">Feature Video is <strong>Required</strong></p>
    </div>
    <ng-container *ngFor="let media of mediaTypes">
      <div class="add-media-sect mt-16 pt-8 border-t border-gray-border add-media-sect">
        <p class="text-lg font-semibold">Add Additional {{ media.label }}</p>
        <p class="mb-16">Add any additional {{ media.label }} you wish to include with your project. (optional)</p>
        <div class="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-16 mt-16">
          <ng-container *ngFor="let item of mediaList">
            <div class="relative" *ngIf="item?.type === media.type">
              <div
                *ngIf="item?.type === mediaType.VIDEO"
                class="rounded ratio-16-9 overflow-hidden border border-gray-border video"
              >
                <ng-container *ngIf="item?.thumbnailUrl">
                  <img
                    class="absolute top-1/2 left-1/2 w-full h-full object-cover ng-star-inserted transform -translate-y-1/2 -translate-x-1/2"
                    [src]="item.thumbnailUrl"
                    alt="thumbnail"
                  />
                </ng-container>
              </div>
              <div
                *ngIf="item?.type === mediaType.IMAGE"
                class="rounded ratio-16-9 overflow-hidden border border-gray-border image"
              >
                <img
                  [src]="item?.value"
                  alt="img-group"
                  class="absolute top-1/2 left-1/2 w-full h-full object-cover ng-star-inserted transform -translate-y-1/2 -translate-x-1/2"
                />
              </div>
              <div
                *ngIf="item?.type === mediaType.FILE"
                class="rounded ratio-16-9 overflow-hidden border border-gray-border file"
              >
                <img
                  [src]="item?.fileData"
                  alt="img-group"
                  class="absolute top-1/2 left-1/2 w-full h-full object-cover ng-star-inserted transform -translate-y-1/2 -translate-x-1/2"
                />
              </div>
              <div class="flex absolute top-0 right-0 pt-8 pr-8">
                <button (click)="deleteMedia(item?.index)" class="btn-icon error btn-sm mr-8 btn-inverted deleteMedia">
                  <app-icon icon="trash"></app-icon>
                </button>
                <button
                  (click)="editMedia(item)"
                  *ngIf="item?.type !== mediaType.VIDEO"
                  class="btn-icon btn-sm edit btn-inverted editMedia"
                >
                  <app-icon [icon]="'edit'"></app-icon>
                </button>
              </div>
              <p>{{ item?.title }}</p>
            </div>
          </ng-container>
          <div
            class="bg-body rounded ratio-16-9 overflow-hidden border border-gray-border"
            id="addMedia"
            (click)="openAdditionalMedia()"
          >
            <div class="w-full h-full absolute flex top-0 flex-col justify-center items-center">
              <app-icon
                [icon]="media.type === mediaType.FILE ? 'pdf' : media.type"
                class="box-icon w-40 h-40 opacity-25 mb-16"
              ></app-icon>
              <p class="text-blue text-center text-sm">
                Click to add {{ media.type === mediaType.FILE ? 'pdf' : media.type }}
              </p>
              <input
                type="file"
                id="addMediaInput"
                [accept]="media.media"
                (change)="openModal($event)"
                class="opacity-0 absolute w-full h-full top-0 left-0 cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div>
    <h4 class="mt-24 mb-16 font-semibold pb-8 border-b border-gray-border">Project Details</h4>
    <p class="mb-16">Please add all your project details below.</p>
    <div class="form-group">
      <label class="flex"
        >Log Line
        <app-icon
          [icon]="'info'"
          class="ml-4 w-12 h-12 opacity-50 hover:opacity-100 inline-block"
          appTooltip="One-sentence summary or description of the project"
          placement="top"
        ></app-icon>
      </label>
      <textarea
        row="5"
        placeholder="Briefly describe what your project is about"
        id="content"
        formControlName="content"
        #content
        required
        trim="blur"
        (blur)="validateFormData()"
      ></textarea>
      <p
        class="error-msg mt-8"
        *ngIf="
          stepForm.get('content').errors && stepForm.get('content').errors.required && stepForm.get('content').touched
        "
      >
        Field is <strong>Required</strong>
      </p>
      <p
        class="error-msg mt-8"
        *ngIf="
          stepForm.get('content').errors && stepForm.get('content').errors.maxlength && stepForm.get('content').touched
        "
      >
        Maximum character allowed are {{ stepForm.get('content').errors.maxlength.requiredLength }}, currently log line
        have {{ stepForm.get('content').errors.maxlength.actualLength }} characters
      </p>

      <p class="text-count">{{ content.value.length }} / 500</p>
    </div>

    <div class="form-group">
      <label class="flex"
        >Project Description
        <app-icon
          [icon]="'info'"
          class="ml-4 w-12 h-12 opacity-50 hover:opacity-100 inline-block"
          appTooltip="The complete description of the project"
          placement="top"
        ></app-icon>
      </label>
      <ckeditor
        #summary
        class="form-control"
        required
        placeholder="Type the description here!"
        [config]="ckConfig"
        [editor]="Editor"
        formControlName="summary"
        type="inline"
        (blur)="validateFormData()"
      ></ckeditor>
      <p
        class="error-msg my-8"
        *ngIf="
          stepForm.get('summary').touched && stepForm.get('summary').errors && stepForm.get('summary').errors.required
        "
      >
        Field is <strong>Required</strong>
      </p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 md:gap-24">
      <div class="form-group">
        <app-dcp-multiselect
          [ngClass]="{
            'block rounded border border-error ng-invalid': stepForm.get('genres').errors && stepForm.touched
          }"
          *ngIf="genreList"
          [options]="genreList"
          [placeholder]="'Genre(s)'"
          [filterOption]="true"
          (selected)="selectGenre($event)"
          [selectedOptions]="selectedGeneres"
        >
        </app-dcp-multiselect>
        <p
          class="error-msg mt-8"
          *ngIf="stepForm.get('genres').errors && stepForm.get('genres').errors.required && stepForm.touched"
        >
          Field is <strong>Required</strong>
        </p>
      </div>
      <div class="form-group">
        <app-dcp-multiselect
          [ngClass]="{
            'block rounded border border-error ng-invalid': stepForm.get('languages').errors && stepForm.touched
          }"
          *ngIf="languageList"
          [options]="languageList"
          [placeholder]="'Target Audience Language(s)'"
          [filterOption]="true"
          (selected)="selectLanguage($event)"
          [selectedOptions]="selectedLanguages"
        >
        </app-dcp-multiselect>
        <p
          class="error-msg mt-8"
          *ngIf="stepForm.get('languages').errors && stepForm.get('languages').errors.required && stepForm.touched"
        >
          Field is <strong>Required</strong>
        </p>
      </div>
      <div class="form-group">
        <app-dcp-select
          [ngClass]="{
            'block rounded border border-error ng-invalid': stepForm.get('mpa_rating').errors && stepForm.touched
          }"
          *ngIf="mpaRatingList"
          [options]="mpaRatingList"
          [placeholder]="'Suggested MPAA Rating'"
          (selected)="selectMpaRating($event)"
          [selectedDefault]="distribution?.mpa_rating?.slug || ''"
        >
        </app-dcp-select>
        <p
          class="error-msg mt-8"
          *ngIf="stepForm.get('mpa_rating').errors && stepForm.get('mpa_rating').errors.reuired && stepForm.touched"
        >
          Field is <strong>Required</strong>
        </p>
      </div>
    </div>
    <h4 class="mt-24 mb-16 font-semibold pb-8 border-b border-gray-border">Cast & Crew(Optional)</h4>
    <ng-container formArrayName="cast">
      <ng-container *ngFor="let controls of cast.controls; let i = index">
        <p class="text-lg font-semibold flex items-center">
          Cast Member {{ i + 1 }}
          <button class="btn-icon-transparent btn-lg icon-red ml-12" (click)="removeField(i)">
            <app-icon icon="trash"></app-icon>
          </button>
        </p>
        <div class="grid grid-cols-1 md:grid-cols-4 md:gap-24 relative" [formGroupName]="i">
          <div class="form-group">
            <label>Character Name</label>
            <input type="text" formControlName="character_name" (blur)="validateFormData()" />
          </div>
          <div class="form-group col-span-2">
            <label>Talent Name</label>
            <input type="text" formControlName="name" (blur)="validateFormData()" />
          </div>
          <div class="form-group">
            <label>IMDB url</label>
            <input type="text" formControlName="imdb_link" (blur)="validateFormData()" />
          </div>
        </div>
      </ng-container>
    </ng-container>
    <button class="btn btn-link btn-sm !p-0 !min-w-[max-content] mb-24" (click)="addField()" [disabled]="cast.invalid">
      <app-icon icon="plus" class="w-12 h-12 !m-0"></app-icon>
      Add Cast
    </button>
    <ng-container formArrayName="crew">
      <ng-container *ngFor="let controls of crew.controls; let i = index">
        <p class="text-lg font-semibold flex items-center">
          Crew Member {{ i + 1 }}
          <button class="btn-icon-transparent icon-red btn-lg ml-12" (click)="removeField(i, true)">
            <app-icon icon="trash"></app-icon>
          </button>
        </p>
        <div class="grid grid-cols-1 md:grid-cols-4 md:gap-24 relative" [formGroupName]="i">
          <div class="form-group">
            <label>Crew Role</label>
            <input type="text" formControlName="role" (blur)="validateFormData()" />
          </div>
          <div class="form-group col-span-2">
            <label>Crew Name</label>
            <input type="text" formControlName="name" (blur)="validateFormData()" />
          </div>
          <div class="form-group">
            <label>IMDB url</label>
            <input type="text" formControlName="imdb_link" (blur)="validateFormData()" />
          </div>
        </div>
      </ng-container>
    </ng-container>
    <button
      class="btn btn-link btn-sm !min-w-[max-content] !p-0 mb-24"
      (click)="addField(null, true)"
      [disabled]="crew.invalid"
    >
      <app-icon icon="plus" class="w-12 h-12 !m-0" mb-24></app-icon>
      Add Crew
    </button>
    <h4 class="mb-16 font-semibold pb-8 border-b border-gray-border">DCP+ Pricing Details</h4>
    <div class="flex pb-24">
      <div class="form-check form-check-inline flex">
        <label class="radio">
          <input type="radio" formControlName="access_type" value="pay-to-watch" />
          <span class="checkmark"></span>
          Pay to Watch
        </label>
        <app-icon
          [icon]="'info'"
          tooltipClass="lg"
          class="ml-4 opacity-50 hover:opacity-100"
          style="position: inherit !important; width: 12px !important; height: 12px !important"
          appTooltip="This option allows you to set a rental and purchase price ($USD) for your feature content."
          placement="top"
        ></app-icon>
      </div>
      <div class="form-check form-check-inline ml-32 flex">
        <label class="radio">
          <input type="radio" formControlName="access_type" value="free-to-watch" />
          <span class="checkmark"></span>
          Free to Watch
        </label>
        <app-icon
          [icon]="'info'"
          tooltipClass="lg"
          class="ml-4 opacity-50 hover:opacity-100"
          style="position: inherit !important; width: 12px !important; height: 12px !important"
          appTooltip="This option allows users to view your feature content for free."
          placement="top"
        ></app-icon>
      </div>
    </div>

    <div
      class="grid grid-cols-1 md:grid-cols-2 md:gap-24 mb-24 relative"
      *ngIf="stepForm.value.access_type === 'pay-to-watch'"
    >
      <div class="form-group w-full addon-f p-0">
        <label>Set 72hr Rental Price</label>
        <input
          type="number"
          appOnlyNumber
          [maxDecimals]="2"
          formControlName="rental_price"
          (blur)="validateFormData()"
        />
        <app-icon [icon]="'usd'"></app-icon>
        <p class="error-msg mt-8" *ngIf="stepForm.get('rental_price').errors && stepForm.get('rental_price').touched">
          It should be greater than 0
        </p>
      </div>
      <div class="form-group w-full addon-f p-0">
        <label>Set Purchase Price</label>
        <input
          type="number"
          appOnlyNumber
          [maxDecimals]="2"
          formControlName="purchase_price"
          (blur)="validateFormData()"
        />
        <app-icon [icon]="'usd'"></app-icon>
        <p
          class="error-msg mt-8"
          *ngIf="stepForm.get('purchase_price').errors && stepForm.get('purchase_price').touched"
        >
          It should be greater than 0
        </p>
      </div>
      <div class="form-group w-full addon-f p-0">
        <label class="flex"
          >Set Review Reward(% of Rental/Purchase)
          <app-icon
            [icon]="'info'"
            class="ml-4 opacity-50 hover:opacity-100"
            style="position: inherit !important; width: 12px !important; height: 12px !important"
            appTooltip="Encourage reviews for your project by offering a discounted rental/purchase. The percentage set here will be discounted and returned to the viewer if they complete a review for your project. This is optional."
            placement="top"
          ></app-icon>
        </label>
        <input type="number" formControlName="review_reward_percentage" (blur)="validateFormData()" />
        <app-icon [icon]="'percentage'"></app-icon>
      </div>
    </div>

    <div
      class="grid grid-cols-1 md:grid-cols-2 md:gap-24 mb-24 relative"
      *ngIf="stepForm.value.access_type !== 'pay-to-watch'"
    >
      <div class="form-group w-full addon-f p-0">
        <label
          >Set Rewards Pool(FILM)
          <app-icon
            [icon]="'info'"
            class="ml-4 opacity-50 hover:opacity-100"
            style="position: inherit !important; width: 12px !important; height: 12px !important"
            appTooltip="Add a Rewards Pool to encourage viewers to review your project. This is optional."
            placement="top"
          ></app-icon
        ></label>
        <input type="number" (blur)="validateFormData()" formControlName="reward_contribution" />
        <app-icon [icon]="'film'"></app-icon>
      </div>
      <fieldset class="form-group w-full addon-f p-0" [disabled]="!stepForm.value.reward_contribution">
        <label
          >Set Earnings/Review(% of Rewards Pool)
          <app-icon
            [icon]="'info'"
            class="ml-4 opacity-50 hover:opacity-100"
            style="position: inherit !important; width: 12px !important; height: 12px !important"
            appTooltip="Percentage of overall Rewards Pool each viewer will earn for their Review."
            placement="top"
          ></app-icon
        ></label>
        <input type="number" formControlName="review_reward_percentage" (blur)="validateFormData()" />
        <app-icon [icon]="'percentage'"></app-icon>
      </fieldset>
    </div>
    <ng-container *ngIf="stepForm.value.access_type === 'pay-to-watch'">
      <h4 class="mb-16 font-semibold pb-8 border-b border-gray-border">Royalties</h4>

      <app-distribution-royalty-map
        [distribution]="distribution"
        (royaltyMaps)="setRoyaltyMaps($event)"
      ></app-distribution-royalty-map>
      <ng-container *ngIf="is_film_supported">
        <div class="info-box mt-24">
          <p class="flex w-full">
            Allow Affiliate Marketing (optional)<app-icon
              [icon]="'info'"
              tooltipClass="lg"
              class="ml-4 opacity-50 hover:opacity-100"
              style="position: inherit !important; width: 18px !important; height: 18px !important"
              appTooltip="Allow users to share your project to encourage additional rental/purchases. For every rental or purchase generated via an affiliate user link, the affiliate user will earn this percentage of the transaction total. Only available for FILMCredit earnings."
              placement="top"
            ></app-icon>
          </p>
          <label class="switch mt-4">
            <input type="checkbox" formControlName="affiliate_marketing" />
            <span class="slider rounded"></span>
          </label>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 md:gap-24 relative" *ngIf="stepForm.value.affiliate_marketing">
          <div class="form-group addon-f col-span-1">
            <label class="flex">Set Affiliate Earnings Percentage</label>
            <input type="number" formControlName="affiliate_marketing_percentage" (blur)="validateFormData()" />
            <app-icon [icon]="'percentage'"></app-icon>
            <p
              class="error-msg mt-8"
              *ngIf="
                stepForm.get('affiliate_marketing_percentage').errors &&
                stepForm.get('affiliate_marketing_percentage').touched
              "
            >
              It should be max of 100%
            </p>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <h4 class="mb-16 font-semibold pb-8 border-b border-gray-border mt-24">Additional Requirements (optional)</h4>
    <div class="form-group max-w-[564px]">
      <label class="flex"
        >Restricted Countries
        <app-icon
          [icon]="'info'"
          class="ml-4 opacity-50 hover:opacity-100"
          style="position: inherit !important; width: 12px !important; height: 12px !important"
          appTooltip="Select any country from the list to remove distribution rights from those regions."
          placement="top"
        ></app-icon>
      </label>
      <app-dcp-multiselect
        [filterOption]="true"
        placeholder="Country"
        *ngIf="countryList"
        [options]="countryList"
        (selected)="onCountrySelected($event)"
      >
      </app-dcp-multiselect>
    </div>
    <div class="form-group max-w-[564px]">
      <label class="flex"
        >Expiration Date
        <app-icon
          [icon]="'info'"
          class="ml-4 w-12 h-12 opacity-50 hover:opacity-100 inline-block"
          appTooltip="Set expiration date for your project. At this selected date your project shall be delisted and no longer available to watch (this is optional)"
          placement="top"
        ></app-icon
      ></label>

      <app-dcp-date-picker formControlName="expired_at" controlName="expired_at"></app-dcp-date-picker>
    </div>
  </div>
</form>

<div *ngIf="isOpenModal" class="backdrop flex flex-wrap items-center justify-center w-full h-full overflow-auto">
  <app-upload-dialog
    [type]="uploadType"
    [from]="'proposal'"
    [isVideoDistribution]="true"
    [distributionType]="distributionType"
    [mode]="mode"
    [mediaFile]="selectedMediaFile"
    [proposalIndex]="index"
    [imageUploaded]="imageUploaded"
    [fileUploaded]="fileUploaded"
    [videoUploaded]="videoUploaded"
    [isDirectVideoUpload]="isDirectVideoUpload"
    [modalTitle]="modalTitle"
    [modalValue]="modalValue"
    [modalDescription]="modalDescription"
    [videoBlob]="videoBlob"
    (cancelModal)="cancelModal()"
    (saveMedia)="saveMedia($event)"
  ></app-upload-dialog>
</div>
